import { Box, Button, Stack } from '@mui/material';
import {
  AssetType,
  CaseSpineProfile,
  CaseSpineType,
  caseUtils,
  IAsset,
  ICase,
  IMeasure,
  Permission,
  PlanAssetColorType,
  TableType,
  VertebralBody,
} from '@workflow-nx/common';
import { MeasurementsView } from '@workflow-nx/ui';
import config from '../../../../extras/config';
import {
  BabylonSceneManager,
  LoadedSpineConfig,
  MeshData,
  SpineAssetTags,
} from '@workflow-nx/scene';
import { useLazyQuery, useQuery } from '@apollo/client';
import useCreateAndUploadAsset from '../../../../hooks/useCreateAndUploadAsset';
import { gql } from '@apollo/client/core';
import { useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import ActionButton from 'apps/workflow-client/src/app/components/ActionButton';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-thin-svg-icons';

const FIND_ASSETS_QUERY = gql`
  query FindAssets(
    $caseId: Int!
    $planId: Int
    $showDeleted: Boolean
    $assetTypeFilter: [AssetType]
  ) {
    planAssets: assets(
      caseId: $caseId
      planId: $planId
      showDeleted: $showDeleted
      assetTypeFilter: $assetTypeFilter
    ) {
      assetId
      assetType
      fileName
      signedDownloadUrl
    }
    planMeasurements: measurements(caseId: $caseId, planId: $planId) {
      body
      endPlate
      position
      point
      source
    }
    preopAssets: assets(
      caseId: $caseId
      showDeleted: $showDeleted
      assetTypeFilter: $assetTypeFilter
    ) {
      assetId
      assetType
      fileName
      signedDownloadUrl
    }
    preopMeasurements: measurements(caseId: $caseId) {
      body
      endPlate
      position
      point
      source
    }
  }
`;

const FIND_PLAN_DETAILS_QUERY = gql`
  query FindAssets($caseId: Int!, $planId: Int) {
    planAssets: assets(
      caseId: $caseId
      planId: $planId
      assetTypeFilter: [PLAN_CORONAL_IMAGE, PLAN_LATERAL_IMAGE]
    ) {
      assetType
      signedDownloadUrl
    }
    preopAssets: assets(
      caseId: $caseId
      planId: $planId
      assetTypeFilter: [PREOP_CORONAL_IMAGE, PREOP_LATERAL_IMAGE]
    ) {
      assetType
      signedDownloadUrl
    }
    planMeasurements: measurements(caseId: $caseId, planId: $planId) {
      body
      endPlate
      position
      point
      source
    }
    preopMeasurements: measurements(caseId: $caseId) {
      body
      endPlate
      position
      point
      source
    }
  }
`;

function ImageWithFallback(props: { url: string; alt: string }) {
  return (
    <>
      {props.url ? (
        <img
          style={{
            height: 400,
            width: 200,
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          src={props.url}
          alt={props.alt}
        />
      ) : (
        <Box
          sx={{
            width: 200,
            height: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px dashed lightgrey',
            backgroundColor: '#f0f0f0',
            borderRadius: 5,
            opacity: 0.5,
          }}
        >
          <FontAwesomeIcon icon={faImage} size="3x" style={{ color: 'gray' }} />
        </Box>
      )}
    </>
  );
}

export function ProposedPlanView(props: { activeCase: ICase; planId: number }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [planMeasurements, setPlanMeasurements] = useState<IMeasure[]>([]);
  const [preopMeasurements, setPreopMeasurements] = useState<IMeasure[]>([]);

  const [preopLateralImage, setPreopLateralImage] = useState('');
  const [preopCoronalImage, setPreopCoronalImage] = useState('');
  const [planLateralImage, setPlanLateralImage] = useState('');
  const [planCoronalImage, setPlanCoronalImage] = useState('');

  const { createAndUploadAsset } = useCreateAndUploadAsset();
  const { hasPermission } = useAuth();
  const [findAssets] = useLazyQuery(FIND_ASSETS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const { refetch } = useQuery(FIND_PLAN_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { caseId: props.activeCase.caseId, planId: props.planId },
    onCompleted: (data) => {
      setPlanMeasurements(data.planMeasurements);
      setPreopMeasurements(data.preopMeasurements);

      setPreopLateralImage(
        data.preopAssets.find((asset: IAsset) => asset.assetType === AssetType.PreopLateralImage)
          ?.signedDownloadUrl ?? '',
      );
      setPreopCoronalImage(
        data.preopAssets.find((asset: IAsset) => asset.assetType === AssetType.PreopCoronalImage)
          ?.signedDownloadUrl ?? '',
      );
      setPlanLateralImage(
        data.planAssets.find((asset: IAsset) => asset.assetType === AssetType.PlanLateralImage)
          ?.signedDownloadUrl ?? '',
      );
      setPlanCoronalImage(
        data.planAssets.find((asset: IAsset) => asset.assetType === AssetType.PlanCoronalImage)
          ?.signedDownloadUrl ?? '',
      );
    },
  });

  async function getScreenshots(): Promise<{ assetType: AssetType; blob: Blob }[]> {
    const offscreenSceneManager = await loadOffscreenSpine();

    const screenshots: { assetType: AssetType; blob: Blob }[] = [];

    try {
      if (!offscreenSceneManager) {
        return screenshots;
      }

      const desiredWidth = 500;
      const desiredHeight = 750;

      const planMeshes = offscreenSceneManager.getMeshesByTags('PLAN');
      const preopMeshes = offscreenSceneManager.getMeshesByTags('PREOP');

      offscreenSceneManager.updateMeshes(
        planMeshes.map((value: MeshData) => ({
          meshId: value.mesh,
          config: { opacity: 1, excludeAnnotations: true },
        })),
      );

      offscreenSceneManager.updateMeshes(
        preopMeshes.map((value: MeshData) => ({
          meshId: value.mesh,
          config: { opacity: 0, excludeAnnotations: true },
        })),
      );

      await offscreenSceneManager.createScreenshot(desiredWidth, desiredHeight, 'CORONAL');

      const coronalDataPlan = await offscreenSceneManager.createScreenshot(
        desiredWidth,
        desiredHeight,
        'CORONAL',
      );

      if (coronalDataPlan) {
        screenshots.push({ assetType: AssetType.PlanCoronalImage, blob: coronalDataPlan });
      }

      const sagittalDataPlan = await offscreenSceneManager.createScreenshot(
        desiredWidth,
        desiredHeight,
        'SAGITTAL_LEFT',
      );
      if (sagittalDataPlan) {
        screenshots.push({ assetType: AssetType.PlanLateralImage, blob: sagittalDataPlan });
      }

      offscreenSceneManager.updateMeshes(
        planMeshes.map((value: MeshData) => ({
          meshId: value.mesh,
          config: { opacity: 0, excludeAnnotations: true },
        })),
      );

      offscreenSceneManager.updateMeshes(
        preopMeshes.map((value: MeshData) => ({
          meshId: value.mesh,
          config: { opacity: 1, excludeAnnotations: true },
        })),
      );

      const coronalDataPreop = await offscreenSceneManager.createScreenshot(
        desiredWidth,
        desiredHeight,
        'CORONAL',
      );
      if (coronalDataPreop) {
        screenshots.push({ assetType: AssetType.PreopCoronalImage, blob: coronalDataPreop });
      }

      const sagittalDataPreop = await offscreenSceneManager.createScreenshot(
        desiredWidth,
        desiredHeight,
        'SAGITTAL_LEFT',
      );
      if (sagittalDataPreop) {
        screenshots.push({ assetType: AssetType.PreopLateralImage, blob: sagittalDataPreop });
      }
    } catch (e) {
      console.error(e);
    }

    return screenshots;
  }

  async function loadOffscreenSpine(): Promise<BabylonSceneManager> {
    const validCutImplants = caseUtils
      .getValidCaseLevels(props.activeCase.levels)
      .map((validCaseLevelsType) => {
        return `${validCaseLevelsType}_APP`;
      });
    const validImplantScrews = caseUtils
      .getValidCaseLevels(props.activeCase.levels)
      .map((validCaseLevelsType) => {
        return `${validCaseLevelsType}_IMPLANT_SCREW`;
      });
    const validLevels = caseUtils.getCaseVertebralBodyAssets(
      props.activeCase.spineProfile,
      'desc',
    ).standard;

    const response = await findAssets({
      variables: {
        caseId: props.activeCase.caseId,
        planId: props?.planId,
        assetTypeFilter: [
          AssetType.Pelvis,
          ...validLevels,
          ...validCutImplants,
          ...validImplantScrews,
        ],
      },
    });

    const spineAssets: LoadedSpineConfig[] = [];

    if (response.data) {
      const preopAssets = response.data.preopAssets.filter(
        (asset: IAsset) => !asset.assetType.endsWith('_DAISY'),
      );

      for (const asset of preopAssets) {
        if (asset.signedDownloadUrl) {
          spineAssets.push({
            assetType: asset.assetType,
            assetUrl: asset.signedDownloadUrl,
            tag: SpineAssetTags.PreOp,
            otherTags: [SpineAssetTags.Vertebrae],
            opacity: 0,
          });
        }
      }

      for (const asset of response.data.planAssets) {
        if (asset.signedDownloadUrl) {
          const name = asset.assetType.replace('_APP', '').replace('_IMPLANT_SCREW', '');

          let type: SpineAssetTags.Vertebrae | SpineAssetTags.Implant | SpineAssetTags.Screws =
            asset.assetType.endsWith('_APP') ? SpineAssetTags.Implant : SpineAssetTags.Vertebrae;

          if (asset.assetType.endsWith('_IMPLANT_SCREW')) {
            type = SpineAssetTags.Screws;
          }

          // only include vertebrae for now
          if (type !== SpineAssetTags.Vertebrae) {
            continue;
          }

          spineAssets.push({
            assetType: name,
            assetUrl: asset.signedDownloadUrl,
            tag: SpineAssetTags.Plan,
            otherTags: [type],
            opacity: 1,
          });
        }
      }
    }

    const offscreenCanvas = document.createElement('canvas');

    const offscreenSceneManager = new BabylonSceneManager(offscreenCanvas, {
      debug: false,
    });
    offscreenSceneManager.start();

    console.log(spineAssets);

    await offscreenSceneManager.loadSpine(
      props.activeCase?.spineProfile ?? CaseSpineProfile.LumbarStandard,
      spineAssets,
      {
        PREOP: PlanAssetColorType.PreopVertebrae,
        PLAN: PlanAssetColorType.PlanVertebrae,
      },
    );
    const defaultCameraTarget =
      props.activeCase.spineType === CaseSpineType.Lumbar ? VertebralBody.L3 : VertebralBody.C4;

    await offscreenSceneManager.zoom({
      cameraView: 'CORONAL',
      distance: 300,
      target: {
        meshName: defaultCameraTarget,
        tags: [SpineAssetTags.Plan, SpineAssetTags.Vertebrae],
      },
      disableAnimation: true,
    });

    return offscreenSceneManager;
  }

  async function regenerateScreenshots() {
    setLoading(true);

    try {
      const screenshots = await getScreenshots();

      for (const screenshot of screenshots) {
        await createAndUploadAsset(
          new File([screenshot.blob], `${screenshot.assetType}.png`, { type: 'image/png' }),
          screenshot.assetType,
          props.activeCase.caseId,
          props.planId,
        );
      }

      await refetch();

      enqueueSnackbar(`Plan images were regenerated`, {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(`An error occurred regenerating the plan images`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        border={'1px solid #eaeaea'}
        borderRadius="5px"
        p={2}
        mb={2}
        gap={2}
      >
        <ImageWithFallback url={planCoronalImage} alt={'plan coronal'} />
        <ImageWithFallback url={planLateralImage} alt={'plan lateral'} />
        <MeasurementsView
          spineType={props.activeCase.spineType}
          measurementsVersion={props.activeCase.settings.measurementsVersion}
          caseSpineProfile={props.activeCase.spineProfile}
          caseLevels={caseUtils.getValidCaseLevels(props.activeCase.levels)}
          disableToggle={true}
          pelvicIncidence={props.activeCase.patient.patientRecord.pelvicIncidence}
          slopeOfLineOfSight={props.activeCase.patient.patientRecord.slopeOfLineOfSight}
          plan={planMeasurements}
          preop={preopMeasurements}
          size={'small'}
          tableType={TableType.Plan}
          showErrors={false}
          measurementConfig={config.measurementsConfig}
        />
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        border={'1px solid #eaeaea'}
        borderRadius="5px"
        p={2}
        mb={2}
        gap={2}
      >
        <ImageWithFallback url={preopCoronalImage} alt={'pre-op coronal'} />
        <ImageWithFallback url={preopLateralImage} alt={'pre-op lateral'} />
        <MeasurementsView
          spineType={props.activeCase.spineType}
          measurementsVersion={props.activeCase.settings.measurementsVersion}
          caseSpineProfile={props.activeCase.spineProfile}
          caseLevels={caseUtils.getValidCaseLevels(props.activeCase.levels)}
          disableToggle={true}
          pelvicIncidence={props.activeCase.patient.patientRecord.pelvicIncidence}
          slopeOfLineOfSight={props.activeCase.patient.patientRecord.slopeOfLineOfSight}
          plan={planMeasurements}
          preop={preopMeasurements}
          size={'small'}
          tableType={TableType.Preop}
          showErrors={false}
          measurementConfig={config.measurementsConfig}
        />
      </Stack>
      {hasPermission?.([Permission.ManageCase]) ? (
        <Box>
          <ActionButton loading={loading} variant={'outlined'} onClick={regenerateScreenshots}>
            Regenerate screenshots
          </ActionButton>
        </Box>
      ) : null}
    </Box>
  );
}
