import { JSX } from 'react';

export enum PlanAssetColorType {
  DaisyVertebrae = '#FFD60A',
  PreopVertebrae = '#30B0C7',
  PreopHardware = '#C0C0C0',
  PlanVertebrae = '#EAEAEA',
  PlanDiffVertebrae = '#e0d7c6',
  PlanImplantTemplate = '#FFEA00',
  PlanImplantCut = '#8E8E93',

  PlanImplantScrew = '#9ae14d',
}

export enum VertebraePositionColor {
  AnteriorTop = '#fa8072',
  AnteriorBottom = '#8b0000',
  PosteriorTop = '#ffff99',
  PosteriorBottom = '#cccc00',
  PatientRightTop = '#87cefa',
  PatientRightBottom = '#4682b4',
  PatientLeftTop = '#90ee90',
  PatientLeftBottom = '#228b22',
}

export enum CaseHoldType {
  Administrative = 'ADMINISTRATIVE',
  Medical = 'MEDICAL',
  PendingCaseApproval = 'PENDING_CASE_APPROVAL',
  Unknown = 'UNKNOWN',
}

export enum CaseCancellationType {
  Cancelled = 'CANCELLED',
  Missed = 'MISSED',
  Invalid = 'INVALID',
}

export enum CaseCancellationListType {
  None = 'NONE',
  All = 'ALL',
  Cancelled = 'CANCELLED',
  Missed = 'MISSED',
  Invalid = 'INVALID',
}

export enum CaseCancelledReasonType {
  PatientOptOut = 'PATIENT_OPT_OUT',
  PatientHealth = 'PATIENT_HEALTH',
  SurgeonPreference = 'SURGEON_PREFERENCE',
  InsuranceCoPay = 'INSURANCE_CO_PAY',
  InsuranceDenial = 'INSURANCE_DENIAL',
}

export enum CaseMissedReasonType {
  InsufficientLeadTime = 'INSUFFICIENT_LEAD_TIME',
  SurgeryMovedUp = 'SURGERY_MOVED_UP',
  ProductionIssue = 'PRODUCTION_ISSUE',
  Imaging = 'IMAGING',
  SurgeonPreferencePreOp = 'SURGEON_PREFERENCE_PRE_OP',
  SurgeonPreferenceIntraOp = 'SURGEON_PREFERENCE_INTRA_OP',
  LostDelayedShipment = 'LOST_DELAYED_SHIPMENT',
  HospitalApproval = 'HOSPITAL_APPROVAL',
}

export enum CaseInvalidReasonType {
  Duplicate = 'DUPLICATE',
  DataEntryError = 'DATA_ENTRY_ERROR',
  // NOTE: jeffp: 09/05/23 - All the below reasons are kept for historic
  // reasons but the only two valid "invalid" reason types are the two above
  ExpiredCT = 'EXPIRED_CT',
  ExpiredXR = 'EXPIRED_XR',
  CTExpiresBeforeSurgery = 'CT_EXPIRES_BEFORE_SURGERY',
  XRExpiresBeforeSurgery = 'XR_EXPIRES_BEFORE_SURGERY',
  NonDicomCT = 'NON_DICOM_CT',
  IncompleteOrCorruptedCT = 'INCOMPLETE_OR_CORRUPTED_CT',
  InvalidOrMissingPatientInfo = 'INVALID_OR_MISSING_PATIENT_INFO',
  SurgeryDateTooEarlyForProd = 'SURGERY_DATE_TOO_EARLY_FOR_PROD',
  SurgeryDateTooLateForImaging = 'SURGERY_DATE_TOO_LATE_FOR_IMAGING',
  LevelsUnknown = 'LEVELS_UNKNOWN',
  ImplantTypeUnknown = 'IMPLANT_TYPE_UNKNOWN',
  ApproachSideUnknown = 'APPROACH_SIDE_UNKNOWN',
  NotAprevoCandidate = 'NOT_APREVO_CANDIDATE',
  InsuranceApprovalNotGranted = 'INSURANCE_APPROVAL_NOT_GRANTED',
  RequestToDropCase = 'REQUEST_TO_DROP_CASE',
}

export enum CloudDesignStatus {
  None = 'NONE',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export enum DaisySegmentationStatus {
  None = 'NONE',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Completed = 'COMPLETED',
}

export enum AutoCorrectStatus {
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Completed = 'COMPLETED',
}

export enum DaisySegmentationStage {
  ZipExtraction = 'ZIP_EXTRACTION',
  StlUpload = 'STL_UPLOAD',
  SacrumUpload = 'SACRUM_UPLOAD',
  LandmarkInProgress = 'LANDMARK_IN_PROCESS',
  LandmarkUploaded = 'LANDMARK_UPLOADED',
  SacrumConverted = 'SACRUM_CONVERTED',
  SpinePreprocessing = 'SPINE_PREPROCESSING',
  SpineLocalization = 'SPINE_LOCALIZATION',
  VertebraeLocalization = 'VERTEBRAE_LOCALIZATION',
  VertebraeSegmentation = 'VERTEBRAE_SEGMENTATION',
  VertebraePostprocessing = 'VERTEBRAE_POSTPROCESSING',
  VertebraeConversion = 'VERTEBRAE_CONVERSION',
  SacrumInProcess = 'SACRUM_IN_PROCESS',
  NiftiConversion = 'NIFTI_CONVERSION',
}

export enum DaisySegmentationType {
  Sacrum = 'SACRUM',
  Spine = 'SPINE',
}

export enum Interbody {
  L4S1Implant = 'L4_S1_IMPLANT',
  L6S1Implant = 'L6_S1_IMPLANT',
  L56L6Implant = 'L5_L6_IMPLANT',
  L5S1Implant = 'L5_S1_IMPLANT',
  L4L5Implant = 'L4_L5_IMPLANT',
  L3L4Implant = 'L3_L4_IMPLANT',
  L2L3Implant = 'L2_L3_IMPLANT',
  L1L2Implant = 'L1_L2_IMPLANT',
}

export enum ImplantType {
  None = 'NONE',
  ACDF = 'ACDF',
  ACDFX = 'ACDF_X',
  ACDFX_NO_CAM = 'ACDF_X_NO_CAM',
  ALIF = 'ALIF',
  ALIFX = 'ALIF_X',
  LLIF = 'LLIF',
  TLIFO = 'TLIF_O',
  TLIFC = 'TLIF_C',
  TLIFCA = 'TLIF_CA',
}

export enum AssetCategory {
  Implants = 'IMPLANTS',
  VertebralBodies = 'VERTEBRAL_BODIES',
}

export enum ImplantDirection {
  None = 'NONE',
  Left = 'LEFT',
  Right = 'RIGHT',
  ApproachFromSideWithShorterDiscHeight = 'APPROACH_FROM_SIDE_WITH_SHORTER_DISC_HEIGHT',
  CaseSpecific = 'CASE_SPECIFIC',
  LevelSpecific = 'LEVEL_SPECIFIC',
}

export enum ImplantOrientation {
  None = 'NONE',
  Inline = 'INLINE',
  Offset = 'OFFSET',
  TwoUp = 'TWO_UP',
  TwoDown = 'TWO_DOWN',
  CaseSpecific = 'CASE_SPECIFIC',
  LevelSpecific = 'LEVEL_SPECIFIC',
  LeftUp = 'LEFT_UP',
  LeftDown = 'LEFT_DOWN',
}

export enum ActivityOriginType {
  Asset = 'ASSET',
  Case = 'CASE',
  CaseImplantSpecification = 'CASE_IMPLANT_SPECIFICATION',
  Comment = 'COMMENT',
  Configuration = 'CONFIGURATION',
  Event = 'EVENT',
  Email = 'EMAIL',
  Form = 'FORM',
  Form19 = 'FORM19',
  Location = 'LOCATION',
  Measurement = 'MEASUREMENT',
  Organization = 'ORGANIZATION',
  Patient = 'PATIENT',
  PatientRecord = 'PATIENT_RECORD',
  PriceLevel = 'PRICE_LEVEL',
  Plan = 'PLAN',
  PlanRejection = 'PLAN_REJECTION',
  PostOpAnalysis = 'POST_OP_ANALYSIS',
  Setting = 'SETTING',
  Task = 'TASK',
  User = 'USER',
}

export enum ActivityType {
  AssetCreate = 'ASSET_CREATE',
  AssetDelete = 'ASSET_DELETE',
  CaseCreate = 'CASE_CREATE',
  CaseExported = 'CASE_EXPORTED',
  CaseImported = 'CASE_IMPORTED',
  CaseUpdate = 'CASE_UPDATE',
  CaseUpdateNumber = 'CASE_UPDATE_NUMBER',
  CaseUpdateStudyDate = 'CASE_UPDATE_STUDY_DATE',
  CaseAutoSegmentationApproved = 'CASE_AUTO_SEGMENTATION_APPROVED',
  CaseAutoSegmentationApprovedWithChanges = 'CASE_AUTO_SEGMENTATION_APPROVED_WITH_CHANGES',
  CaseAutoSegmentationRejected = 'CASE_AUTO_SEGMENTATION_REJECTED',
  CaseCancelled = 'CASE_CANCELLED',
  CaseHold = 'CASE_HOLD',
  CaseDelete = 'CASE_DELETE',
  CaseDHRReviewRequested = 'CASE_DHR_REVIEW_REQUESTED',
  CaseDHRReviewAccepted = 'CASE_DHR_REVIEW_ACCEPTED',
  CaseDHRReviewRejected = 'CASE_DHR_REVIEW_REJECTED',
  CaseUncancelled = 'CASE_UNCANCELLED',
  CaseUndelete = 'CASE_UNDELETE',
  CaseRemoveHold = 'CASE_REMOVE_HOLD',
  CaseImport = 'CASE_IMPORT',
  CaseForceSync = 'CASE_FORCE_SYNC',
  CaseStageCompleteReopened = 'CASE_STAGE_COMPLETE_REOPENED',
  CaseStageOpenComplete = 'CASE_STAGE_OPEN_COMPLETE',
  CaseStageSegmentationComplete = 'CASE_STAGE_SEGMENTATION_COMPLETE',
  CaseStageSegmentationReopened = 'CASE_STAGE_SEGMENTATION_REOPENED',
  CaseStagePlanningComplete = 'CASE_STAGE_PLANNING_COMPLETE',
  CaseStagePlanningReopened = 'CASE_STAGE_PLANNING_REOPENED',
  CaseStageProposedComplete = 'CASE_STAGE_PROPOSED_COMPLETE',
  CaseStageProposedReopened = 'CASE_STAGE_PROPOSED_REOPENED',
  CaseStageDesignComplete = 'CASE_STAGE_DESIGN_COMPLETE',
  CaseStageDesignReopened = 'CASE_STAGE_DESIGN_REOPENED',
  CaseStageQaReviewComplete = 'CASE_STAGE_QA_REVIEW_COMPLETE',
  CaseStageQaReviewReopened = 'CASE_STAGE_QA_REVIEW_REOPENED',
  CaseStageProductionComplete = 'CASE_STAGE_PRODUCTION_COMPLETE',
  CaseStageProductionReopened = 'CASE_STAGE_PRODUCTION_REOPENED',
  CaseStageReadyComplete = 'CASE_STAGE_READY_COMPLETE',
  CaseStageReadyReopened = 'CASE_STAGE_READY_REOPENED',
  CaseResultsUpdated = 'CASE_RESULTS_UPDATED',
  CaseStudyDateUpdated = 'CASE_STUDY_DATE_UPDATED',
  CaseSurgeryDateUpdated = 'CASE_SURGERY_DATE_UPDATED',
  CaseImplantSpecificationCreate = 'CASE_IMPLANT_SPECIFICATION_CREATE',
  CaseImplantSpecificationDelete = 'CASE_IMPLANT_SPECIFICATION_DELETE',
  CaseImplantSpecificationUpdate = 'CASE_IMPLANT_SPECIFICATION_UPDATE',
  CaseImplantSpecificationUpsert = 'CASE_IMPLANT_SPECIFICATION_UPSERT',
  CaseReportCreate = 'CASE_REPORT_CREATE',
  CaseReportUpdate = 'CASE_REPORT_UPDATE',
  CaseReportDelete = 'CASE_REPORT_DELETE',
  CaseReportInProgress = 'CASE_REPORT_IN_PROGRESS',
  CaseReportInReview = 'CASE_REPORT_IN_REVIEW',
  CaseReportRejected = 'CASE_REPORT_REJECTED',
  CaseReportApproved = 'CASE_REPORT_APPROVED',
  CommentCreate = 'COMMENT_CREATE',
  CommentDelete = 'COMMENT_DELETE',
  CommentUpdate = 'COMMENT_UPDATE',
  ConfigurationUpdate = 'CONFIGURATION_UPDATE',
  EmailSendSuccess = 'EMAIL_SEND_SUCCESS',
  EmailSendFailure = 'EMAIL_SEND_FAILURE',
  EventCreate = 'EVENT_CREATE',
  EventDelete = 'EVENT_DELETE',
  EventUpdate = 'EVENT_UPDATE',
  Form19Update = 'FORM19_UPDATE',
  Form19Create = 'FORM19_CREATE',
  Form19Delete = 'FORM19_DELETE',
  Form19UpdateStatusDraft = 'FORM_19_UPDATE_STATUS_DRAFT',
  Form19UpdateStatusReadyForReview = 'FORM_19_UPDATE_STATUS_READY_FOR_REVIEW',
  Form19UpdateStatusVerified = 'FORM_19_UPDATE_STATUS_VERIFIED',
  Form19UpdateStatusRejected = 'FORM_19_UPDATE_STATUS_REJECTED',
  Form19UpdateStatusApproved = 'FORM_19_UPDATE_STATUS_APPROVED',
  Form19Regenerated = 'FORM19_REGENERATED',
  LocationUpsert = 'LOCATION_UPSERT',
  LocationCreate = 'LOCATION_CREATE',
  LocationUpdate = 'LOCATION_UPDATE',
  LocationDelete = 'LOCATION_DELETE',
  MeasurementUpsert = 'MEASUREMENT_UPSERT',
  PatientUpdate = 'PATIENT_UPDATE',
  PatientRecordUpdate = 'PATIENT_RECORD_UPDATE',
  PriceLevelUpdate = 'PRICE_LEVEL_UPDATE',
  PriceLevelCreate = 'PRICE_LEVEL_CREATE',
  PriceLevelDelete = 'PRICE_LEVEL_DELETE',
  OrganizationCreate = 'ORGANIZATION_CREATE',
  OrganizationUpdate = 'ORGANIZATION_UPDATE',
  OrganizationDelete = 'ORGANIZATION_DELETE',
  PlanCopy = 'PLAN_COPY',
  PlanCreate = 'PLAN_CREATE',
  PlanUpdate = 'PLAN_UPDATE',
  PlanUpsert = 'PLAN_UPSERT',
  PlanDelete = 'PLAN_DELETE',
  PlanUnapprove = 'PLAN_UNAPPROVE',
  PlanUpdateImplants = 'PLAN_UPDATE_IMPLANTS',
  PlanUpdateStatus = 'PLAN_UPDATE_STATUS',
  PlanUpdateIsActive = 'PLAN_UPDATE_IS_ACTIVE',
  PlanProposalRejected = 'PLAN_PROPOSAL_REJECTED',
  PlanImplantUpsert = 'PLAN_IMPLANT_UPSERT',
  PlanImplantDelete = 'PLAN_IMPLANT_DELETE',
  PlanImplantMove = 'PLAN_IMPLANT_MOVE',
  PostOpAnalysisCreate = 'POST_OP_ANALYSIS_CREATE',
  PostOpAnalysisDelete = 'POST_OP_ANALYSIS_DELETE',
  PostOpAnalysisUpdate = 'POST_OP_ANALYSIS_UPDATE',
  SettingUpdate = 'SETTING_CREATE',
  TaskCancel = 'TASK_CANCEL',
  TaskCreate = 'TASK_CREATE',
  TaskDelete = 'TASK_DELETE',
  TaskUpdate = 'TASK_UPDATE',
  UserCreate = 'USER_CREATE',
  UserUpdate = 'USER_UPDATE',
  UserUpdatePreferences = 'USER_UPDATE_PREFERENCES',
  UserUpdatePermissions = 'USER_UPDATE_PERMISSIONS',
  UserDelete = 'USER_DELETE',
}

export enum EmailType {
  CaseSegmented = 'CASE_SEGMENTED',
  CaseApproved = 'CASE_APPROVED',
  CaseRejected = 'CASE_REJECTED',
  CaseCreatedAssigned = 'CASE_CREATED_ASSIGNED',
  CaseCreatedUnassigned = 'CASE_CREATED_UNASSIGNED',
  CaseUpdated = 'CASE_UPDATED',
  CaseImagingUpdated = 'CASE_IMAGING_UPDATED',
  CaseFinalRelease = 'CASE_FINAL_RELEASE',
  CaseDelivered = 'CASE_DELIVERED',
  CaseQaReview = 'CASE_QA_REVIEW',
  CaseQaReviewApproved = 'CASE_QA_REVIEW_APPROVED',
  CaseQaReviewRejected = 'CASE_QA_REVIEW_REJECTED',
  CasePeerReview = 'CASE_PEER_REVIEW',
  CaseComplete = 'CASE_COMPLETE',
  CaseProposed = 'CASE_PROPOSED',
  CaseCancelled = 'CASE_CANCELLED',
  CaseRestored = 'CASE_RESTORED',
  CaseHeld = 'CASE_HELD',
  PostOpAnalysisImagingUpdated = 'POST_OP_ANALYSIS_IMAGING_UPDATED',
  CaseNewComment = 'CASE_NEW_COMMENT',
  CaseCloudDesignCutComplete = 'CASE_CLOUD_DESIGN_CUT_COMPLETE',
  CaseCloudDesignCutAndMeshComplete = 'CASE_CLOUD_DESIGN_CUT_AND_MESH_COMPLETE',
  CaseDaisySegmentationComplete = 'CASE_DAISY_SEGMENTATION_COMPLETE',
  CaseNewTask = 'CASE_NEW_TASK',
  CaseTaskReminder = 'CASE_TASK_REMINDER',
  CaseTaskStatusChange = 'CASE_TASK_STATUS_CHANGE',
  SurgeonCreated = 'SURGEON_CREATED',
  SegmentationNeededNotification = 'SEGMENTATION_NEEDED_NOTIFICATION',
  DHRReviewRequested = 'DHR_REVIEW_REQUESTED',
  DHRReviewApproved = 'DHR_REVIEW_APPROVED',
  DHRReviewRejected = 'DHR_REVIEW_REJECTED',
  CaseShipmentException = 'CASE_SHIPMENT_EXCEPTION',
  CaseDraftReopened = 'CASE_DRAFT_REOPENED',
  CaseSurgeryDateUpdated = 'CASE_SURGERY_DATE_UPDATED',
  Test = 'TEST',
  PoUploaded = 'PO_UPLOADED',
  MBRUploaded = 'MBR_UPLOADED',
}

export enum FormType {
  Form18 = 'FORM_18',
  Form19 = 'FORM_19',
}

export enum PriceLevelType {
  PerImplant = 'PER_IMPLANT',
  Fixed = 'FIXED',
  BasePlusLevel = 'BASE_PLUS_LEVEL',
}

export enum OrganizationType {
  System = 'HQ',
  HealthSystem = 'HEALTH_SYSTEM',
  Hospital = 'HOSPITAL',
  Main = 'MAIN',
  Distributor = 'DISTRIBUTOR',
  Manufacturer = 'MANUFACTURER',
  Vendor = 'VENDOR',
}

export enum LocationType {
  Hospital = 'HOSPITAL',
  Unknown = 'UNKNOWN',
}

export enum FormStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  ReadyForReview = 'READY_FOR_REVIEW',
  Rejected = 'REJECTED',
  Unapproved = 'UNAPPROVED',
  Verified = 'VERIFIED',
}

export enum PartType {
  NONE = 'NONE',
  FUSED = 'FUSED',
  // Cervical part types
  ACDF = 'ACDF',
  ACDF_X_TWO_UP = 'ACDF_X_TWO_UP',
  ACDF_X_TWO_DOWN = 'ACDF_X_TWO_DOWN',
  ACDF_X_LEFT_UP = 'ACDF_X_LEFT_UP',
  ACDF_X_LEFT_DOWN = 'ACDF_X_LEFT_DOWN',
  ACDF_X_NO_CAM_TWO_UP = 'ACDF_X_NO_CAM_TWO_UP',
  ACDF_X_NO_CAM_TWO_DOWN = 'ACDF_X_NO_CAM_TWO_DOWN',
  ACDF_X_NO_CAM_LEFT_UP = 'ACDF_X_NO_CAM_LEFT_UP',
  ACDF_X_NO_CAM_LEFT_DOWN = 'ACDF_X_NO_CAM_LEFT_DOWN',

  // Lumbar part types
  ALIF = 'ALIF',
  ALIF_X_TWO_UP = 'ALIF_X',
  ALIF_X_TWO_DOWN = 'ALIF_X_TWO_DOWN',
  LLIF_RIGHT = 'LLIF_RIGHT',
  LLIF_LEFT = 'LLIF_LEFT',
  TLIFO_RIGHT = 'TLIF_O_RIGHT',
  TLIFO_LEFT = 'TLIF_O_LEFT',
  TLIFC_INLINE_RIGHT = 'TLIF_C_INLINE_RIGHT',
  TLIFC_INLINE_LEFT = 'TLIF_C_INLINE_LEFT',
  TLIFC_OFFSET_RIGHT = 'TLIF_C_RIGHT', //This is Offset part
  TLIFC_OFFSET_LEFT = 'TLIF_C_LEFT', //This is Offset part
  TLIFCA_INLINE_RIGHT = 'TLIF_CA_INLINE_RIGHT',
  TLIFCA_INLINE_LEFT = 'TLIF_CA_INLINE_LEFT',
  TLIFCA_OFFSET_RIGHT = 'TLIF_CA_OFFSET_RIGHT',
  TLIFCA_OFFSET_LEFT = 'TLIF_CA_OFFSET_LEFT',
}

export enum PlanStatusType {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Proposed = 'PROPOSED',
}

export enum UserStatusType {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export enum UserRoleType {
  SiteAdministrator = 'SITE_ADMINISTRATOR',
  Surgeon = 'SURGEON',
  CaseEngineer = 'CASE_ENGINEER',
  SegmentationEngineer = 'SEGMENTATION_ENGINEER',
  CaseAdmin = 'CASE_ADMIN',
  QualityEngineer = 'QUALITY_ENGINEER',
  System = 'SYSTEM',
  CustomerRep = 'CUSTOMER_REP',
  CustomerRepManager = 'CUSTOMER_REP_MANAGER',
  FieldRep = 'FIELD_REP',
  FieldRepManager = 'FIELD_REP_MANAGER',
  Finance = 'FINANCE',
  Operations = 'OPERATIONS',
  SalesRep = 'SALES_REP',
  Vendor = 'VENDOR',
  Viewer = 'VIEWER',
  External = 'EXTERNAL',
  SupplyChain = 'SUPPLY_CHAIN',
  Demo = 'DEMO',
}

export enum CaseApproachType {
  AgeAdjusted = 'AGE_ADJUSTED',
  ZeroMismatch = 'ZERO_MISMATCH',
  FocalAlignment = 'FOCAL_ALIGNMENT',
  GapScore = 'GAP_SCORE',
  Other = 'OTHER',
  None = 'NONE',
}

export enum CaseType {
  Live = 'LIVE',
  Lab = 'LAB',
  Test = 'TEST',
  Demo = 'DEMO',
  Draft = 'DRAFT',
}

export enum CaseStageType {
  Open = 'OPEN',
  Segmentation = 'SEGMENTATION',
  Planning = 'PLANNING',
  Proposed = 'PROPOSED',
  Design = 'DESIGN',
  QaReview = 'QA_REVIEW',
  Production = 'PRODUCTION',
  Packaging = 'PACKAGING',
  Shipping = 'SHIPPING',
  Distributor = 'DISTRIBUTOR',
  Ready = 'READY',
  Complete = 'COMPLETE',
}

export enum CaseSpineType {
  Cervical = 'CERVICAL',
  Lumbar = 'LUMBAR',
}

export enum CaseSpineProfile {
  CervicalStandard = 'CERVICAL_STANDARD',
  CervicalStandardMinusC7 = 'CERVICAL_STANDARD_MINUS_C7',
  CervicalStandardPlusC8 = 'CERVICAL_STANDARD_PLUS_C8',
  LumbarStandard = 'LUMBAR_STANDARD',
  LumbarStandardMinusL5 = 'LUMBAR_STANDARD_MINUS_L5',
  LumbarStandardPlusL6 = 'LUMBAR_STANDARD_PLUS_L6',
}

export enum EventType {
  PurchaseOrderSent = 'PURCHASE_ORDER_SENT',
  PurchaseOrderPackagingSent = 'PURCHASE_ORDER_PACKAGING_SENT',
  MasterBuildRecordSent = 'MASTER_BUILD_RECORD_SENT',
  BuildPlateConfirmationComplete = 'BUILD_PLATE_CONFIRMATION_COMPLETE',
  AdditivePrintingComplete = 'ADDITIVE_PRINTING_COMPLETE',
  HipComplete = 'HIP_COMPLETE',
  PostHipInspectionComplete = 'POST_HIP_INSPECTION_COMPLETE',
  HeatTreatComplete = 'HEAT_TREAT_COMPLETE',
  PostHeatTreatInspectionComplete = 'POST_HEAT_TREAT_INSPECTION_COMPLETE',
  PostHipInspectionDeliveryComplete = 'POST_HIP_INSPECTION_DELIVERY_COMPLETE',
  NitricPassivationComplete = 'NITRIC_PASSIVATION_COMPLETE',
  CleaningAndPackagingComplete = 'CLEANING_AND_PACKAGING_COMPLETE',
  SterilizationComplete = 'STERILIZATION_COMPLETE',
  DHRReviewRequested = 'DHR_REVIEW_REQUESTED',
  DHRReviewRequestApproved = 'DHR_REVIEW_REQUEST_APPROVED',
  DHRReviewRequestRejected = 'DHR_REVIEW_REQUEST_REJECTED',
  FinalReleaseComplete = 'FINAL_RELEASE_COMPLETE',
  HospitalDeliveryComplete = 'HOSPITAL_DELIVERY_COMPLETE',
  // @TODO - Deprecated - move all entries in DB to "HOSPITAL_DELIVERY_COMPLETE"
  // DeliveredToHospital = "DELIVERED_TO_HOSPITAL",
  Exception = 'EXCEPTION',
}

export enum CarrierType {
  FedEx = 'FEDEX',
  FedExSameDay = 'FEDEX_SAME_DAY',
  Mnx = 'MNX',
  Other = 'OTHER',
}

export enum LevelSize {
  Normal = 'NORMAL',
  Plus = 'PLUS',
  Minus = 'MINUS',
}

export enum LevelResult {
  Used = 'USED',
  NotUsed = 'NOT_USED',
  NotUsedBillable = 'NOT_USED_BILLABLE',
  Exception = 'EXCEPTION',
}

export enum LevelType {
  // Cervical
  C2C3 = 'C2_C3',
  C3C4 = 'C3_C4',
  C4C5 = 'C4_C5',
  C5C6 = 'C5_C6',
  C6C7 = 'C6_C7',
  C7T1 = 'C7_T1',
  C6T1 = 'C6_T1', // STANDARD MINUS C7
  C7C8 = 'C7_C8', // STANDARD PLUS C8
  C8T1 = 'C8_T1', // STANDARD PLUS C8
  // Lumbar
  L1L2 = 'L1_L2',
  L2L3 = 'L2_L3',
  L3L4 = 'L3_L4',
  L4L5 = 'L4_L5',
  L5S1 = 'L5_S1',
  L4S1 = 'L4_S1', // STANDARD MINUS L5
  L5L6 = 'L5_L6', // STANDARD PLUS L6
  L6S1 = 'L6_S1', // STANDARD PLUS L6
}

export enum AssetType {
  // Cervical
  T1 = 'T1',
  C8 = 'C8', // Level types for STANDARD PLUS C8
  C7 = 'C7',
  C6 = 'C6',
  C5 = 'C5',
  C4 = 'C4',
  C3 = 'C3',
  C2 = 'C2',
  C1 = 'C1',
  // Lumbar
  Pelvis = 'PELVIS',
  S1 = 'S1',
  L6 = 'L6', // Level types for STANDARD PLUS L6
  L5 = 'L5',
  L4 = 'L4',
  L3 = 'L3',
  L2 = 'L2',
  L1 = 'L1',
  // Cervical
  C7C8 = 'C7_C8', // STANDARD PLUS C8
  C8T1 = 'C8_T1', // STANDARD PLUS C8
  C6T1 = 'C6_T1', // STANDARD MINUS C7
  C7T1 = 'C7_T1',
  C6C7 = 'C6_C7',
  C5C6 = 'C5_C6',
  C4C5 = 'C4_C5',
  C3C4 = 'C3_C4',
  C2C3 = 'C2_C3',
  // Lumbar
  L5L6 = 'L5_L6', // STANDARD PLUS L6
  L6S1 = 'L6_S1', // STANDARD PLUS L6
  L4S1 = 'L4_S1', // STANDARD MINUS L5
  L5S1 = 'L5_S1',
  L4L5 = 'L4_L5',
  L3L4 = 'L3_L4',
  L2L3 = 'L2_L3',
  L1L2 = 'L1_L2',
  DicomCtDaisy = 'DICOM_CT_DAISY',
  // Cervical
  T1Daisy = 'T1_DAISY',
  C8Daisy = 'C8_DAISY', // Level types for STANDARD PLUS C8
  C7Daisy = 'C7_DAISY',
  C6Daisy = 'C6_DAISY',
  C5Daisy = 'C5_DAISY',
  C4Daisy = 'C4_DAISY',
  C3Daisy = 'C3_DAISY',
  C2Daisy = 'C2_DAISY',
  C1Daisy = 'C1_DAISY',
  // Lumbar
  S1Daisy = 'S1_DAISY',
  L6Daisy = 'L6_DAISY', // STANDARD PLUS L6
  L5Daisy = 'L5_DAISY',
  L4Daisy = 'L4_DAISY',
  L3Daisy = 'L3_DAISY',
  L2Daisy = 'L2_DAISY',
  L1Daisy = 'L1_DAISY',
  HardwareDaisy = 'HARDWARE_DAISY',
  HardwarePreop = 'HARDWARE_PREOP',
  // Cervical
  C7C8CyborgImplant = 'C7_C8_CYBORG_IMPLANT', // STANDARD PLUS C8
  C8T1CyborgImplant = 'C8_T1_CYBORG_IMPLANT', // STANDARD PLUS C8
  C6T1CyborgImplant = 'C6_T1_CYBORG_IMPLANT', // STANDARD MINUS C7
  C7T1CyborgImplant = 'C7_T1_CYBORG_IMPLANT',
  C6C7CyborgImplant = 'C6_C7_CYBORG_IMPLANT',
  C5C6CyborgImplant = 'C5_C6_CYBORG_IMPLANT',
  C4C5CyborgImplant = 'C4_C5_CYBORG_IMPLANT',
  C3C4CyborgImplant = 'C3_C4_CYBORG_IMPLANT',
  C2C3CyborgImplant = 'C2_C3_CYBORG_IMPLANT',
  // Lumbar
  L5L6CyborgImplant = 'L5_L6_CYBORG_IMPLANT', // STANDARD PLUS L6
  L6S1CyborgImplant = 'L6_S1_CYBORG_IMPLANT', // STANDARD PLUS L6
  L4S1CyborgImplant = 'L4_S1_CYBORG_IMPLANT', // STANDARD MINUS L5
  L5S1CyborgImplant = 'L5_S1_CYBORG_IMPLANT',
  L4L5CyborgImplant = 'L4_L5_CYBORG_IMPLANT',
  L3L4CyborgImplant = 'L3_L4_CYBORG_IMPLANT',
  L2L3CyborgImplant = 'L2_L3_CYBORG_IMPLANT',
  L1L2CyborgImplant = 'L1_L2_CYBORG_IMPLANT',
  // Cervical
  C7C8ImplantScrew = 'C7_C8_IMPLANT_SCREW', // STANDARD PLUS C8
  C8T1ImplantScrew = 'C8_T1_IMPLANT_SCREW', // STANDARD PLUS C8
  C6T1ImplantScrew = 'C6_T1_IMPLANT_SCREW', // STANDARD MINUS C7
  C7T1ImplantScrew = 'C7_T1_IMPLANT_SCREW',
  C6C7ImplantScrew = 'C6_C7_IMPLANT_SCREW',
  C5C6ImplantScrew = 'C5_C6_IMPLANT_SCREW',
  C4C5ImplantScrew = 'C4_C5_IMPLANT_SCREW',
  C3C4ImplantScrew = 'C3_C4_IMPLANT_SCREW',
  C2C3ImplantScrew = 'C2_C3_IMPLANT_SCREW',
  // Lumbar
  L5L6ImplantScrew = 'L5_L6_IMPLANT_SCREW', // STANDARD PLUS L6
  L6S1ImplantScrew = 'L6_S1_IMPLANT_SCREW', // STANDARD PLUS L6
  L4S1ImplantScrew = 'L4_S1_IMPLANT_SCREW', // STANDARD MINUS L5
  L5S1ImplantScrew = 'L5_S1_IMPLANT_SCREW',
  L4L5ImplantScrew = 'L4_L5_IMPLANT_SCREW',
  L3L4ImplantScrew = 'L3_L4_IMPLANT_SCREW',
  L2L3ImplantScrew = 'L2_L3_IMPLANT_SCREW',
  L1L2ImplantScrew = 'L1_L2_IMPLANT_SCREW',
  // Cervical
  C7C8ImplantScrewGuide = 'C7_C8_IMPLANT_SCREW_GUIDE', // STANDARD PLUS C8
  C8T1ImplantScrewGuide = 'C8_T1_IMPLANT_SCREW_GUIDE', // STANDARD PLUS C8
  C6T1ImplantScrewGuide = 'C6_T1_IMPLANT_SCREW_GUIDE', // STANDARD MINUS C7
  C7T1ImplantScrewGuide = 'C7_T1_IMPLANT_SCREW_GUIDE',
  C6C7ImplantScrewGuide = 'C6_C7_IMPLANT_SCREW_GUIDE',
  C5C6ImplantScrewGuide = 'C5_C6_IMPLANT_SCREW_GUIDE',
  C4C5ImplantScrewGuide = 'C4_C5_IMPLANT_SCREW_GUIDE',
  C3C4ImplantScrewGuide = 'C3_C4_IMPLANT_SCREW_GUIDE',
  C2C3ImplantScrewGuide = 'C2_C3_IMPLANT_SCREW_GUIDE',
  // Lumbar
  L5L6ImplantScrewGuide = 'L5_L6_IMPLANT_SCREW_GUIDE', // STANDARD PLUS L6
  L6S1ImplantScrewGuide = 'L6_S1_IMPLANT_SCREW_GUIDE', // STANDARD PLUS L6
  L4S1ImplantScrewGuide = 'L4_S1_IMPLANT_SCREW_GUIDE', // STANDARD MINUS L5
  L5S1ImplantScrewGuide = 'L5_S1_IMPLANT_SCREW_GUIDE',
  L4L5ImplantScrewGuide = 'L4_L5_IMPLANT_SCREW_GUIDE',
  L3L4ImplantScrewGuide = 'L3_L4_IMPLANT_SCREW_GUIDE',
  L2L3ImplantScrewGuide = 'L2_L3_IMPLANT_SCREW_GUIDE',
  L1L2ImplantScrewGuide = 'L1_L2_IMPLANT_SCREW_GUIDE',
  // Cervical
  C7C8ImplantInstrument = 'C7_C8_IMPLANT_INSTRUMENT', // STANDARD PLUS C8
  C8T1ImplantInstrument = 'C8_T1_IMPLANT_INSTRUMENT', // STANDARD PLUS C8
  C6T1ImplantInstrument = 'C6_T1_IMPLANT_INSTRUMENT', // STANDARD MINUS C7
  C7T1ImplantInstrument = 'C7_T1_IMPLANT_INSTRUMENT',
  C6C7ImplantInstrument = 'C6_C7_IMPLANT_INSTRUMENT',
  C5C6ImplantInstrument = 'C5_C6_IMPLANT_INSTRUMENT',
  C4C5ImplantInstrument = 'C4_C5_IMPLANT_INSTRUMENT',
  C3C4ImplantInstrument = 'C3_C4_IMPLANT_INSTRUMENT',
  C2C3ImplantInstrument = 'C2_C3_IMPLANT_INSTRUMENT',
  // Lumbar
  L5L6ImplantInstrument = 'L5_L6_IMPLANT_INSTRUMENT', // STANDARD PLUS L6
  L6S1ImplantInstrument = 'L6_S1_IMPLANT_INSTRUMENT', // STANDARD PLUS L6
  L4S1ImplantInstrument = 'L4_S1_IMPLANT_INSTRUMENT', // STANDARD MINUS L5
  L5S1ImplantInstrument = 'L5_S1_IMPLANT_INSTRUMENT',
  L4L5ImplantInstrument = 'L4_L5_IMPLANT_INSTRUMENT',
  L3L4ImplantInstrument = 'L3_L4_IMPLANT_INSTRUMENT',
  L2L3ImplantInstrument = 'L2_L3_IMPLANT_INSTRUMENT',
  L1L2ImplantInstrument = 'L1_L2_IMPLANT_INSTRUMENT',
  // Cervical
  C7C8Plus = 'C7_C8_PLUS', // STANDARD PLUS C8
  C8T1Plus = 'C8_T1_PLUS', // STANDARD PLUS C8
  C6T1Plus = 'C6_T1_PLUS', // STANDARD MINUS C7
  C7T1Plus = 'C7_T1_PLUS',
  C6C7Plus = 'C6_C7_PLUS',
  C5C6Plus = 'C5_C6_PLUS',
  C4C5Plus = 'C4_C5_PLUS',
  C3C4Plus = 'C3_C4_PLUS',
  C2C3Plus = 'C2_C3_PLUS',
  // Lumbar
  L5L6Plus = 'L5_L6_PLUS', // STANDARD PLUS L6
  L6S1Plus = 'L6_S1_PLUS', // STANDARD PLUS L6
  L4S1Plus = 'L4_S1_PLUS', // STANDARD MINUS L5
  L5S1Plus = 'L5_S1_PLUS',
  L4L5Plus = 'L4_L5_PLUS',
  L3L4Plus = 'L3_L4_PLUS',
  L2L3Plus = 'L2_L3_PLUS',
  L1L2Plus = 'L1_L2_PLUS',
  // Cervical
  C7C8Minus = 'C7_C8_MINUS', // STANDARD PLUS C8
  C8T1Minus = 'C8_T1_MINUS', // STANDARD PLUS C8
  C6T1Minus = 'C6_T1_MINUS', // STANDARD MINUS C7
  C7T1Minus = 'C7_T1_MINUS',
  C6C7Minus = 'C6_C7_MINUS',
  C5C6Minus = 'C5_C6_MINUS',
  C4C5Minus = 'C4_C5_MINUS',
  C3C4Minus = 'C3_C4_MINUS',
  C2C3Minus = 'C2_C3_MINUS',
  // Lumbar
  L5L6Minus = 'L5_L6_MINUS', // STANDARD PLUS L6
  L6S1Minus = 'L6_S1_MINUS', // STANDARD PLUS L6
  L4S1Minus = 'L4_S1_MINUS', // STANDARD MINUS L5
  L5S1Minus = 'L5_S1_MINUS',
  L4L5Minus = 'L4_L5_MINUS',
  L3L4Minus = 'L3_L4_MINUS',
  L2L3Minus = 'L2_L3_MINUS',
  L1L2Minus = 'L1_L2_MINUS',
  // Cervical
  C7C8Dimensions = 'C7_C8_DIMENSIONS', // STANDARD PLUS C8
  C8T1Dimensions = 'C8_T1_DIMENSIONS', // STANDARD PLUS C8
  C6T1Dimensions = 'C6_T1_DIMENSIONS', // STANDARD MINUS C7
  C7T1Dimensions = 'C7_T1_DIMENSIONS',
  C6C7Dimensions = 'C6_C7_DIMENSIONS',
  C5C6Dimensions = 'C5_C6_DIMENSIONS',
  C4C5Dimensions = 'C4_C5_DIMENSIONS',
  C3C4Dimensions = 'C3_C4_DIMENSIONS',
  C2C3Dimensions = 'C2_C3_DIMENSIONS',
  // Lumbar
  L5L6Dimensions = 'L5_L6_DIMENSIONS', // STANDARD PLUS L6
  L6S1Dimensions = 'L6_S1_DIMENSIONS', // STANDARD PLUS L6
  L4S1Dimensions = 'L4_S1_DIMENSIONS', // STANDARD MINUS L5
  L5S1Dimensions = 'L5_S1_DIMENSIONS',
  L4L5Dimensions = 'L4_L5_DIMENSIONS',
  L3L4Dimensions = 'L3_L4_DIMENSIONS',
  L2L3Dimensions = 'L2_L3_DIMENSIONS',
  L1L2Dimensions = 'L1_L2_DIMENSIONS',

  // Cervical
  C7C8AppDimensions = 'C7_C8_METADATA', // STANDARD PLUS C8
  C8T1AppDimensions = 'C8_T1_METADATA', // STANDARD PLUS C8
  C6T1AppDimensions = 'C6_T1_METADATA', // STANDARD MINUS C7
  C7T1AppDimensions = 'C7_T1_METADATA',
  C6C7AppDimensions = 'C6_C7_METADATA',
  C5C6AppDimensions = 'C5_C6_METADATA',
  C4C5AppDimensions = 'C4_C5_METADATA',
  C3C4AppDimensions = 'C3_C4_METADATA',
  C2C3AppDimensions = 'C2_C3_METADATA',
  // Lumbar
  L5L6AppDimensions = 'L5_L6_METADATA', // STANDARD PLUS L6
  L6S1AppDimensions = 'L6_S1_METADATA', // STANDARD PLUS L6
  L4S1AppDimensions = 'L4_S1_METADATA', // STANDARD MINUS L5
  L5S1AppDimensions = 'L5_S1_METADATA',
  L4L5AppDimensions = 'L4_L5_METADATA',
  L3L4AppDimensions = 'L3_L4_METADATA',
  L2L3AppDimensions = 'L2_L3_METADATA',
  L1L2AppDimensions = 'L1_L2_METADATA',
  // Cervical
  C7C8PlusDimensions = 'C7_C8_PLUS_DIMENSIONS', // STANDARD PLUS C8
  C8T1PlusDimensions = 'C8_T1_PLUS_DIMENSIONS', // STANDARD PLUS C8
  C6T1PlusDimensions = 'C6_T1_PLUS_DIMENSIONS', // STANDARD MINUS C7
  C7T1PlusDimensions = 'C7_T1_PLUS_DIMENSIONS',
  C6C7PlusDimensions = 'C6_C7_PLUS_DIMENSIONS',
  C5C6PlusDimensions = 'C5_C6_PLUS_DIMENSIONS',
  C4C5PlusDimensions = 'C4_C5_PLUS_DIMENSIONS',
  C3C4PlusDimensions = 'C3_C4_PLUS_DIMENSIONS',
  C2C3PlusDimensions = 'C2_C3_PLUS_DIMENSIONS',
  // Lumbar
  L5L6PlusDimensions = 'L5_L6_PLUS_DIMENSIONS', // STANDARD PLUS L6
  L6S1PlusDimensions = 'L6_S1_PLUS_DIMENSIONS', // STANDARD PLUS L6
  L4S1PlusDimensions = 'L4_S1_PLUS_DIMENSIONS', // STANDARD MINUS L5
  L5S1PlusDimensions = 'L5_S1_PLUS_DIMENSIONS',
  L4L5PlusDimensions = 'L4_L5_PLUS_DIMENSIONS',
  L3L4PlusDimensions = 'L3_L4_PLUS_DIMENSIONS',
  L2L3PlusDimensions = 'L2_L3_PLUS_DIMENSIONS',
  L1L2PlusDimensions = 'L1_L2_PLUS_DIMENSIONS',
  // Cervical
  C7C8MinusDimensions = 'C7_C8_MINUS_DIMENSIONS', // STANDARD PLUS C8
  C8T1MinusDimensions = 'C8_T1_MINUS_DIMENSIONS', // STANDARD PLUS C8
  C6T1MinusDimensions = 'C6_T1_MINUS_DIMENSIONS', // STANDARD MINUS C7
  C7T1MinusDimensions = 'C7_T1_MINUS_DIMENSIONS',
  C6C7MinusDimensions = 'C6_C7_MINUS_DIMENSIONS',
  C5C6MinusDimensions = 'C5_C6_MINUS_DIMENSIONS',
  C4C5MinusDimensions = 'C4_C5_MINUS_DIMENSIONS',
  C3C4MinusDimensions = 'C3_C4_MINUS_DIMENSIONS',
  C2C3MinusDimensions = 'C2_C3_MINUS_DIMENSIONS',
  // Lumbar
  L5L6MinusDimensions = 'L5_L6_MINUS_DIMENSIONS', // STANDARD PLUS L6
  L6S1MinusDimensions = 'L6_S1_MINUS_DIMENSIONS', // STANDARD PLUS L6
  L4S1MinusDimensions = 'L4_S1_MINUS_DIMENSIONS', // STANDARD MINUS L5
  L5S1MinusDimensions = 'L5_S1_MINUS_DIMENSIONS',
  L4L5MinusDimensions = 'L4_L5_MINUS_DIMENSIONS',
  L3L4MinusDimensions = 'L3_L4_MINUS_DIMENSIONS',
  L2L3MinusDimensions = 'L2_L3_MINUS_DIMENSIONS',
  L1L2MinusDimensions = 'L1_L2_MINUS_DIMENSIONS',

  // Cervical
  C7C8Metadata = 'C7_C8_METADATA', // STANDARD PLUS C8
  C8T1Metadata = 'C8_T1_METADATA', // STANDARD PLUS C8
  C6T1Metadata = 'C6_T1_METADATA', // STANDARD MINUS C7
  C7T1Metadata = 'C7_T1_METADATA',
  C6C7Metadata = 'C6_C7_METADATA',
  C5C6Metadata = 'C5_C6_METADATA',
  C4C5Metadata = 'C4_C5_METADATA',
  C3C4Metadata = 'C3_C4_METADATA',
  C2C3Metadata = 'C2_C3_METADATA',
  // Lumbar
  L5L6Metadata = 'L5_L6_METADATA', // STANDARD PLUS L6
  L6S1Metadata = 'L6_S1_METADATA', // STANDARD PLUS L6
  L4S1Metadata = 'L4_S1_METADATA', // STANDARD MINUS L5
  L5S1Metadata = 'L5_S1_METADATA',
  L4L5Metadata = 'L4_L5_METADATA',
  L3L4Metadata = 'L3_L4_METADATA',
  L2L3Metadata = 'L2_L3_METADATA',
  L1L2Metadata = 'L1_L2_METADATA',

  // Cervical
  C7C8AppMetadata = 'C7_C8_APP_METADATA', // STANDARD PLUS C8
  C8T1AppMetadata = 'C8_T1_APP_METADATA', // STANDARD PLUS C8
  C6T1AppMetadata = 'C6_T1_APP_METADATA', // STANDARD MINUS C7
  C7T1AppMetadata = 'C7_T1_APP_METADATA',
  C6C7AppMetadata = 'C6_C7_APP_METADATA',
  C5C6AppMetadata = 'C5_C6_APP_METADATA',
  C4C5AppMetadata = 'C4_C5_APP_METADATA',
  C3C4AppMetadata = 'C3_C4_APP_METADATA',
  C2C3AppMetadata = 'C2_C3_APP_METADATA',
  // Lumbar
  L5L6AppMetadata = 'L5_L6_APP_METADATA', // STANDARD PLUS L6
  L6S1AppMetadata = 'L6_S1_APP_METADATA', // STANDARD PLUS L6
  L4S1AppMetadata = 'L4_S1_APP_METADATA', // STANDARD MINUS L5
  L5S1AppMetadata = 'L5_S1_APP_METADATA',
  L4L5AppMetadata = 'L4_L5_APP_METADATA',
  L3L4AppMetadata = 'L3_L4_APP_METADATA',
  L2L3AppMetadata = 'L2_L3_APP_METADATA',
  L1L2AppMetadata = 'L1_L2_APP_METADATA',
  // Cervical
  C7C8PlusMetadata = 'C7_C8_PLUS_METADATA', // STANDARD PLUS C8
  C8T1PlusMetadata = 'C8_T1_PLUS_METADATA', // STANDARD PLUS C8
  C6T1PlusMetadata = 'C6_T1_PLUS_METADATA', // STANDARD MINUS C7
  C7T1PlusMetadata = 'C7_T1_PLUS_METADATA',
  C6C7PlusMetadata = 'C6_C7_PLUS_METADATA',
  C5C6PlusMetadata = 'C5_C6_PLUS_METADATA',
  C4C5PlusMetadata = 'C4_C5_PLUS_METADATA',
  C3C4PlusMetadata = 'C3_C4_PLUS_METADATA',
  C2C3PlusMetadata = 'C2_C3_PLUS_METADATA',
  // Lumbar
  L5L6PlusMetadata = 'L5_L6_PLUS_METADATA', // STANDARD PLUS L6
  L6S1PlusMetadata = 'L6_S1_PLUS_METADATA', // STANDARD PLUS L6
  L4S1PlusMetadata = 'L4_S1_PLUS_METADATA', // STANDARD MINUS L5
  L5S1PlusMetadata = 'L5_S1_PLUS_METADATA',
  L4L5PlusMetadata = 'L4_L5_PLUS_METADATA',
  L3L4PlusMetadata = 'L3_L4_PLUS_METADATA',
  L2L3PlusMetadata = 'L2_L3_PLUS_METADATA',
  L1L2PlusMetadata = 'L1_L2_PLUS_METADATA',
  // Cervical
  C7C8MinusMetadata = 'C7_C8_MINUS_METADATA', // STANDARD PLUS C8
  C8T1MinusMetadata = 'C8_T1_MINUS_METADATA', // STANDARD PLUS C8
  C6T1MinusMetadata = 'C6_T1_MINUS_METADATA', // STANDARD MINUS C7
  C7T1MinusMetadata = 'C7_T1_MINUS_METADATA',
  C6C7MinusMetadata = 'C6_C7_MINUS_METADATA',
  C5C6MinusMetadata = 'C5_C6_MINUS_METADATA',
  C4C5MinusMetadata = 'C4_C5_MINUS_METADATA',
  C3C4MinusMetadata = 'C3_C4_MINUS_METADATA',
  C2C3MinusMetadata = 'C2_C3_MINUS_METADATA',
  // Lumbar
  L5L6MinusMetadata = 'L5_L6_MINUS_METADATA', // STANDARD PLUS L6
  L6S1MinusMetadata = 'L6_S1_MINUS_METADATA', // STANDARD PLUS L6
  L4S1MinusMetadata = 'L4_S1_MINUS_METADATA', // STANDARD MINUS L5
  L5S1MinusMetadata = 'L5_S1_MINUS_METADATA',
  L4L5MinusMetadata = 'L4_L5_MINUS_METADATA',
  L3L4MinusMetadata = 'L3_L4_MINUS_METADATA',
  L2L3MinusMetadata = 'L2_L3_MINUS_METADATA',
  L1L2MinusMetadata = 'L1_L2_MINUS_METADATA',
  // Cervical
  C7C8Log = 'C7_C8_LOG', // STANDARD PLUS C8
  C8T1Log = 'C8_T1_LOG', // STANDARD PLUS C8
  C6T1Log = 'C6_T1_LOG', // STANDARD MINUS C7
  C7T1Log = 'C7_T1_LOG',
  C6C7Log = 'C6_C7_LOG',
  C5C6Log = 'C5_C6_LOG',
  C4C5Log = 'C4_C5_LOG',
  C3C4Log = 'C3_C4_LOG',
  C2C3Log = 'C2_C3_LOG',
  // Lumbar
  L5L6Log = 'L5_L6_LOG', // STANDARD PLUS L6
  L6S1Log = 'L6_S1_LOG', // STANDARD PLUS L6
  L4S1Log = 'L4_S1_LOG', // STANDARD MINUS L5
  L5S1Log = 'L5_S1_LOG',
  L4L5Log = 'L4_L5_LOG',
  L3L4Log = 'L3_L4_LOG',
  L2L3Log = 'L2_L3_LOG',
  L1L2Log = 'L1_L2_LOG',

  // Cervical
  C7C8PlusLog = 'C7_C8_PLUS_LOG', // STANDARD PLUS C8
  C8T1PlusLog = 'C8_T1_PLUS_LOG', // STANDARD PLUS C8
  C6T1PlusLog = 'C6_T1_PLUS_LOG', // STANDARD MINUS C7
  C7T1PlusLog = 'C7_T1_PLUS_LOG',
  C6C7PlusLog = 'C6_C7_PLUS_LOG',
  C5C6PlusLog = 'C5_C6_PLUS_LOG',
  C4C5PlusLog = 'C4_C5_PLUS_LOG',
  C3C4PlusLog = 'C3_C4_PLUS_LOG',
  C2C3PlusLog = 'C2_C3_PLUS_LOG',
  // Lumbar
  L5L6PlusLog = 'L5_L6_PLUS_LOG', // STANDARD PLUS L6
  L6S1PlusLog = 'L6_S1_PLUS_LOG', // STANDARD PLUS L6
  L4S1PlusLog = 'L4_S1_PLUS_LOG', // STANDARD MINUS L5
  L5S1PlusLog = 'L5_S1_PLUS_LOG',
  L4L5PlusLog = 'L4_L5_PLUS_LOG',
  L3L4PlusLog = 'L3_L4_PLUS_LOG',
  L2L3PlusLog = 'L2_L3_PLUS_LOG',
  L1L2PlusLog = 'L1_L2_PLUS_LOG',

  // Cervical
  C7C8MinusLog = 'C7_C8_MINUS_LOG', // STANDARD PLUS C8
  C8T1MinusLog = 'C8_T1_MINUS_LOG', // STANDARD PLUS C8
  C6T1MinusLog = 'C6_T1_MINUS_LOG', // STANDARD MINUS C7
  C7T1MinusLog = 'C7_T1_MINUS_LOG',
  C6C7MinusLog = 'C6_C7_MINUS_LOG',
  C5C6MinusLog = 'C5_C6_MINUS_LOG',
  C4C5MinusLog = 'C4_C5_MINUS_LOG',
  C3C4MinusLog = 'C3_C4_MINUS_LOG',
  C2C3MinusLog = 'C2_C3_MINUS_LOG',
  // Lumbar
  L5L6MinusLog = 'L5_L6_MINUS_LOG', // STANDARD MINUS L6
  L6S1MinusLog = 'L6_S1_MINUS_LOG', // STANDARD MINUS L6
  L4S1MinusLog = 'L4_S1_MINUS_LOG', // STANDARD MINUS L5
  L5S1MinusLog = 'L5_S1_MINUS_LOG',
  L4L5MinusLog = 'L4_L5_MINUS_LOG',
  L3L4MinusLog = 'L3_L4_MINUS_LOG',
  L2L3MinusLog = 'L2_L3_MINUS_LOG',
  L1L2MinusLog = 'L1_L2_MINUS_LOG',

  // Cervical
  C7C8AppLog = 'C7_C8_APP_LOG', // STANDARD PLUS C8
  C8T1AppLog = 'C8_T1_APP_LOG', // STANDARD PLUS C8
  C6T1AppLog = 'C6_T1_APP_LOG', // STANDARD MINUS C7
  C7T1AppLog = 'C7_T1_APP_LOG',
  C6C7AppLog = 'C6_C7_APP_LOG',
  C5C6AppLog = 'C5_C6_APP_LOG',
  C4C5AppLog = 'C4_C5_APP_LOG',
  C3C4AppLog = 'C3_C4_APP_LOG',
  C2C3AppLog = 'C2_C3_APP_LOG',
  // Lumbar
  L5L6AppLog = 'L5_L6_APP_LOG', // STANDARD PLUS L6
  L6S1AppLog = 'L6_S1_APP_LOG', // STANDARD PLUS L6
  L4S1AppLog = 'L4_S1_APP_LOG', // STANDARD MINUS L5
  L5S1AppLog = 'L5_S1_APP_LOG',
  L4L5AppLog = 'L4_L5_APP_LOG',
  L3L4AppLog = 'L3_L4_APP_LOG',
  L2L3AppLog = 'L2_L3_APP_LOG',
  L1L2AppLog = 'L1_L2_APP_LOG',

  // Cervical
  C7C8NTopCli = 'C7_C8_NTOP_CLI', // STANDARD PLUS C8
  C8T1NTopCli = 'C8_T1_NTOP_CLI', // STANDARD PLUS C8
  C6T1NTopCli = 'C6_T1_NTOP_CLI', // STANDARD MINUS C7
  C7T1NTopCli = 'C7_T1_NTOP_CLI',
  C6C7NTopCli = 'C6_C7_NTOP_CLI',
  C5C6NTopCli = 'C5_C6_NTOP_CLI',
  C4C5NTopCli = 'C4_C5_NTOP_CLI',
  C3C4NTopCli = 'C3_C4_NTOP_CLI',
  C2C3NTopCli = 'C2_C3_NTOP_CLI',
  // Lumbar
  L5L6NTopCli = 'L5_L6_NTOP_CLI', // STANDARD PLUS L6
  L6S1NTopCli = 'L6_S1_NTOP_CLI', // STANDARD PLUS L6
  L4S1NTopCli = 'L4_S1_NTOP_CLI', // STANDARD MINUS L5
  L5S1NTopCli = 'L5_S1_NTOP_CLI',
  L4L5NTopCli = 'L4_L5_NTOP_CLI',
  L3L4NTopCli = 'L3_L4_NTOP_CLI',
  L2L3NTopCli = 'L2_L3_NTOP_CLI',
  L1L2NTopCli = 'L1_L2_NTOP_CLI',

  // Cervical
  C7C8PlusNTopCli = 'C7_C8_PLUS_NTOP_CLI', // STANDARD PLUS C8
  C8T1PlusNTopCli = 'C8_T1_PLUS_NTOP_CLI', // STANDARD PLUS C8
  C6T1PlusNTopCli = 'C6_T1_PLUS_NTOP_CLI', // STANDARD MINUS C7
  C7T1PlusNTopCli = 'C7_T1_PLUS_NTOP_CLI',
  C6C7PlusNTopCli = 'C6_C7_PLUS_NTOP_CLI',
  C5C6PlusNTopCli = 'C5_C6_PLUS_NTOP_CLI',
  C4C5PlusNTopCli = 'C4_C5_PLUS_NTOP_CLI',
  C3C4PlusNTopCli = 'C3_C4_PLUS_NTOP_CLI',
  C2C3PlusNTopCli = 'C2_C3_PLUS_NTOP_CLI',
  // Lumbar
  L5L6PlusNTopCli = 'L5_L6_PLUS_NTOP_CLI', // STANDARD PLUS L6
  L6S1PlusNTopCli = 'L6_S1_PLUS_NTOP_CLI', // STANDARD PLUS L6
  L4S1PlusNTopCli = 'L4_S1_PLUS_NTOP_CLI', // STANDARD MINUS L5
  L5S1PlusNTopCli = 'L5_S1_PLUS_NTOP_CLI',
  L4L5PlusNTopCli = 'L4_L5_PLUS_NTOP_CLI',
  L3L4PlusNTopCli = 'L3_L4_PLUS_NTOP_CLI',
  L2L3PlusNTopCli = 'L2_L3_PLUS_NTOP_CLI',
  L1L2PlusNTopCli = 'L1_L2_PLUS_NTOP_CLI',

  // Cervical
  C7C8MinusNTopCli = 'C7_C8_MINUS_NTOP_CLI', // STANDARD PLUS C8
  C8T1MinusNTopCli = 'C8_T1_MINUS_NTOP_CLI', // STANDARD PLUS C8
  C6T1MinusNTopCli = 'C6_T1_MINUS_NTOP_CLI', // STANDARD MINUS C7
  C7T1MinusNTopCli = 'C7_T1_MINUS_NTOP_CLI',
  C6C7MinusNTopCli = 'C6_C7_MINUS_NTOP_CLI',
  C5C6MinusNTopCli = 'C5_C6_MINUS_NTOP_CLI',
  C4C5MinusNTopCli = 'C4_C5_MINUS_NTOP_CLI',
  C3C4MinusNTopCli = 'C3_C4_MINUS_NTOP_CLI',
  C2C3MinusNTopCli = 'C2_C3_MINUS_NTOP_CLI',
  // Lumbar
  L5L6MinusNTopCli = 'L5_L6_MINUS_NTOP_CLI', // STANDARD PLUS L6
  L6S1MinusNTopCli = 'L6_S1_MINUS_NTOP_CLI', // STANDARD PLUS L6
  L4S1MinusNTopCli = 'L4_S1_MINUS_NTOP_CLI', // STANDARD MINUS L5
  L5S1MinusNTopCli = 'L5_S1_MINUS_NTOP_CLI',
  L4L5MinusNTopCli = 'L4_L5_MINUS_NTOP_CLI',
  L3L4MinusNTopCli = 'L3_L4_MINUS_NTOP_CLI',
  L2L3MinusNTopCli = 'L2_L3_MINUS_NTOP_CLI',
  L1L2MinusNTopCli = 'L1_L2_MINUS_NTOP_CLI',

  RodTemplateLeft = 'ROD_TEMPLATE_LEFT',
  RodTemplateRight = 'ROD_TEMPLATE_RIGHT',
  DicomCt = 'DICOM_CT',
  DicomXray = 'DICOM_XRAY',
  DicomMri = 'DICOM_MRI',

  // Cervical
  Form19C7C8AdditionalImage = 'FORM_19_C7_C8_ADDITIONAL_IMAGE', // STANDARD PLUS C8
  Form19C8T1AdditionalImage = 'FORM_19_C8_T1_ADDITIONAL_IMAGE', // STANDARD PLUS C8
  Form19C6T1AdditionalImage = 'FORM_19_C6_T1_ADDITIONAL_IMAGE', // STANDARD MINUS C7
  Form19C7T1AdditionalImage = 'FORM_19_C7_T1_ADDITIONAL_IMAGE',
  Form19C6C7AdditionalImage = 'FORM_19_C6_C7_ADDITIONAL_IMAGE',
  Form19C5C6AdditionalImage = 'FORM_19_C5_C6_ADDITIONAL_IMAGE',
  Form19C4C5AdditionalImage = 'FORM_19_C4_C5_ADDITIONAL_IMAGE',
  Form19C3C4AdditionalImage = 'FORM_19_C3_C4_ADDITIONAL_IMAGE',
  Form19C2C3AdditionalImage = 'FORM_19_C2_C3_ADDITIONAL_IMAGE',
  // Lumbar
  Form19L5L6AdditionalImage = 'FORM_19_L5_L6_ADDITIONAL_IMAGE', // STANDARD PLUS L6
  Form19L6S1NAdditionalImage = 'FORM_19_L6_S1_ADDITIONAL_IMAGE', // STANDARD PLUS L6
  Form19L4S1AdditionalImage = 'FORM_19_L4_S1_ADDITIONAL_IMAGE', // STANDARD MINUS L5
  Form19L5S1AdditionalImage = 'FORM_19_L5_S1_ADDITIONAL_IMAGE',
  Form19L4L5AdditionalImage = 'FORM_19_L4_L5_ADDITIONAL_IMAGE',
  Form19L3L4AdditionalImage = 'FORM_19_L3_L4_ADDITIONAL_IMAGE',
  Form19L2L3AdditionalImage = 'FORM_19_L2_L3_ADDITIONAL_IMAGE',
  Form19L1L2AdditionalImage = 'FORM_19_L1_L2_ADDITIONAL_IMAGE',

  Form19ApprovalSignature = 'FORM_19_APPROVAL_SIGNATURE',
  Form19MeasurementsReference = 'FORM_19_MEASUREMENTS_REFERENCE',

  Form19 = 'FORM_19',
  Form18 = 'FORM_18',
  Tem013 = 'TEM_013',

  // the _APP suffix assets are for assets that are meant to be displayed on a mobile device
  // Cervical
  T1PreopApp = 'T1_PREOP_APP',
  C8PreopApp = 'C8_PREOP_APP', // Level types for STANDARD PLUS C8
  C7PreopApp = 'C7_PREOP_APP',
  C6PreopApp = 'C6_PREOP_APP',
  C5PreopApp = 'C5_PREOP_APP',
  C4PreopApp = 'C4_PREOP_APP',
  C3PreopApp = 'C3_PREOP_APP',
  C2PreopApp = 'C2_PREOP_APP',
  C1PreopApp = 'C1_PREOP_APP',
  // Lumbar
  S1PreopApp = 'S1_PREOP_APP',
  L6PreopApp = 'L6_PREOP_APP', // STANDARD PLUS L6
  L5PreopApp = 'L5_PREOP_APP',
  L4PreopApp = 'L4_PREOP_APP',
  L3PreopApp = 'L3_PREOP_APP',
  L2PreopApp = 'L2_PREOP_APP',
  L1PreopApp = 'L1_PREOP_APP',
  // Cervical
  T1PlanApp = 'T1_PLAN_APP',
  C8PlanApp = 'C8_PLAN_APP', // Level types for STANDARD PLUS C8
  C7PlanApp = 'C7_PLAN_APP',
  C6PlanApp = 'C6_PLAN_APP',
  C5PlanApp = 'C5_PLAN_APP',
  C4PlanApp = 'C4_PLAN_APP',
  C3PlanApp = 'C3_PLAN_APP',
  C2PlanApp = 'C2_PLAN_APP',
  C1PlanApp = 'C1_PLAN_APP',
  // Lumbar
  S1PlanApp = 'S1_PLAN_APP',
  L6PlanApp = 'L6_PLAN_APP', // STANDARD PLUS L6
  L5PlanApp = 'L5_PLAN_APP',
  L4PlanApp = 'L4_PLAN_APP',
  L3PlanApp = 'L3_PLAN_APP',
  L2PlanApp = 'L2_PLAN_APP',
  L1PlanApp = 'L1_PLAN_APP',

  // Cervical
  C7C8App = 'C7_C8_APP', // STANDARD PLUS C8
  C8T1App = 'C8_T1_APP', // STANDARD PLUS C8
  C6T1App = 'C6_T1_APP', // STANDARD MINUS C7
  C7T1App = 'C7_T1_APP',
  C6C7App = 'C6_C7_APP',
  C5C6App = 'C5_C6_APP',
  C4C5App = 'C4_C5_APP',
  C3C4App = 'C3_C4_APP',
  C2C3App = 'C2_C3_APP',
  // Lumbar
  L5L6App = 'L5_L6_APP', // STANDARD PLUS L6
  L6S1App = 'L6_S1_APP', // STANDARD PLUS L6
  L4S1App = 'L4_S1_APP', // STANDARD MINUS L5
  L5S1App = 'L5_S1_APP',
  L4L5App = 'L4_L5_APP',
  L3L4App = 'L3_L4_APP',
  L2L3App = 'L2_L3_APP',
  L1L2App = 'L1_L2_APP',

  // Cervical
  C7C8AppMinus = 'C7_C8_APP_MINUS', // STANDARD PLUS C8
  C8T1AppMinus = 'C8_T1_APP_MINUS', // STANDARD PLUS C8
  C6T1AppMinus = 'C6_T1_APP_MINUS', // STANDARD MINUS C7
  C7T1AppMinus = 'C7_T1_APP_MINUS',
  C6C7AppMinus = 'C6_C7_APP_MINUS',
  C5C6AppMinus = 'C5_C6_APP_MINUS',
  C4C5AppMinus = 'C4_C5_APP_MINUS',
  C3C4AppMinus = 'C3_C4_APP_MINUS',
  C2C3AppMinus = 'C2_C3_APP_MINUS',
  // Lumbar
  L5L6AppMinus = 'L5_L6_APP_MINUS', // STANDARD PLUS L6
  L6S1AppMinus = 'L6_S1_APP_MINUS', // STANDARD PLUS L6
  L4S1AppMinus = 'L4_S1_APP_MINUS', // STANDARD MINUS L5
  L5S1AppMinus = 'L5_S1_APP_MINUS',
  L4L5AppMinus = 'L4_L5_APP_MINUS',
  L3L4AppMinus = 'L3_L4_APP_MINUS',
  L2L3AppMinus = 'L2_L3_APP_MINUS',
  L1L2AppMinus = 'L1_L2_APP_MINUS',

  // Cervical
  C7C8Data = 'C7_C8_DATA', // STANDARD PLUS C8
  C8T1Data = 'C8_T1_DATA', // STANDARD PLUS C8
  C6T1Data = 'C6_T1_DATA', // STANDARD MINUS C7
  C7T1Data = 'C7_T1_DATA',
  C6C7Data = 'C6_C7_DATA',
  C5C6Data = 'C5_C6_DATA',
  C4C5Data = 'C4_C5_DATA',
  C3C4Data = 'C3_C4_DATA',
  C2C3Data = 'C2_C3_DATA',
  // Lumbar
  L5L6Data = 'L5_L6_DATA', // STANDARD PLUS L6
  L6S1Data = 'L6_S1_DATA', // STANDARD PLUS L6
  L4S1Data = 'L4_S1_DATA', // STANDARD MINUS L5
  L5S1Data = 'L5_S1_DATA',
  L4L5Data = 'L4_L5_DATA',
  L3L4Data = 'L3_L4_DATA',
  L2L3Data = 'L2_L3_DATA',
  L1L2Data = 'L1_L2_DATA',

  PlanSummaryApp = 'PLAN_SUMMARY_APP',
  PlanSummaryFormApp = 'PLAN_SUMMARY_FORM_APP',
  ParasolidExport = 'PARASOLID_EXPORT',
  CloudDesignLevelExport = 'CLOUD_DESIGN_LEVEL_EXPORT',
  PlanCoronalImage = 'PLAN_CORONAL_IMAGE',
  PlanLateralImage = 'PLAN_LATERAL_IMAGE',
  PreopCoronalImage = 'PREOP_CORONAL_IMAGE',
  PreopLateralImage = 'PREOP_LATERAL_IMAGE',

  PostOpIntraOp = 'POST_OP_INTRA_OP',
  PostOpSixWeeks = 'POST_OP_SIX_WEEKS',
  PostOpSixMonths = 'POST_OP_SIX_MONTHS',
  PostOpOneYear = 'POST_OP_ONE_YEAR',
  PostOpTwoYear = 'POST_OP_TWO_YEAR',

  PostOpIntraOpXray = 'POST_OP_INTRA_OP_XRAY',
  PostOpSixWeeksXray = 'POST_OP_SIX_WEEKS_XRAY',
  PostOpSixMonthsXray = 'POST_OP_SIX_MONTHS_XRAY',
  PostOpOneYearXray = 'POST_OP_ONE_YEAR_XRAY',
  PostOpTwoYearXray = 'POST_OP_TWO_YEAR_XRAY',
  PostOpIntraOpStandingXrayLateral = 'POST_OP_INTRA_OP_STANDING_XRAY_LATERAL',
  PostOpSixWeeksStandingXrayLateral = 'POST_OP_SIX_WEEKS_STANDING_XRAY_LATERAL',
  PostOpSixMonthsStandingXrayLateral = 'POST_OP_SIX_MONTHS_STANDING_XRAY_LATERAL',
  PostOpOneYearStandingXrayLateral = 'POST_OP_ONE_YEAR_STANDING_XRAY_LATERAL',
  PostOpTwoYearStandingXrayLateral = 'POST_OP_TWO_YEAR_STANDING_XRAY_LATERAL',
  PostOpIntraOpStandingXrayAp = 'POST_OP_INTRA_OP_STANDING_XRAY_AP',
  PostOpSixWeeksStandingXrayAp = 'POST_OP_SIX_WEEKS_STANDING_XRAY_AP',
  PostOpSixMonthsStandingXrayAp = 'POST_OP_SIX_MONTHS_STANDING_XRAY_AP',
  PostOpOneYearStandingXrayAp = 'POST_OP_ONE_YEAR_STANDING_XRAY_AP',
  PostOpTwoYearStandingXrayAp = 'POST_OP_TWO_YEAR_STANDING_XRAY_AP',
  // Cervical
  C7C8ImplantMeasurementImage = 'C7_C8_IMPLANT_MEASUREMENT_IMAGE', // STANDARD PLUS C8
  C8T1ImplantMeasurementImage = 'C8_T1_IMPLANT_MEASUREMENT_IMAGE', // STANDARD PLUS C8
  C6T1ImplantMeasurementImage = 'C6_T1_IMPLANT_MEASUREMENT_IMAGE', // STANDARD MINUS C7
  C7T1ImplantMeasurementImage = 'C7_T1_IMPLANT_MEASUREMENT_IMAGE',
  C6C7ImplantMeasurementImage = 'C6_C7_IMPLANT_MEASUREMENT_IMAGE',
  C5C6ImplantMeasurementImage = 'C5_C6_IMPLANT_MEASUREMENT_IMAGE',
  C4C5ImplantMeasurementImage = 'C4_C5_IMPLANT_MEASUREMENT_IMAGE',
  C3C4ImplantMeasurementImage = 'C3_C4_IMPLANT_MEASUREMENT_IMAGE',
  C2C3ImplantMeasurementImage = 'C2_C3_IMPLANT_MEASUREMENT_IMAGE',
  // Lumbar
  L5L6ImplantMeasurementImage = 'L5_L6_IMPLANT_MEASUREMENT_IMAGE',
  L6S1ImplantMeasurementImage = 'L6_S1_IMPLANT_MEASUREMENT_IMAGE',
  L4S1ImplantMeasurementImage = 'L4_S1_IMPLANT_MEASUREMENT_IMAGE',
  L5S1ImplantMeasurementImage = 'L5_S1_IMPLANT_MEASUREMENT_IMAGE',
  L4L5ImplantMeasurementImage = 'L4_L5_IMPLANT_MEASUREMENT_IMAGE',
  L3L4ImplantMeasurementImage = 'L3_L4_IMPLANT_MEASUREMENT_IMAGE',
  L2L3ImplantMeasurementImage = 'L2_L3_IMPLANT_MEASUREMENT_IMAGE',
  L1L2ImplantMeasurementImage = 'L1_L2_IMPLANT_MEASUREMENT_IMAGE',
  StandingXrayLateral = 'STANDING_XRAY_LATERAL',
  StandingXrayAP = 'STANDING_XRAY_AP',

  CaseReportL5L6ImplantAppImage = 'CASE_REPORT_L5_L6_IMPLANT_APP_IMAGE',
  CaseReportL5L6ImplantPositionImage = 'CASE_REPORT_L5_L6_IMPLANT_POSITION_IMAGE',
  CaseReportL5L6ImplantMiniCheckTopImage = 'CASE_REPORT_L5_L6_IMPLANT_MINI_CHECK_TOP_IMAGE',
  CaseReportL5L6ImplantMiniCheckBottomImage = 'CASE_REPORT_L5_L6_IMPLANT_MINI_CHECK_BOTTOM_IMAGE',
  CaseReportL5L6ImplantScrewVerificationImage = 'CASE_REPORT_L5_L6_IMPLANT_SCREW_VERIFICATION_IMAGE',
  CaseReportL5L6ImplantScrewAnteriorPostionImage = 'CASE_REPORT_L5_L6_IMPLANT_SCREW_ANTERIOR_POSTION_IMAGE',
  CaseReportL5L6ImplantScrewLateralPostionImage = 'CASE_REPORT_L5_L6_IMPLANT_SCREW_LATERAL_POSTION_IMAGE',
  CaseReportL6S1ImplantAppImage = 'CASE_REPORT_L6_S1_IMPLANT_APP_IMAGE',
  CaseReportL6S1ImplantPositionImage = 'CASE_REPORT_L6_S1_IMPLANT_POSITION_IMAGE',
  CaseReportL6S1ImplantMiniCheckTopImage = 'CASE_REPORT_L6_S1_IMPLANT_MINI_CHECK_TOP_IMAGE',
  CaseReportL6S1ImplantMiniCheckBottomImage = 'CASE_REPORT_L6_S1_IMPLANT_MINI_CHECK_BOTTOM_IMAGE',
  CaseReportL6S1ImplantScrewVerificationImage = 'CASE_REPORT_L6_S1_IMPLANT_SCREW_VERIFICATION_IMAGE',
  CaseReportL6S1ImplantScrewAnteriorPostionImage = 'CASE_REPORT_L6_S1_IMPLANT_SCREW_ANTERIOR_POSTION_IMAGE',
  CaseReportL6S1ImplantScrewLateralPostionImage = 'CASE_REPORT_L6_S1_IMPLANT_SCREW_LATERAL_POSTION_IMAGE',
  CaseReportL4S1ImplantAppImage = 'CASE_REPORT_L4_S1_IMPLANT_APP_IMAGE',
  CaseReportL4S1ImplantPositionImage = 'CASE_REPORT_L4_S1_IMPLANT_POSITION_IMAGE',
  CaseReportL4S1ImplantMiniCheckTopImage = 'CASE_REPORT_L4_S1_IMPLANT_MINI_CHECK_TOP_IMAGE',
  CaseReportL4S1ImplantMiniCheckBottomImage = 'CASE_REPORT_L4_S1_IMPLANT_MINI_CHECK_BOTTOM_IMAGE',
  CaseReportL4S1ImplantScrewVerificationImage = 'CASE_REPORT_L4_S1_IMPLANT_SCREW_VERIFICATION_IMAGE',
  CaseReportL4S1ImplantScrewAnteriorPostionImage = 'CASE_REPORT_L4_S1_IMPLANT_SCREW_ANTERIOR_POSTION_IMAGE',
  CaseReportL4S1ImplantScrewLateralPostionImage = 'CASE_REPORT_L4_S1_IMPLANT_SCREW_LATERAL_POSTION_IMAGE',
  CaseReportL5S1ImplantAppImage = 'CASE_REPORT_L5_S1_IMPLANT_APP_IMAGE',
  CaseReportL5S1ImplantPositionImage = 'CASE_REPORT_L5_S1_IMPLANT_POSITION_IMAGE',
  CaseReportL5S1ImplantMiniCheckTopImage = 'CASE_REPORT_L5_S1_IMPLANT_MINI_CHECK_TOP_IMAGE',
  CaseReportL5S1ImplantMiniCheckBottomImage = 'CASE_REPORT_L5_S1_IMPLANT_MINI_CHECK_BOTTOM_IMAGE',
  CaseReportL5S1ImplantScrewVerificationImage = 'CASE_REPORT_L5_S1_IMPLANT_SCREW_VERIFICATION_IMAGE',
  CaseReportL5S1ImplantScrewAnteriorPostionImage = 'CASE_REPORT_L5_S1_IMPLANT_SCREW_ANTERIOR_POSTION_IMAGE',
  CaseReportL5S1ImplantScrewLateralPostionImage = 'CASE_REPORT_L5_S1_IMPLANT_SCREW_LATERAL_POSTION_IMAGE',
  CaseReportL4L5ImplantAppImage = 'CASE_REPORT_L4_L5_IMPLANT_APP_IMAGE',
  CaseReportL4L5ImplantPositionImage = 'CASE_REPORT_L4_L5_IMPLANT_POSITION_IMAGE',
  CaseReportL4L5ImplantMiniCheckTopImage = 'CASE_REPORT_L4_L5_IMPLANT_MINI_CHECK_TOP_IMAGE',
  CaseReportL4L5ImplantMiniCheckBottomImage = 'CASE_REPORT_L4_L5_IMPLANT_MINI_CHECK_BOTTOM_IMAGE',
  CaseReportL4L5ImplantScrewVerificationImage = 'CASE_REPORT_L4_L5_IMPLANT_SCREW_VERIFICATION_IMAGE',
  CaseReportL4L5ImplantScrewAnteriorPostionImage = 'CASE_REPORT_L4_L5_IMPLANT_SCREW_ANTERIOR_POSTION_IMAGE',
  CaseReportL4L5ImplantScrewLateralPostionImage = 'CASE_REPORT_L4_L5_IMPLANT_SCREW_LATERAL_POSTION_IMAGE',
  CaseReportL3L4ImplantAppImage = 'CASE_REPORT_L3_L4_IMPLANT_APP_IMAGE',
  CaseReportL3L4ImplantPositionImage = 'CASE_REPORT_L3_L4_IMPLANT_POSITION_IMAGE',
  CaseReportL3L4ImplantMiniCheckTopImage = 'CASE_REPORT_L3_L4_IMPLANT_MINI_CHECK_TOP_IMAGE',
  CaseReportL3L4ImplantMiniCheckBottomImage = 'CASE_REPORT_L3_L4_IMPLANT_MINI_CHECK_BOTTOM_IMAGE',
  CaseReportL3L4ImplantScrewVerificationImage = 'CASE_REPORT_L3_L4_IMPLANT_SCREW_VERIFICATION_IMAGE',
  CaseReportL3L4ImplantScrewAnteriorPostionImage = 'CASE_REPORT_L3_L4_IMPLANT_SCREW_ANTERIOR_POSTION_IMAGE',
  CaseReportL3L4ImplantScrewLateralPostionImage = 'CASE_REPORT_L3_L4_IMPLANT_SCREW_LATERAL_POSTION_IMAGE',
  CaseReportL2L3ImplantAppImage = 'CASE_REPORT_L2_L3_IMPLANT_APP_IMAGE',
  CaseReportL2L3ImplantPositionImage = 'CASE_REPORT_L2_L3_IMPLANT_POSITION_IMAGE',
  CaseReportL2L3ImplantMiniCheckTopImage = 'CASE_REPORT_L2_L3_IMPLANT_MINI_CHECK_TOP_IMAGE',
  CaseReportL2L3ImplantMiniCheckBottomImage = 'CASE_REPORT_L2_L3_IMPLANT_MINI_CHECK_BOTTOM_IMAGE',
  CaseReportL2L3ImplantScrewVerificationImage = 'CASE_REPORT_L2_L3_IMPLANT_SCREW_VERIFICATION_IMAGE',
  CaseReportL2L3ImplantScrewAnteriorPostionImage = 'CASE_REPORT_L2_L3_IMPLANT_SCREW_ANTERIOR_POSTION_IMAGE',
  CaseReportL2L3ImplantScrewLateralPostionImage = 'CASE_REPORT_L2_L3_IMPLANT_SCREW_LATERAL_POSTION_IMAGE',
  CaseReportL1L2ImplantAppImage = 'CASE_REPORT_L1_L2_IMPLANT_APP_IMAGE',
  CaseReportL1L2ImplantPositionImage = 'CASE_REPORT_L1_L2_IMPLANT_POSITION_IMAGE',
  CaseReportL1L2ImplantMiniCheckTopImage = 'CASE_REPORT_L1_L2_IMPLANT_MINI_CHECK_TOP_IMAGE',
  CaseReportL1L2ImplantMiniCheckBottomImage = 'CASE_REPORT_L1_L2_IMPLANT_MINI_CHECK_BOTTOM_IMAGE',
  CaseReportL1L2ImplantScrewVerificationImage = 'CASE_REPORT_L1_L2_IMPLANT_SCREW_VERIFICATION_IMAGE',
  CaseReportL1L2ImplantScrewAnteriorPostionImage = 'CASE_REPORT_L1_L2_IMPLANT_SCREW_ANTERIOR_POSTION_IMAGE',
  CaseReportL1L2ImplantScrewLateralPostionImage = 'CASE_REPORT_L1_L2_IMPLANT_SCREW_LATERAL_POSTION_IMAGE',
  CaseReportVertebraePreEditImage = 'CASE_REPORT_VERTEBRAE_PRE_EDIT_IMAGE',
  CaseReportVertebraePostEditImage = 'CASE_REPORT_VERTEBRAE_POST_EDIT_IMAGE',
  CaseReportImplantPreEditImage = 'CASE_REPORT_IMPLANT_PRE_EDIT_IMAGE',
  CaseReportImplantPostEditImage = 'CASE_REPORT_IMPLANT_POST_EDIT_IMAGE',
  CaseReportStandingXrayLateralMeasured = 'CASE_REPORT_STANDING_XRAY_LATERAL_MEASURED',

  // _EDITED vertebrae will be used in cloud design only. These will not be visualized
  // anywhere else (i.e. the mobile apps)
  T1Edited = 'T1_EDITED',
  C8Edited = 'C8_EDITED', // Level types for STANDARD PLUS C8
  C7Edited = 'C7_EDITED',
  C6Edited = 'C6_EDITED',
  C5Edited = 'C5_EDITED',
  C4Edited = 'C4_EDITED',
  C3Edited = 'C3_EDITED',
  C2Edited = 'C2_EDITED',
  C1Edited = 'C1_EDITED',
  S1Edited = 'S1_EDITED',
  L6Edited = 'L6_EDITED', // Level types for STANDARD PLUS L6
  L5Edited = 'L5_EDITED',
  L4Edited = 'L4_EDITED',
  L3Edited = 'L3_EDITED',
  L2Edited = 'L2_EDITED',
  L1Edited = 'L1_EDITED',

  PurchaseOrder = 'PURCHASE_ORDER',
  DHR = 'DHR',
  SignedDHR = 'SIGNED_DHR',
  PurchaseOrderPackaging = 'PURCHASE_ORDER_PACKAGING',
  MasterBuildRecord = 'MASTER_BUILD_RECORD',
  BookingSheet = 'BOOKING_SHEET',

  NtopAutoOp1AlifFile = 'NTOP_AUTO_OP1_ALIF_FILE',
  NtopManualOp1AlifFile = 'NTOP_MANUAL_OP1_ALIF_FILE',
  NtopAutoOp2AlifFile = 'NTOP_AUTO_OP2_ALIF_FILE',
  NtopManualOp2AlifFile = 'NTOP_MANUAL_OP2_ALIF_FILE',

  NtopAutoOp1AlifXFile = 'NTOP_AUTO_OP1_ALIF_X_FILE',
  NtopManualOp1AlifXFile = 'NTOP_MANUAL_OP1_ALIF_X_FILE',
  NtopAutoOp2AlifXFile = 'NTOP_AUTO_OP2_ALIF_X_FILE',
  NtopManualOp2AlifXFile = 'NTOP_MANUAL_OP2_ALIF_X_FILE',

  NtopAutoOp1LlifLeftFile = 'NTOP_AUTO_OP1_LLIF_LEFT_FILE',
  NtopManualOp1LlifLeftFile = 'NTOP_MANUAL_OP1_LLIF_LEFT_FILE',
  NtopAutoOp2LlifLeftFile = 'NTOP_AUTO_OP2_LLIF_LEFT_FILE',
  NtopManualOp2LlifLeftFile = 'NTOP_MANUAL_OP2_LLIF_LEFT_FILE',

  NtopAutoOp1LlifRightFile = 'NTOP_AUTO_OP1_LLIF_RIGHT_FILE',
  NtopManualOp1LlifRightFile = 'NTOP_MANUAL_OP1_LLIF_RIGHT_FILE',
  NtopAutoOp2LlifRightFile = 'NTOP_AUTO_OP2_LLIF_RIGHT_FILE',
  NtopManualOp2LlifRightFile = 'NTOP_MANUAL_OP2_LLIF_RIGHT_FILE',

  NtopAutoOp1TlifCLeftFile = 'NTOP_AUTO_OP1_TLIF_C_LEFT_FILE',
  NtopManualOp1TlifCLeftFile = 'NTOP_MANUAL_OP1_TLIF_C_LEFT_FILE',
  NtopAutoOp2TlifCLeftFile = 'NTOP_AUTO_OP2_TLIF_C_LEFT_FILE',
  NtopManualOp2TlifCLeftFile = 'NTOP_MANUAL_OP2_TLIF_C_LEFT_FILE',

  NtopAutoOp1TlifCRightFile = 'NTOP_AUTO_OP1_TLIF_C_RIGHT_FILE',
  NtopManualOp1TlifCRightFile = 'NTOP_MANUAL_OP1_TLIF_C_RIGHT_FILE',
  NtopAutoOp2TlifCRightFile = 'NTOP_AUTO_OP2_TLIF_C_RIGHT_FILE',
  NtopManualOp2TlifCRightFile = 'NTOP_MANUAL_OP2_TLIF_C_RIGHT_FILE',

  NtopAutoOp1TlifOLeftFile = 'NTOP_AUTO_OP1_TLIF_O_LEFT_FILE',
  NtopManualOp1TlifOLeftFile = 'NTOP_MANUAL_OP1_TLIF_O_LEFT_FILE',
  NtopAutoOp2TlifOLeftFile = 'NTOP_AUTO_OP2_TLIF_O_LEFT_FILE',
  NtopManualOp2TlifOLeftFile = 'NTOP_MANUAL_OP2_TLIF_O_LEFT_FILE',

  NtopAutoOp1TlifORightFile = 'NTOP_AUTO_OP1_TLIF_O_RIGHT_FILE',
  NtopManualOp1TlifORightFile = 'NTOP_MANUAL_OP1_TLIF_O_RIGHT_FILE',
  NtopAutoOp2TlifORightFile = 'NTOP_AUTO_OP2_TLIF_O_RIGHT_FILE',
  NtopManualOp2TlifORightFile = 'NTOP_MANUAL_OP2_TLIF_O_RIGHT_FILE',

  CommentAttachment = 'COMMENT_ATTACHMENT',
}

export enum PostOpAnalysisType {
  PreOp = 'PRE_OP',
  Plan = 'PLAN',
  PostOpIntraOp = 'POST_OP_INTRA_OP',
  PostOpSixWeeks = 'POST_OP_SIX_WEEKS',
  PostOpSixMonths = 'POST_OP_SIX_MONTHS',
  PostOpOneYear = 'POST_OP_ONE_YEAR',
  PostOpTwoYears = 'POST_OP_TWO_YEARS',
}

export enum EndPlate {
  Superior = 'SUPERIOR',
  Inferior = 'INFERIOR',
}

export enum Axis {
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}

export enum RotationalDirection {
  CCW = 'CCW',
  CW = 'CW',
}

export enum VertebralBody {
  // Cervical
  T1 = 'T1',
  C8 = 'C8', // STANDARD PLUS L6
  C7 = 'C7',
  C6 = 'C6',
  C5 = 'C5',
  C4 = 'C4',
  C3 = 'C3',
  C2 = 'C2',
  C1 = 'C1',
  // Lumbar
  S1 = 'S1',
  L6 = 'L6', // STANDARD PLUS L6
  L5 = 'L5',
  L4 = 'L4',
  L3 = 'L3',
  L2 = 'L2',
  L1 = 'L1',
}

export enum Position {
  Posterior = 'POSTERIOR',
  Anterior = 'ANTERIOR',
  PatientRight = 'PATIENT_RIGHT',
  PatientLeft = 'PATIENT_LEFT',
  PosteriorEdge = 'POSTERIOR_EDGE',
}

export enum Operation {
  TranslateZ = 'TRANSLATE_Z',
  RotateZ = 'ROTATE_Z',
  TranslateY = 'TRANSLATE_Y',
  RotateY = 'ROTATE_Y',
  TranslateX = 'TRANSLATE_X',
  RotateX = 'ROTATE_X',
}

export enum CaseStageTypeColor {
  Draft = '#f5f5f5',
  Open = '#E5001C',
  Segmentation = '#ff9e43',
  Planning = '#DE0591',
  Proposed = '#8509D8',
  Design = '#1239CB',
  QaReview = '#1696C4',
  Production = '#19BE93',
  Ready = '#1DB83E',
  Complete = '#BEBEBE',
}

export enum MeasurementType {
  LumbarLordosis = 'LUMBAR_LORDOSIS',
  LumbarCoronalCobb = 'LUMBAR_CORONAL_COBB',
  SegmentalLordoticAngle = 'SEGMENTAL_LORDOTIC_ANGLE',
  SegmentalCoronalAngle = 'SEGMENTAL_CORONAL_ANGLE',
  LordoticAngle = 'LORDOTIC_ANGLE',
  CoronalAngle = 'CORONAL_ANGLE',
  PosteriorHeight = 'POSTERIOR_HEIGHT',
  AnteriorHeight = 'ANTERIOR_HEIGHT',
  LumbarAngle = 'LUMBAR_ANGLE',
  ThoracicKyphosis = 'THORACIC_KYPHOSIS',
  SagittalVerticalAxis = 'SAGITTAL_VERTICAL_AXIS',
  RealSegmentalLordoticAngle = 'REAL_SEGMENTAL_LORDOTIC_ANGLE',
  RealSegmentalCoronalAngle = 'REAL_SEGMENTAL_CORONAL_ANGLE',
}

export enum CoronalCorrectionGoalType {
  DiscSpaceParallelization = 'DISC_SPACE_PARALLELIZATION',
  HightestSuperiorEndplateParallelization = 'HIGHTEST_SUPERIOR_ENDPLATE_PARALLELIZATION',
  FloorParallelization = 'FLOOR_PARALLELIZATION',
  ZeroCoronal = 'ZERO_CORONAL',
  Other = 'OTHER',
  None = 'NONE',
}

export enum AxialCorrectionGoalType {
  Include = 'INCLUDE',
  Other = 'OTHER',
  None = 'NONE',
}

export enum HeightRestorationGoalType {
  TEM013 = 'TEM013',
  Specified = 'SPECIFIED',
  None = 'NONE',
}

export interface ITableHeader {
  id: string;
  sortable?: boolean;
  label: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  customLabel?: JSX.Element;
}

// Patient registry emum types
export enum RegistryStage {
  Preop = 'PREOP',
  Discharge = 'DISCHARGE',
  SixWeek = 'SIX_WEEK',
  ThreeMonth = 'THREE_MONTH',
  SixMonth = 'SIX_MONTH',
  TwelveMonth = 'TWELVE_MONTH',
  TwentyFourMonth = 'TWENTY_FOUR_MONTH',
}

export enum ComorbidityType {
  BreastCancer = 'BREAST_CANCER',
  Smoking = 'SMOKING',
  Type2Diabetes = 'TYPE_2_DIABETES',
}

export enum NarcoticUsageType {
  Fentanyl = 'FENTANYL',
  Hydrocodone = 'HYDROCODONE',
  Meperidine = 'MEPERIDINE',
  Methadone = 'METHADONE',
  Morphine = 'MORPHINE',
  Vicodin = 'VICODIN',
  Otc = 'OTC',
}

export enum DepressiveMedicationType {
  Serzone = 'SERZONE',
  Zoloft = 'ZOLOFT',
  Zyprexa = 'ZYPREXA',
}

export enum PresentingConditionType {
  CompensatoryThoracic = 'COMPENSATORY_THORACIC',
  DeformityRelatedToDdd = 'DEFORMITY_RELATED_TO_DDD',
  DoubleMajor = 'DOUBLE_MAJOR',
  LumbarDeformity = 'LUMBAR_DEFORMITY',
  PelvicObliquity = 'PELVIC_OBLIQUITY',
}

export enum PreviousSpineSurgeryIndicatedLevel {
  FailedMisDecompression = 'FAILED_MIS_DECOMPRESSION',
}

export enum Level {
  L1L2 = 'L1_L2',
  L2L3 = 'L2_L3',
  L3L4 = 'L3_L4',
  L4L5 = 'L4_L5',
  L5S1 = 'L5_S1',
  L5L6 = 'L5_L6', // STANDARD PLUS L6
  L6S1 = 'L6_S1', // STANDARD PLUS L6
  L4S1 = 'L4_S1', // STANDARD MINUS L5
}

export enum LIVLocationType {
  L1L2 = 'L1_L2',
  L2L3 = 'L2_L3',
  L3L4 = 'L3_L4',
  L4L5 = 'L4_L5',
  L5S1 = 'L5_S1',
  L5L6 = 'L5_L6', // STANDARD PLUS L6
  L6S1 = 'L6_S1', // STANDARD PLUS L6
  L4S1 = 'L4_S1', // STANDARD MINUS L5
  SacrumS1S2 = 'SACRUM_S1_S2',
  Ilium = 'ILIUM',
}

export enum UIVLocationType {
  T1T2 = 'T1_T2',
  T2T3 = 'T2_T3',
  T3T4 = 'T3_T4',
  T4T5 = 'T4_T5',
  T5T6 = 'T5_T6',
  T6T7 = 'T6_T7',
  T7T8 = 'T7_T8',
  T8T9 = 'T8_T9',
  T9T10 = 'T9_T10',
  T10T11 = 'T10_T11',
  T11T12 = 'T11_T12',
  T12Caudal = 'T12_CAUDAL',
}

export enum BiologicsUsedType {
  AutograftIliacCrest = 'AUTOGRAFT_ILIAC_CREST',
  AutograftLocal = 'AUTOGRAFT_LOCAL',
  AllograftCancellous = 'ALLOGRAFT_CANCELLOUS',
  Bmp2 = 'BMP_2',
  BoneMarrowAspirate = 'BONE_MARROW_ASPIRATE',
  CellBasedAllograft = 'CELL_BASED_ALLOGRAFT',
}

export enum IntraoperativeComplicationType {
  DeviceFailure = 'DEVICE_FAILURE',
  Instrument = 'INSTRUMENT',
}

export enum StabilizationTechniqueType {
  AnteriorPlate = 'ANTERIOR_PLATE',
  ButtressPlate = 'BUTTRESS_PLATE',
  PedicleScrews = 'PEDICLE_SCREWS',
}

export enum PainManagementMedicationType {
  Fentanyl = 'FENTANYL',
  Hydrocodone = 'HYDROCODONE',
  Meperidine = 'MEPERIDINE',
  Methadone = 'METHADONE',
  Morphine = 'MORPHINE',
  Vicodin = 'VICODIN',
  Otc = 'OTC',
}

export enum PostopImplantComplicationType {
  AnteriorMigration = 'ANTERIOR_MIGRATION',
  Subsidence = 'SUBSIDENCE',
}

export enum PostopSurgeryComplicationType {
  Infection = 'INFECTION',
  Pjf = 'PJF',
  Pjk = 'PJK',
}

export enum SurgeonApproachType {
  None = 'NONE',
  ALIF = 'ALIF',
  ALIFX = 'ALIF_X',
  LLIF = 'LLIF',
  TLIFC = 'TLIF_C',
  TLIFCA = 'TLIF_CA',
  TLIFO = 'TLIF_O',
}

export enum NavigationSystemType {
  PosteriorFixation = 'POSTERIOR_FIXATION',
  Interbody = 'INTERBODY',
  DiscPrep = 'DISC_PREP',
}

export enum InterbodySystemType {
  Allosource = 'ALLOSOURCE',
  Alphatec = 'ALPHATEC',
  Medtronic = 'MEDTRONIC',
  DePuySynthes = 'DEPUY_SYNTHES',
  NuVasive = 'NUVASIVE',
  GlobusMedical = 'GLOBUS_MEDICAL',
  Orthofix = 'ORTHOFIX',
  K2Stryker = 'K2_STRYKER',
  SpineArt = 'SPINE_ART',
  Lifenet = 'LIFENET',
  ZimmerBiomet = 'ZIMMER_BIOMET',
  Other = 'OTHER',
}

export enum ImplantPositionType {
  Anterior = 'ANTERIOR',
  AnteriorMiddle = 'ANTERIOR_MIDDLE',
  Middle = 'MIDDLE',
  Midline = 'MIDLINE',
  OffMidline = 'OFF_MIDLINE',
  Other = 'OTHER',
  Posterior = 'POSTERIOR',
  PosteriorMiddle = 'POSTERIOR_MIDDLE',
  PatientLeft = 'PATIENT_LEFT',
  PatientRight = 'PATIENT_RIGHT',
}

export enum TaskPriorityType {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}

export enum TaskStatusType {
  Open = 'OPEN',
  Complete = 'COMPLETE',
  Cancelled = 'CANCELLED',
}

export enum Permission {
  EditCase = 'EDIT_CASE',
  EditDiscussions = 'EDIT_DISCUSSIONS',
  FieldRepCase = 'FIELD_REP_CASE',
  ManageCase = 'MANAGE_CASE',
  ManageCaseInventory = 'MANAGE_CASE_INVENTORY',
  ManageSurgeon = 'MANAGE_SURGEON',
  ManageOrganization = 'MANAGE_ORGANIZATION',
  ManagePlanApproval = 'MANAGE_PLAN_APPROVAL',
  ManagePostOpAnalysis = 'MANAGE_POST_OP_ANALYSIS',
  ManagePriceLevel = 'MANAGE_PRICE_LEVEL',
  ManageSiteConfiguration = 'MANAGE_SITE_CONFIGURATION',
  ManageSiteSettings = 'MANAGE_SITE_SETTINGS',
  ManageSurgeonPreferences = 'MANAGE_SURGEON_PREFERENCES',
  ManageTask = 'MANAGE_TASK',
  ManageUser = 'MANAGE_USER',
  ManageUserPermissions = 'MANAGE_USER_PERMISSIONS',
  ListCases = 'LIST_CASES',
  ListOrganizations = 'LIST_ORGANIZATIONS',
  ListPatients = 'LIST_PATIENTS',
  ListPostOpAnalyses = 'LIST_POST_OP_ANALYSES',
  ListReports = 'LIST_REPORTS',
  ForecastReport = 'FORECAST_REPORT',
  ListPriceLevels = 'LIST_PRICE_LEVELS',
  ListSurgeons = 'LIST_SURGEONS',
  ListTasks = 'LIST_TASKS',
  ListUsers = 'LIST_USERS',
  QaReviewCase = 'QA_REVIEW_CASE',
  ReportExportCaseData = 'REPORT_EXPORT_CASE_DATA',
  ViewCase = 'VIEW_CASE',
  ViewOrganization = 'VIEW_ORGANIZATION',
  ViewPatient = 'VIEW_PATIENT',
  ViewPostOpAnalysis = 'VIEW_POST_OP_ANALYSIS',
  ViewPriceLevel = 'VIEW_PRICE_LEVEL',
  ViewSelf = 'VIEW_SELF',
  ViewSurgeon = 'VIEW_SURGEON',
  ViewTask = 'VIEW_TASK',
  ViewUser = 'VIEW_USER',
}

export type ImplantThreadAngleType = {
  oblique: number | null;
  cranialCaudal: number | null;
};

export type NotificationType =
  | 'CASE_AUTO_SEGMENTED'
  | 'CASE_STUDY_DATE_UPDATED'
  | 'CASE_UPDATED'
  | 'CASE_IMAGING_UPDATED'
  | 'CASE_CREATED'
  | 'DRAFT_CASE_CREATED'
  | 'QA_APPROVED'
  | 'QA_REVIEW'
  | 'QA_REVIEW_REJECTED'
  | 'PEER_REVIEW'
  | 'TASK_UPDATED'
  | 'CASE_SEGMENTED'
  | 'CASE_PROPOSED'
  | 'CASE_APPROVED'
  | 'CASE_REJECTED'
  | 'CASE_COMPLETED'
  | 'CASE_FINAL_RELEASE'
  | 'CASE_DELIVERED'
  | 'CASE_CANCELLED'
  | 'CASE_RESTORED'
  | 'POST_OP_ANALYSIS_IMAGING_UPDATED'
  | 'SURGEON_CREATED'
  | 'NEW_CASE_COMMENT'
  | 'CLOUD_DESIGN_CUT_COMPLETED'
  | 'CLOUD_DESIGN_CUT_AND_MESH_COMPLETED'
  | 'DAISY_COMPLETED'
  | 'TASK_REMINDER'
  | 'NEW_TASK'
  | 'CASE_HELD'
  | 'DHR_REVIEW_REQUESTED'
  | 'DHR_REVIEW_APPROVED'
  | 'DHR_REVIEW_REJECTED'
  | 'CASE_SHIPMENT_EXCEPTION'
  | 'CASE_REMOVE_HOLD'
  | 'CASE_DRAFT_REOPENED'
  | 'CASE_SURGERY_DATE_UPDATED'
  | 'TOTP_REQUEST'
  | 'TEST'
  | 'MANUFACTURING_PO_UPLOADED'
  | 'PACKAGING_PO_UPLOADED'
  | 'MBR_UPLOADED';

export enum NotificationOriginType {
  Task = 'TASK',
}

export enum NotificationStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Sent = 'SENT',
}

export enum TaskActionType {
  DicomCtScanNeeded = 'DICOM_CT_SCAN_NEEDED',
  NewDicomCtScanNeeded = 'NEW_DICOM_CT_SCAN_NEEDED',
  SurgeonCasePlanApprovalNeeded = 'SURGEON_CASE_PLAN_APPROVAL_NEEDED',
  SurgeonPreferencesNeeded = 'SURGEON_PREFERENCES_NEEDED',
  UploadSixWeekPostOpFeedback = 'UPLOAD_SIX_WEEK_POST_OP_FEEDBACK',
  UploadSixMonthPostOpFeedback = 'UPLOAD_SIX_MONTH_POST_OP_FEEDBACK',
  UpdateSurgeryDate = 'UPDATE_CASE_SURGERY_DATE',
}

export enum UserType {
  User = 'USER',
  System = 'SYSTEM',
}

export enum Form20ProductStatusType {
  Archived = 'ARCHIVED',
  InUse = 'IN_USE',
  PreMarket = 'PRE_MARKET',
}

export enum Form20ProductType {
  Implant = 'IMPLANT',
  Instrument = 'INSTRUMENT',
  AlifXScrew = 'ALIF_X_SCREW',
}

export enum ImplantCharacteristicType {
  ImplantName = 'IMPLANT_NAME',
  ApDepthB = 'AP_DEPTH_B',
  MlWidthA = 'ML_WIDTH_A',
  MaxHeightC = 'MAX_HEIGHT_C',
  BulletAngle = 'BULLET_ANGLE',
  BulletHeight = 'BULLET_HEIGHT',
  LeftBulletAngle = 'LEFT_BULLET_ANGLE',
  LeftBulletHeight = 'LEFT_BULLET_HEIGHT',
  RightBulletAngle = 'RIGHT_BULLET_ANGLE',
  RightBulletHeight = 'RIGHT_BULLET_HEIGHT',
  CoronalAngle = 'CORONAL_ANGLE',
  LordoticAngle = 'LORDOTIC_ANGLE',
  BulletMinDistance = 'BULLET_MIN_DISTANCE',
  BulletMinHeight = 'BULLET_MIN_HEIGHT',
  CosmeticCheck = 'COSMETIC_CHECK',
  PartMarkings = 'PART_MARKINGS',
}

export enum Form19ErrorCodeType {
  PhysicianApprovalMissing = 'PHYSICIAN_APPROVAL_MISSING',
  QualityApprovalMissing = 'QUALITY_APPROVAL_MISSING',
  EngineeringApprovalMissing = 'ENGINEERING_APPROVAL_MISSING',
  PeerApprovalMissing = 'PEER_APPROVAL_MISSING',
  EntireLumbar = 'ENTIRE_LUMBAR',
  ImagingExpired = 'IMAGING_EXPIRED',
  InvalidImagingSlice = 'INVALID_IMAGING_SLICE',
  OutOfRange = 'OUT_OF_RANGE',
  Difference = 'DIFFERENCE',
  VisuallyNotInspected = 'VISUALLY_NOT_INSPECTED',
  Form19RevisionNoMissing = 'FORM19_REVISION_NO_MISSING',
  Form19RevisionDescriptionMissing = 'FORM19_REVISION_DESCRIPTION_MISSING',
  LevelRevisionNoMissing = 'LEVEL_REVISION_NO_MISSING',
  LevelRevisionDescriptionMissing = 'LEVEL_REVISION_DESCRIPTION_MISSING',
  AlifXScrewLengthMissing = 'ALIF_X_SCREW_LENGTH_MISSING',
}

export enum PatientGender {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum ProductionStageType {
  PreProduction = 'PRE_PRODUCTION',
  SurgeonApproval = 'SURGEON_APPROVAL',
  Manufacturing = 'MANUFACTURING',
  HIP = 'HIP',
  Packaging = 'PACKAGING',
  Sterilization = 'STERILIZATION',
  Distribution = 'DISTRIBUTION',
}

export enum ManufacturingProductionSubstageType {
  BuildFileCreation = 'BUILD_FILE_CREATION',
  AdditiveManufacturing = 'ADDITIVE_MANUFACTURING',
  BuildPlateCheckAndRemoval = 'BUILD_PLATE_CHECK_AND_REMOVAL',
  ReceiveHIP = 'RECEIVE_HIP',
  PackageAndRelease = 'PACKAGE_AND_RELEASE',
}

export enum HIPProductionSubstageType {
  Transit = 'TRANSIT',
  Hipping = 'HIPPING',
}

export enum PackagingProductionSubstageType {
  Receive = 'RECEIVE',
  PackagingAndLabeling = 'PACKAGING_AND_LABELING',
  ReceiveShipSterilized = 'RECEIVE_SHIP_STERILIZED',
}

export enum SterilizationProductionSubstageType {
  Receive = 'RECEIVE',
  Sterilization = 'STERILIZATION',
}

export enum DistributionProductionSubstageType {
  ReceivePackage = 'RECEIVE_PACKAGE',
  ReadyForSurgery = 'READY_FOR_SURGERY',
}

export enum MeasurementWarningTypes {
  Warning = 'WARNING',
  Danger = 'DANGER',
}

export enum TableType {
  Preop = 'PREOP',
  Daisy = 'DAISY',
  Plan = 'PLAN',
  PlanDiff = 'PLAN_DIFF',
  Postop = 'POST_OP',
}

export enum AssetSuffix {
  ImplantSuffix = '_IMPLANT',
  PointSuffix = '.POINT',
  AxisSuffix = '.AXIS',
  PreopSuffix = '_PREOP',
  PlanSuffix = '_PLAN',
  ScrewSuffix = '_SCREWS',
}

export enum Result {
  Fail = 'FAIL',
  Pass = 'PASS',
}

export enum EnvironmentName {
  Local = 'local',
  Dev = 'dev',
  Vnv = 'vnv',
  Production = 'production',
}

export enum ErrorCode {
  CaseNotFound = 'CASE_NOT_FOUND',
  PlanNotFound = 'PLAN_NOT_FOUND',
  PartTypeNotFound = 'PART_TYPE_NOT_FOUND',
  PartTypeNotSupported = 'PART_TYPE_NOT_SUPPORTED',
  PartTypeCodeUnknown = 'PART_TYPE_CODE_UNKNOWN',
  AssetPositionsInterbodyNotFound = 'ASSET_POSITIONS_INTERBODY_NOT_FOUND',
  InvalidImportCaseData = 'INVALID_IMPORT_CASE_DATA',
  PlanImplantNotFound = 'PLAN_IMPLANT_NOT_FOUND',
  ParentVertebralBodyNotFound = 'PARENT_VERTEBRAL_BODY_NOT_FOUND',
  ChildVertebralBodyNotFound = 'CHILD_VERTEBRAL_BODY_NOT_FOUND',
  VertebralBodyAssetsNotFound = 'VERTEBRAL_BODY_ASSETS_NOT_FOUND',
  CloudDesignQueueNotFound = 'CLOUD_DEVELOPMENT_QUEUE_NOT_FOUND',
  RevisionNotFound = 'REVISION_NOT_FOUND',
  ImplantOrientationCSV = 'IMPLANT_ORIENTATION_CSV_NOT_FOUND',
  NtopCallFileNotFound = 'NTOP_CALL_FILE_NOT_FOUND',
  NtopParasolidNotFound = 'NTOP_PARASOLID_NOT_FOUND',
  NtopConfigurationJsonNotFound = 'NTOP_CONFIGURATION_JSON_NOT_FOUND',
  ApInvalid = 'AP_INVALID',
  MlInvalid = 'ML_INVALID',
  PartOutOfBound = 'PART_OUT_OF_BOUND',
  LevelNotFound = 'LEVEL_NOT_FOUND',
  LevelSizeNotFound = 'LEVEL_SIZE_NOT_FOUND',
  CaseStageNotPlanning = 'CASE_STAGE_NOT_PLANNING',
  CaseStageNotDesign = 'CASE_STAGE_NOT_DESIGN',
  ImplantAssetsNotFound = 'IMPLANT_ASSETS_NOT_FOUND',
  JsonFormFailedValidation = 'FORM_HAS_FAILED_JSON_VALIDATION',
}

export enum CloudDesignQueueType {
  CutAndMesh = 'CUT_AND_MESH',
  Cut = 'CUT',
}

export enum ValidFileExtensions {
  JPG = '.jpg',
  JPEG = '.jpeg',
  STL = '.stl',
  PDF = '.pdf',
  PNG = '.png',
  XLSX = '.xlsx',
  XLSM = '.xlsm',
  TXT = '.txt',
  OBJ = '.obj',
  ZIP = '.zip',
  CSV = '.csv',
  DOCX = '.docx',
  NTOP = '.ntop',
}

export enum CutStatus {
  Ready = 'READY',
  CuttingInProgress = 'CUTTING_IN_PROGRESS',
  CutCompleted = 'CUT_COMPLETED',
  CutError = 'CUT_ERROR',
}

export enum InterbodyScrewLengthTypes {
  None = 'NONE',
  AlifXScrewLength20mm = 'ALIF_X_SCREW_LENGTH_20MM',
  AlifXScrewLength25mm = 'ALIF_X_SCREW_LENGTH_25MM',
  AlifXScrewLength30mm = 'ALIF_X_SCREW_LENGTH_30MM',
}

export enum CaseRiskAssessmentType {
  CaseReviewQaReview = 'CASE_REVIEW_QA_REVIEW',
  QaReviewOnly = 'QA_REVIEW_ONLY',
}

export enum PatientContactPreferenceType {
  Phone = 'PHONE',
  Text = 'TEXT',
  Email = 'EMAIL',
}
export enum PatientContactCadenceType {
  OptOut = 'OPT_OUT',
  Weekly = 'WEEKLY',
  BiWeekly = 'BI_WEEKLY',
}

export enum CommentType {
  Case = 'CASE_COMMENT',
  Patient = 'PATIENT_COMMENT',
  Production = 'PRODUCTION_COMMENT',
}

export enum MeasurementsVersionType {
  Version1 = 1,
  Version2 = 2,
}

export enum PlanVersionType {
  Version1 = 1,
  Version2 = 2,
}

export enum KitCartonRuleType {
  InvalidCase = 'INVALID_CASE',
  NonAlifXCase = 'NON_ALIF_X_CASE',
  AlifXCase = 'ALIF_X_CASE',
  NonAlifXAndAlifXCase = 'NON_ALIF_X_AND_ALIF_X_CASE',
  NonAlifXNavAndAlifXCase = 'NON_ALIF_X_NAV_AND_ALIF_X_CASE',
  TlifCACase = 'TLIF_CA_CASE',
  NonAlifXAndTlifCACase = 'NON_ALIF_X_AND_TLIF_CA_CASE',
  NonAlifXAndTlifCAAndAlifXCase = 'NON_ALIF_X_AND_TLIF_CA_AND_ALIF_X_CASE',
  TlifCAAndAlifXCase = 'TLIF_CA_AND_ALIF_X_CASE',
  AlifXReusableInstrumentCase = 'ALIF_X_REUSABLE_INSTRUMENT_CASE',
  TlifCAAndAlifXReusableInstrumentCase = 'TLIF_CA_AND_ALIF_X_REUSABLE_INSTRUMENT_CASE',
  NonAlifXAndAlifXReusableInstrumentCase = 'NON_ALIF_X_AND_ALIF_X_REUSABLE_INSTRUMENT_CASE',
  NonAlifXAndTlifCAAndAlifXReusableInstrumentCase = 'NON_ALIF_X_AND_TLIF_CA_AND_ALIF_X_REUSABLE_INSTRUMENT_CASE',
}
export enum CaseHoldReasonType {
  Medical = 'MEDICAL',
  Administrative = 'ADMINISTRATIVE',
  PendingCaseApproval = 'PENDING_CASE_APPROVAL',
  Unknown = 'UNKNOWN',
}

export enum AgeRange {
  Under40 = 'UNDER_40',
  In40 = 'IN_40',
  In50 = 'IN_50',
  In60 = 'IN_60',
  In70 = 'IN_70',
  Above80 = 'ABOVE_80',
}

export enum CaseShippingStatusType {
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  Error = 'ERROR',
  Failure = 'FAILURE',
  InTransit = 'IN_TRANSIT',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  PreTransit = 'PRE_TRANSIT',
  ReturnToSender = 'RETURN_TO_SENDER',
  Unknown = 'UNKNOWN',
}

export enum SagittalGoalType {
  AgeAdjusted = 'AGE_ADJUSTED',
  MinimalMismatch = 'MINIMAL_MISMATCH',
  GapScore = 'GAP_SCORE',
  AlternativeSupportivePlanningReference = 'ALTERNATIVE_SUPPORTIVE_PLANNING_REFERENCE',
  Other = 'OTHER',
}

export enum CoronalGoalType {
  ParallelDiscSpaceAtTreatmentLevels = 'PARALLEL_DISC_SPACE_AT_TREATMENT_LEVELS',
  SuperiorEndplateOfHighestTreatedLevelParallelToSacrum = 'SUPERIOR_ENDPLATE_OF_HIGHEST_TREATED_LEVEL_PARALLEL_TO_SACRUM',
  SuperiorL1EndplateParallelToSacrum = 'SUPERIOR_L1_ENDPLATE_PARALLEL_TO_SACRUM',
  SuperiorL1EndplateParallelToFloor = 'SUPERIOR_L1_ENDPLATE_PARALLEL_TO_FLOOR',
}

export enum PositionDistanceType {
  DistanceFromAnteriorEdge = 'DISTANCE_FROM_ANTERIOR_EDGE',
  DistanceFromApCenter = 'DISTANCE_FROM_AP_CENTER',
  AnteriorThird = 'ANTERIOR_THIRD',
  Midline = 'MIDLINE',
  PosteriorThird = 'POSTERIOR_THIRD',
}

export enum SurgeonPreferenceMeasurementType {
  FootprintApRange = 'FOOTPRINT_AP_RANGE',
  FootprintMlRange = 'FOOTPRINT_ML_RANGE',
  AnteriorHeightRange = 'ANTERIOR_HEIGHT_RANGE',
  PosteriorHeightRange = 'POSTERIOR_HEIGHT_RANGE',
  LordosisAngleRange = 'LORDOSIS_ANGLE_RANGE',
  ImplantPositionDistanceRange = 'IMPLANT_POSITION_DISTANCE_RANGE',
}

export enum SurgeonPreferencesErrorCodeType {
  OutOfRange = 'OUT_OF_RANGE',
  MinOutOfRange = 'MIN_OUT_OF_RANGE',
  MaxOutOfRange = 'MAX_OUT_OF_RANGE',
  MinCannotBeGreaterThanMax = 'MIN_CANNOT_BE_GREATER_THAN_MAX',
  SagittalGoalPrimaryMissing = 'SAGITTAL_GOAL_PRIMARY_MISSING',
  SagittalGoalSecondaryMissing = 'SAGITTAL_GOAL_SECONDARY_MISSING',
  SagittalGoalOtherMissing = 'SAGITTAL_GOAL_OTHER_MISSING',
  ScrewOrientationMissing = 'SCREW_ORIENTATION_MISSING',
  ScrewOrientationByLevelMissing = 'SCREW_ORIENTATION_BY_LEVEL_MISSING',
  ThreadAngleMissing = 'THREAD_ANGLE_MISSING',
}

export enum TargetHeightMetricType {
  ImplantHeight = 'IMPLANT_HEIGHT',
  DiscSpaceHeight = 'DISC_SPACE_HEIGHT',
  CaseSpecific = 'CASE_SPECIFIC',
}

export enum ExternalSyncRecordType {
  Organization = 'ORGANIZATION',
  Location = 'LOCATION',
}

export enum VendorTaskType {
  UploadDhr = 'UPLOAD_DHR',
  CorrectDhr = 'CORRECT_DHR',
  ShipCase = 'SHIP_CASE',
}

export enum VendorTaskStatus {
  Pending = 'PENDING',
  Complete = 'COMPLETE',
}

export enum VendorTaskCategory {
  AdditivePrinting = 'ADDITIVE_PRINTING',
  FinalShipment = 'FINAL_SHIPMENT',
}
export enum ThreadType {
  M4 = 'M4',
  M4L = 'M4L',
  M5 = 'M5',
  M2_2 = 'M2_2',
}

export enum ConfigurationKeyType {
  FreeAgent = 'FREE_AGENT',
  Email = 'EMAIL',
  OnShape = 'ON_SHAPE',
  OneSignal = 'ONE_SIGNAL',
  Daisy = 'DAISY',
  CloudDesign = 'CLOUD_DESIGN',
  AwsSimpleEmailService = 'AWS_SIMPLE_EMAIL_SERVICE',
  AwsSimpleNotificationService = 'AWS_SIMPLE_NOTIFICATION_SERVICE',
  EasyPost = 'EASY_POST',
  GreenlightGuru = 'GREENLIGHT_GURU',
}

export enum ConfigurationValueType {
  Email = 'EMAIL',
  Text = 'TEXT',
  Boolean = 'BOOLEAN',
  Number = 'NUMERIC',
  Password = 'PASSWORD',
  Url = 'URL',
}

export enum CaseReportStatusType {
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export enum SegmentationImagingAssessmentType {
  GoldCtFullLengthXrayWithFemoralHeads = 'GOLD_CT_FULL_LENGTH_XRAY_WITH_FEMORAL_HEADS',
  SilverCtStandardLumbarXrayWithFemoralHeads = 'SILVER_CT_STANDARD_LUMBAR_XRAY_WITH_FEMORAL_HEADS',
  BronzeCtStandardLumbarXrayWithoutFemoralHeads = 'BRONZE_CT_STANDARD_LUMBAR_XRAY_WITHOUT_FEMORAL_HEADS',
  BronzeCtNoXrayOrUnusableXray = 'BRONZE_CT_NO_XRAY_OR_UNUSABLE_XRAY',
}

export enum SegmentationType {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}

export enum LordosisDifferenceRationaleType {
  HypermobilityInDiscSpaces = 'HYPERMOBILITY_IN_DISC_SPACES',
  XrayQualityCausingInaccurateS1OrL1Placement = 'XRAY_QUALITY_CAUSING_INACCURATE_S1_OR_L1_PLACEMENT',
  XrayQualityCausingInaccurateFemoralHeadPlacement = 'XRAY_QUALITY_CAUSING_INACCURATE_FEMORAL_HEAD_PLACEMENT',
  Other = 'OTHER',
}

export enum StatementType {
  CorrectionPlanning = 'CORRECTION_PLANNING',
  ImplantDesign = 'IMPLANT_DESIGN',
}

export enum Statement {
  LordoticAngleAchievable = 'LORDOTIC_ANGLE_ACHIEVABLE',
  CoronalAngleAchievable = 'CORONAL_ANGLE_ACHIEVABLE',
  DiscHeightsReasonable = 'DISC_HEIGHTS_REASONABLE',
  PosteriorDiscHeightValid = 'POSTERIOR_DISC_HEIGHT_VALID',
  AlifxScrewTrajectoriesAcceptable = 'ALIFX_SCREW_TRAJECTORIES_ACCEPTABLE',
  TlifCKyphotic = 'TLIF_C_KYPHOTIC',
  CageFootprintFit = 'CAGE_FOOTPRINT_FIT',
  VertebralBodiesEdited = 'VERTEBRAL_BODIES_EDITED',
}

export enum PathologyType {
  Osteoporosis = 'OSTEOPOROSIS',
  DiscDegeneration = 'DISC_DEGENERATION',
  Scoliosis = 'SCOLIOSIS',
  Spondylolisthesis = 'SPONDYLOLISTHESIS',
  TransitionalAnatomy = 'TRANSITIONAL_ANATOMY',
  ExtraVertebrae = 'EXTRA_VERTEBRAE',
  EndplateDeformityAtTreatmentLevels = 'ENDPLATE_DEFORMITY_AT_TREATMENT_LEVELS',
  UniqueAnatomy = 'UNIQUE_ANATOMY',
  PriorHardwareAtTheTreatmentLevels = 'PRIOR_HARDWARE_AT_THE_TREATMENT_LEVELS',
  Other = 'OTHER',
}

export enum PlanRejectionReason {
  LordosisChange = 'LORDOSIS_CHANGE',
  CoronalChange = 'CORONAL_CHANGE',
  HeightChange = 'HEIGHT_CHANGE',
  FootprintChange = 'FOOTPRINT_CHANGE',
  CagePosition = 'CAGE_POSITION',
  LevelImplantChange = 'LEVEL_IMPLANT_CHANGE',
  Admin = 'ADMIN',
  Other = 'OTHER',
}

export enum LevelResultNotUsedReason {
  CorrectionAchievedWithoutLevel = 'CORRECTION_ACHIEVED_WITHOUT_LEVEL',
  SurgeonSurgicalPlanChange = 'SURGEON_SURGICAL_PLAN_CHANGE',
  LimitedAccess = 'LIMITED_ACCESS',
  LevelAutofused = 'LEVEL_AUTOFUSED',
  AnatomicalConstraints = 'ANATOMICAL_CONSTRAINTS',
  StockDeviceUsed = 'STOCK_DEVICE_USED',
  DiscSpaceFitIssue = 'DISC_SPACE_FIT_ISSUE',
  Other = 'OTHER',
}
