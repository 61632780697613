import { Box, FormControlLabel, Checkbox as MuiCheckbox, Typography } from '@mui/material';
import { green, grey, lightBlue, red } from '@mui/material/colors';
import {
  AssetType,
  CaseStageType,
  IAsset,
  ICase,
  IPlan,
  LevelSize,
  LevelType,
  PartType,
  PlanVersionType,
  IPatientRecord,
} from '@workflow-nx/common';
import React, { Dispatch, useState } from 'react';
import ActionButton from '../../../../components/ActionButton';
import EditedVertebralAssetsBanner from '../EditedVertebralAssetsBanner';
import { ManageAppAssetsDialog } from './ManageAppAssetsDialog';
import { PlanImplantsTableView } from './PlanImplantsTableView';
import { PlanVersionToggle } from './PlanVersionToggle';
import { ProposedPlanView } from './ProposedPlanView';

export function PlanDetailsView({
  activeCase,
  caseLevels,
  dispatch,
  disabled,
  isPlanAppReady,
  plan,
  patientRecord,
  planAssets,
  requiredAppAssets,
  optionalAppAssets,
  implantImageAssets,
  requiredPlanAssets,
  editingAllowed,
  isSelectedPlanDeleted,
  onShowPlanAssetsClick,
  onShowExportAssetsClick,
  onPlanVersionClick,
  onAppAssetsReadyClick,
  onPlusLevelSizeClick,
  onAppAssetsComplete,
  onPlanImplantsUpdated,
}: {
  caseLevels: LevelType[];
  dispatch: Dispatch<any>;
  disabled: boolean;
  isPlanAppReady: boolean;
  plan: IPlan;
  patientRecord: IPatientRecord;
  planAssets: IAsset[];
  activeCase: ICase;
  requiredAppAssets: AssetType[];
  optionalAppAssets: AssetType[];
  implantImageAssets: AssetType[];
  requiredPlanAssets: AssetType[];
  editingAllowed: boolean;
  isSelectedPlanDeleted: boolean;
  onShowPlanAssetsClick: () => void;
  onShowExportAssetsClick: () => void;
  onPlanVersionClick: () => void;
  onAppAssetsReadyClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPlusLevelSizeClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAppAssetsComplete: (level?: LevelType, levelSize?: LevelSize, partType?: PartType) => void;
  onPlanImplantsUpdated: () => void;
}) {
  const [showInterbodyAssetsDialog, setShowInterbodyAssetsDialog] = useState(false);

  function hasPlanAssetsCompleted() {
    let hasPlanAssetsComplete = true;

    for (const requiredPlanAsset of requiredPlanAssets) {
      const foundPlanAsset = planAssets.find(
        (planAsset) => planAsset.assetType === requiredPlanAsset,
      );
      if (!foundPlanAsset) {
        hasPlanAssetsComplete = false;
      }
    }

    return hasPlanAssetsComplete;
  }

  function hasAppAssetsCompleted() {
    let hasAppAssetsComplete = true;

    for (const requiredAsset of requiredAppAssets) {
      const foundPlanAsset = planAssets.find((planAsset) => planAsset.assetType === requiredAsset);
      if (!foundPlanAsset) {
        hasAppAssetsComplete = false;
      }
    }

    for (const requiredAsset of implantImageAssets) {
      const foundPlanAsset = planAssets.find((planAsset) => planAsset.assetType === requiredAsset);
      if (!foundPlanAsset) {
        hasAppAssetsComplete = false;
      }
    }

    return hasAppAssetsComplete;
  }

  return (
    <Box p={2}>
      <Box>
        <Box display={'flex'} mb={2}></Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'stretch'} mb={2} mx={10}>
          <Box
            sx={{
              display: 'flex',
              borderRadius: '5px',
              alignItems: 'center',
              mb: 2,
              p: 2,
              border: `1px solid ${grey[300]}`,
            }}
          >
            <Typography variant={'body1'}>
              {plan.version === PlanVersionType.Version1
                ? 'This plan uses nTop on the Designers desktop to generate text files for use in Cloud Design V1'
                : 'This plan uses IDE to position and rotate implants for use in Cloud Design V2'}
            </Typography>
            <Box flexGrow={1} />
            {plan.version === PlanVersionType.Version1 ? (
              <ActionButton
                color={'secondary'}
                disabled={disabled}
                onClick={onShowExportAssetsClick}
                variant={'outlined'}
              >
                <Typography color={'secondary'} variant={'button'} noWrap={true}>
                  Download nTop GUI Assets
                </Typography>
              </ActionButton>
            ) : null}
            <Box sx={{ mx: 1 }} />
            <PlanVersionToggle
              disabled={disabled}
              planVersion={plan.version}
              onChange={onPlanVersionClick}
            />
          </Box>

          <Box bgcolor={lightBlue['50']} borderRadius="5px" alignItems={'center'} mb={2} p={2}>
            <Box>
              <PlanImplantsTableView
                patientRecord={patientRecord}
                plan={plan}
                disabled={disabled}
                activeCase={activeCase}
                editingAllowed={editingAllowed}
                onUpdated={onPlanImplantsUpdated}
              />
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Box
                sx={{
                  display: 'inline-block',
                  backgroundColor: 'white',
                  textAlign: 'center',
                  marginTop: 1,
                  padding: 1,
                  borderRadius: '10px',
                }}
              >
                <FormControlLabel
                  control={
                    <MuiCheckbox
                      disabled={disabled}
                      name="planPlusLevelSize"
                      checked={plan?.plusLevelSize === 1}
                      onChange={onPlusLevelSizeClick}
                    />
                  }
                  label={`Use a smaller (1mm) PLUS implant size?`}
                />
              </Box>
            </Box>
          </Box>
          <Typography variant={'h4'} sx={{ mb: 1 }}>
            Vertebral Body and Form Summary Assets
          </Typography>
          <Box
            display={'flex'}
            bgcolor={hasPlanAssetsCompleted() ? green['50'] : red['50']}
            borderRadius="5px"
            alignItems={'center'}
            mb={2}
            p={2}
          >
            <Typography variant={'body1'}>
              {hasPlanAssetsCompleted()
                ? 'All plan assets are available for this plan'
                : 'There are plan assets missing for this plan'}
            </Typography>
            <Box flexGrow={1} />
            <ActionButton
              color={'secondary'}
              disabled={isSelectedPlanDeleted}
              onClick={onShowPlanAssetsClick}
              variant={'outlined'}
            >
              <Typography color={'secondary'} variant={'button'} noWrap={true}>
                Manage Plan Assets
              </Typography>
            </ActionButton>
          </Box>
          <Typography variant={'h4'} sx={{ mb: 1 }}>
            my aprevo™ Assets
          </Typography>
          <Box
            display={'flex'}
            bgcolor={hasAppAssetsCompleted() && isPlanAppReady ? green['50'] : red['50']}
            borderRadius="5px"
            alignItems={'center'}
            mb={2}
            p={2}
          >
            <Typography variant={'body1'} sx={{ width: '40%' }}>
              {hasAppAssetsCompleted() && isPlanAppReady
                ? 'All app assets are available for this plan'
                : 'There are app plan assets missing for this plan or the plan is not marked as "ready"'}
            </Typography>
            <Box flexGrow={1} />
            <Box
              sx={{
                mx: 1,
                width: '30%',
              }}
            >
              <Box display={'flex'} justifyContent={'center'}>
                <FormControlLabel
                  control={
                    <MuiCheckbox
                      disabled={disabled}
                      name="planIsAppReady"
                      checked={isPlanAppReady}
                      onChange={onAppAssetsReadyClick}
                    />
                  }
                  label={`Has the iOS app been configured with the plan "${plan?.name}"?`}
                />
              </Box>
            </Box>
            <ActionButton
              color={'secondary'}
              disabled={isSelectedPlanDeleted}
              onClick={() => setShowInterbodyAssetsDialog(true)}
              variant={'outlined'}
            >
              <Typography color={'secondary'} variant={'button'} noWrap={true}>
                Manage App Assets
              </Typography>
            </ActionButton>
          </Box>
          <EditedVertebralAssetsBanner
            dispatch={dispatch}
            disabled={isSelectedPlanDeleted}
            assets={planAssets}
            activeCase={activeCase}
            plan={plan}
            readOnly={
              !editingAllowed ||
              ![
                CaseStageType.Open,
                CaseStageType.Segmentation,
                CaseStageType.Planning,
                CaseStageType.Proposed,
                CaseStageType.Design,
              ].includes(activeCase.stage)
            }
          />
          <ProposedPlanView activeCase={activeCase} planId={plan.planId} />
          {showInterbodyAssetsDialog ? (
            <ManageAppAssetsDialog
              caseLevels={caseLevels}
              caseNumber={activeCase.number}
              onClose={() => {
                setShowInterbodyAssetsDialog(false);
                onAppAssetsComplete();
              }}
              plan={plan}
              requiredAppAssets={[...requiredAppAssets, ...optionalAppAssets]}
              planAssets={planAssets}
              dispatch={dispatch}
              disabled={disabled}
              implantImageAssets={implantImageAssets}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
