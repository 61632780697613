import Refresh from '@mui/icons-material/RefreshOutlined';
import ThreeSixty from '@mui/icons-material/ThreeSixtyOutlined';
import OrthoIcon from '@mui/icons-material/ViewInAr';
import PerspectiveIcon from '@mui/icons-material/Visibility';

import { Box, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EndPlate, IMeasure, Position, VertebraePositionColor } from '@workflow-nx/common';
import {
  AbstractMesh,
  ArcRotateCamera,
  Axis,
  Camera,
  Color3,
  DirectionalLight,
  Engine,
  EngineOptions,
  Mesh,
  MeshBuilder,
  Nullable,
  Quaternion,
  Scene,
  SceneLoader,
  SceneOptions,
  StandardMaterial,
  Tags,
  Vector3,
} from 'babylonjs';
import { AdvancedDynamicTexture, Rectangle, TextBlock } from 'babylonjs-gui';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import config from '../../extras/config';
import { getOrthoCameraProperties } from './renderer';

export const setTarget = (targetNames: string[], scene: Scene) => {
  let targetMesh: Mesh | undefined;

  targetMesh = scene?.getMeshesByTags((targetNames || []).join(' && '))?.[0];

  if (targetMesh) {
    (scene?.activeCamera as ArcRotateCamera).setTarget(
      (targetMesh as Mesh).getBoundingInfo().boundingBox.center,
    );
  }

  /*
  if (targetNames.length > 0) {
    let targetMesh = scene?.getMeshByName(targetNames[0]);

    // if there is a second name in targetNames, then get the 2nd one as a fallback if the
    // first requested mesh for the target name doesn't exist
    if (!targetMesh && targetNames.length > 1) {
      targetMesh = scene?.getMeshByName(targetNames[1]);
    }

    if (targetMesh) {
      (scene?.activeCamera as ArcRotateCamera).setTarget(
        targetMesh.getBoundingInfo().boundingBox.center,
      );
    }
  }
*/
};

export const setOpacity = (targetName: string, opacity: number, scene: Scene) => {
  let targetMesh = scene?.getMeshByName(targetName);

  if (targetMesh && targetMesh?.material) {
    targetMesh.material.alpha = opacity;
  }
};

export const onControlClick = (point: Vector3 | null, scene: Scene) => {
  if (!scene || !point) return;

  const name = `POINT_OF_ROTATION`;

  let mesh = scene.getMeshByName(name);
  if (!mesh) {
    const material = new StandardMaterial('pointMaterial', scene);

    mesh = MeshBuilder.CreatePolyhedron(name, { type: 1, size: 0.75 }, scene);
    material.diffuseColor = Color3.FromHexString('#FFD700');
    mesh.material = material;
  }

  mesh.isPickable = false;
  mesh.position = point;

  (scene.activeCamera as ArcRotateCamera).setTarget(point);
};

/*
export function getTags(name: string): string[] {
  let lowerName = name.toLowerCase();
  console.log(lowerName);
  if (lowerName.endsWith('_app_plan')) {
    return [`${lowerName.replace('_app_plan', '')}`];
  } else if (lowerName.endsWith('_plan')) {
    return [`${lowerName.replace('_plan', '')}`];
  } else {
    return [lowerName];
  }
}
*/

export const loadLandmarks = (
  parentMesh: AbstractMesh,
  landmarks: IMeasure[],
  visibility: number,
  scene: Scene,
) => {
  for (let landmark of landmarks) {
    const { body, position, endPlate, point } = landmark;
    const name = `${body}.${position}.${endPlate}.POINT`;

    let color =
      endPlate === EndPlate.Superior
        ? Color3.FromHexString(VertebraePositionColor.AnteriorTop) // light red
        : Color3.FromHexString(VertebraePositionColor.AnteriorBottom); // dark red

    if (position === Position.Posterior) {
      color =
        endPlate === EndPlate.Superior
          ? Color3.FromHexString(VertebraePositionColor.PosteriorTop) // light yellow
          : Color3.FromHexString(VertebraePositionColor.PosteriorBottom); // dark yellow
    }
    if (position === Position.PatientLeft) {
      color =
        endPlate === EndPlate.Superior
          ? Color3.FromHexString(VertebraePositionColor.PatientLeftTop) // light green
          : Color3.FromHexString(VertebraePositionColor.PatientLeftBottom); // dark green
    }
    if (position === Position.PatientRight) {
      color =
        endPlate === EndPlate.Superior
          ? Color3.FromHexString(VertebraePositionColor.PatientRightTop) // light blue
          : Color3.FromHexString(VertebraePositionColor.PatientRightBottom); // dark blue
    }

    const vector3 = new Vector3(point[0], point[1], point[2]);
    let landmarkMesh;
    if (landmark.source === 'AUTO') {
      landmarkMesh = addPolyhedron(parentMesh, vector3, name, color, scene);
    } else {
      landmarkMesh = addSphere(parentMesh, vector3, name, color, scene);
    }

    if (landmarkMesh) {
      const parentTags = Tags.GetTags(parentMesh);
      Tags.AddTagsTo(landmarkMesh, `${parentTags} POINT`);
      landmarkMesh.visibility = visibility;
    }
  }
};

export const loadMeshFromUrl = async (
  url: string,
  name: string,
  tags: string[],
  color: Color3,
  scene?: Scene,
): Promise<AbstractMesh | null> => {
  const result = await SceneLoader.ImportMeshAsync(null, url, '', scene);
  const mesh = result.meshes[0];
  mesh.name = name;

  // NOTE: The STL files come back from nTopology with no normal information
  // (used by the shader) so we need to create the normals manually
  mesh.createNormals(false);

  if (tags) {
    Tags.AddTagsTo(mesh, tags.join(' '));
  }

  const standardMaterial = new StandardMaterial('myMaterial', scene);
  standardMaterial.diffuseColor = color;

  // when the mesh is transparent, these settings will make it so that the
  // back of the mesh doesn't make it through the front of the mesh
  standardMaterial.backFaceCulling = true;
  standardMaterial.transparencyMode = BABYLON.Material.MATERIAL_ALPHABLEND;
  standardMaterial.needDepthPrePass = true;

  mesh.material = standardMaterial;

  mesh.computeWorldMatrix(true);
  mesh.setPivotPoint(mesh.getBoundingInfo().boundingBox.center);

  return mesh;
};

/*
export const loadMesh = async (
  assetMeshView: AssetMeshView,
  scene?: Scene,
): Promise<AbstractMesh | null> => {
  if (!scene || !assetMeshView.signedDownloadUrl) return null;

  const result = await SceneLoader.ImportMeshAsync(
    null,
    assetMeshView.signedDownloadUrl,
    '',
    scene,
  );
  const mesh = result.meshes[0];
  mesh.name = assetMeshView.name;

  // NOTE: The STL files come back from nTopology with no normal information
  // (used by the shader) so we need to create the normals manually
  mesh.createNormals(false);

  let tags = assetMeshView.tags;
  if (tags) {
    Tags.AddTagsTo(mesh, tags.join(' '));
  }

  const standardMaterial = new StandardMaterial('myMaterial', scene);
  standardMaterial.diffuseColor = assetMeshView?.options?.color ?? Color3.FromInts(245, 245, 245);

  mesh.material = standardMaterial;

  mesh.computeWorldMatrix(true);
  mesh.setPivotPoint(mesh.getBoundingInfo().boundingBox.center);

  if (assetMeshView?.options?.showEdges) {
    mesh.enableEdgesRendering();
    mesh.edgesWidth = 1.0;
    mesh.edgesColor = new Color4(1, 1, 1, 1);
  }

  if (assetMeshView?.options?.showBoundingBox) {
    mesh.showBoundingBox = true;
  }

  if (assetMeshView?.options?.showHighlight) {
    mesh.actionManager = new ActionManager(scene);
    mesh.actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function () {
        mesh.overlayColor = Color3.Yellow();
        mesh.renderOverlay = true;
      }),
    );
    mesh.actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function () {
        mesh.renderOverlay = false;
      }),
    );
  }

  const visibility = assetMeshView?.options?.visibility ?? 0;

  console.log(assetMeshView.name, assetMeshView.category, visibility);

  if (assetMeshView?.landmarks?.length) {
    const landmarks = assetMeshView.landmarks;
    loadLandmarks(landmarks, visibility, scene);
  }

  mesh.visibility = visibility;
  mesh.isPickable = mesh.visibility === 1;

  return mesh as AbstractMesh;
};
*/

let startingPointerPosition: { x: number; y: number } | null;

function addSphere(
  parentMesh: AbstractMesh,
  point: Vector3,
  name: string,
  color: Color3,
  scene: Scene,
) {
  const material = new StandardMaterial('redMat', scene);
  const mesh = MeshBuilder.CreateSphere(name, { diameter: 3 }, scene);

  material.diffuseColor = color;
  mesh.material = material;
  mesh.parent = parentMesh;

  mesh.isPickable = false;
  mesh.setAbsolutePosition(point);
  return mesh;
}

function addPolyhedron(
  parentMesh: AbstractMesh,
  point: Vector3,
  name: string,
  color: Color3,
  scene: Scene,
) {
  const material = new StandardMaterial('redMat', scene);
  const mesh = MeshBuilder.CreatePolyhedron(name, { type: 1, size: 3 }, scene);

  material.diffuseColor = color;
  mesh.material = material;
  mesh.parent = parentMesh;

  mesh.isPickable = false;
  mesh.setAbsolutePosition(point);
  return mesh;
}

export const configureCanvas = (
  options: {
    cameraPosition?: Vector3;
    disableInputs: boolean;
    distance: number;
    cameraMode?: Camera['mode'];
  },
  scene: Scene,
) => {
  const canvas = scene.getEngine().getRenderingCanvas();
  const light = new DirectionalLight('SpotLight', new Vector3(0, 0, 0), scene);
  const camera = new ArcRotateCamera(
    'Camera',
    0,
    0,
    options.distance ?? 100,
    Vector3.Zero(),
    scene,
  );

  camera.allowUpsideDown = true;
  camera.upperBetaLimit = NaN;
  camera.lowerBetaLimit = NaN;
  camera.lowerRadiusLimit = 25;
  camera.panningInertia = 0;
  camera.panningSensibility = 5;
  camera.mode = options.cameraMode ?? Camera.ORTHOGRAPHIC_CAMERA;
  camera.updateUpVectorFromRotation = true;

  if (options.disableInputs) {
    camera.inputs.clear();
  }

  camera.attachControl(canvas, false);

  // Synchronize the spotlight with the camera
  scene.registerBeforeRender(function () {
    light.position.copyFrom(camera.position);
    light.setDirectionToTarget(camera.getTarget());
  });
};

type SceneComponentPropsType = {
  antialias: boolean;
  canvasId: string;
  engineOptions?: EngineOptions;
  loading?: boolean;
  adaptToDeviceRatio: boolean;
  sceneOptions?: SceneOptions;
  cameraMode?: Camera['mode'];
  onRender?: (scene: Scene) => void;
  onSceneReady: (scene: Scene) => void;
};

export interface ILoadingScreen {
  displayLoadingUI: () => void;
  hideLoadingUI: () => void;
  loadingUIBackgroundColor: string;
  loadingUIText: string;
}

export class CustomLoadingScreen implements ILoadingScreen {
  //optional, but needed due to interface definitions
  // @ts-ignore
  public displayLoadingUI: () => void;
  public hideLoadingUI: () => void;
  // @ts-ignore
  public loadingUIBackgroundColor: string;
  // @ts-ignore
  public loadingUIText: string;

  private textBlock: TextBlock;
  private background: Rectangle;

  constructor(private gui: AdvancedDynamicTexture) {
    this.displayLoadingUI = () => this.displayUIInternal();
    this.hideLoadingUI = () => this.hideLoadingUIInternal();

    this.background = new Rectangle('StateChangeLoadingScreen Rectangle');
    this.background.top = 0;
    this.background.left = 0;
    this.background.width = '100%';
    this.background.height = '100%';
    this.background.background = '#0d47a1';
    this.background.isVisible = false;
    gui.addControl(this.background);

    this.textBlock = new TextBlock('StateChangeLoadingScreen TextBlock', 'Loading...');
    this.textBlock.color = 'white';
    this.textBlock.isVisible = false;
    gui.addControl(this.textBlock);
  }

  private displayUIInternal(): void {
    this.textBlock.isVisible = true;
    this.background.isVisible = true;
  }

  private hideLoadingUIInternal(): void {
    this.textBlock.isVisible = false;
    this.background.isVisible = false;
  }
}

function onMouseWheel(event: any, scene: Scene) {
  const mcamera = scene.activeCamera as ArcRotateCamera;
  var delta = 0;
  var bump = 0.1;
  if (event.wheelDelta !== undefined) {
    // WebKit / Opera / Explorer 9
    delta = event.wheelDelta;
  } else if (event.detail !== undefined) {
    // Firefox
    delta = -event.detail;
  }

  if (delta > 0) bump = -bump;

  if (mcamera.mode == Camera.ORTHOGRAPHIC_CAMERA) {
    const orthoProperties = getOrthoCameraProperties((mcamera.radius ?? 0) + bump, scene);

    if (!orthoProperties) return;

    mcamera.orthoTop = orthoProperties.orthoTop;
    mcamera.orthoBottom = orthoProperties.orthoBottom;
    mcamera.orthoLeft = orthoProperties.orthoLeft;
    mcamera.orthoRight = orthoProperties.orthoRight;
  } else mcamera.position.y += bump;
}

const useStyles = makeStyles(() => ({
  spineCanvasRoot: {
    '& canvas': {
      width: '100%',
      height: '100%',
      touchAction: 'none',
    },
  },
  canvasActions: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  fab: {
    '&:hover': {
      backgroundColor: '#d3d3d3',
    },
    backgroundColor: '#d3d3d3',
  },
}));
export const SceneComponent = ({
  adaptToDeviceRatio,
  antialias,
  canvasId,
  engineOptions,
  loading,
  sceneOptions,
  cameraMode,
  onRender,
  onSceneReady,
}: SceneComponentPropsType) => {
  const reactCanvas = useRef(null);
  const [scene, setScene] = useState<Scene>();
  const styles = useStyles();
  const [currentCameraMode, setCurrentCameraMode] = useState<Camera['mode']>(
    cameraMode ?? Camera.ORTHOGRAPHIC_CAMERA,
  );
  const [rotateCameraEnabled, setRotateCameraEnabled] = useState(false);

  // set up basic engine and scene
  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    const engine = new Engine(canvas, antialias, engineOptions, adaptToDeviceRatio);
    const scene = new Scene(engine, sceneOptions);
    if (scene.isReady()) {
      configureCanvas(
        {
          cameraPosition: new Vector3(0, 0, -20),
          disableInputs: false,
          distance: 100,
          cameraMode,
        },
        scene,
      );

      onSceneReady(scene);
    } else {
      scene.onReadyObservable.addOnce((scene) => onSceneReady(scene));
    }

    engine.runRenderLoop(() => {
      if (typeof onRender === 'function') onRender(scene);
      scene.render();
    });

    const resize = () => {
      scene.getEngine().resize();

      const camera = scene.activeCamera as Nullable<ArcRotateCamera>;

      if (!camera) return;

      const orthoProperties = getOrthoCameraProperties(camera.radius, scene);

      if (!orthoProperties) return;

      camera.orthoTop = orthoProperties.orthoTop;
      camera.orthoBottom = orthoProperties.orthoBottom;
      camera.orthoLeft = orthoProperties.orthoLeft;
      camera.orthoRight = orthoProperties.orthoRight;
    };

    const handleOnMouseWheel = (e: any) => {
      onMouseWheel(e, scene);
    };
    engine.loadingScreen = new CustomLoadingScreen(
      AdvancedDynamicTexture.CreateFullscreenUI('myUI'),
    );

    if (window) {
      window.addEventListener('resize', resize);
      window.addEventListener('mousewheel', handleOnMouseWheel, false);
    }

    setScene(scene);

    return () => {
      scene.getEngine().dispose();

      if (window) {
        window.removeEventListener('resize', resize);
        window.removeEventListener('mousewheel', handleOnMouseWheel);
      }
    };
  }, []);

  useEffect(() => {
    if (loading) {
      scene?.getEngine().displayLoadingUI();
    } else {
      scene?.getEngine().hideLoadingUI();
    }
  }, [loading]);

  const onPointerMove = useCallback(
    (event: PointerEvent) => {
      if (!startingPointerPosition) return;

      const camera = scene?.activeCamera as ArcRotateCamera | null;

      if (!camera) return;

      const sensitivity = 100;
      const delta = event.clientX - startingPointerPosition.x;

      const localAxis = camera.getDirection(Axis.Z);

      const upVector = camera.upVector.clone();
      const axis = Quaternion.RotationAxis(localAxis, delta / sensitivity);
      upVector.applyRotationQuaternionInPlace(axis);

      camera.upVector = upVector;

      // Note about this function: If you want to keep the same position, you'll
      // need to call this function.  This will take the current camera position
      // and recalculate the alpha and beta values.  Not calling this function will
      // instead force the position to be changed on the next camera update.
      camera.rebuildAnglesAndRadius();

      startingPointerPosition = {
        x: event.clientX,
        y: event.clientY,
      };
    },
    [scene],
  );

  const onPointerDown = useCallback((event: PointerEvent) => {
    startingPointerPosition = {
      x: event.clientX,
      y: event.clientY,
    };
  }, []);

  const onPointerUp = useCallback(() => {
    startingPointerPosition = null;
  }, []);

  const toggleCameraRotation = (enabled: boolean) => {
    setRotateCameraEnabled(enabled);

    const camera = scene?.activeCamera as ArcRotateCamera;

    const canvas = scene?.getEngine().getRenderingCanvas();

    if (!camera || !canvas) return;

    if (enabled) {
      camera.inputs.attached.pointers.detachControl();

      canvas.addEventListener('pointerdown', onPointerDown);
      canvas.addEventListener('pointermove', onPointerMove);
      canvas.addEventListener('pointerup', onPointerUp);
    } else {
      camera.inputs.attachInput(camera.inputs.attached.pointers);

      canvas.removeEventListener('pointerdown', onPointerDown);
      canvas.removeEventListener('pointermove', onPointerMove);
      canvas.removeEventListener('pointerup', onPointerUp);
    }
  };

  const handleChangeCameraMode = (newCameraMode: Camera['mode']) => {
    const camera = scene?.activeCamera as ArcRotateCamera;

    setCurrentCameraMode(newCameraMode);

    if (!camera) return;

    camera.mode = newCameraMode;
  };

  useEffect(() => {
    if (!_.isNil(cameraMode)) handleChangeCameraMode(cameraMode);
  }, [cameraMode]);

  return (
    <>
      {scene && !loading ? (
        <Box
          display={'flex'}
          className={styles.canvasActions}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
        >
          <Fab
            className={styles.fab}
            sx={{ marginRight: 1 }}
            size="small"
            color="primary"
            aria-label="rotation-mode"
            onClick={() => toggleCameraRotation(!rotateCameraEnabled)}
          >
            {rotateCameraEnabled ? (
              <Refresh sx={{ color: 'gray' }} />
            ) : (
              <ThreeSixty sx={{ color: 'gray' }} />
            )}
          </Fab>
          {config.cameraModeToggleEnabled ? (
            <Fab
              className={styles.fab}
              size="small"
              color="primary"
              aria-label="camera-mode"
              onClick={() => {
                let newCameraMode =
                  currentCameraMode === Camera.ORTHOGRAPHIC_CAMERA
                    ? Camera.PERSPECTIVE_CAMERA
                    : Camera.ORTHOGRAPHIC_CAMERA;

                handleChangeCameraMode(newCameraMode);
              }}
            >
              {currentCameraMode === Camera.ORTHOGRAPHIC_CAMERA ? (
                <OrthoIcon sx={{ color: 'gray' }} />
              ) : (
                <PerspectiveIcon sx={{ color: 'gray' }} />
              )}
            </Fab>
          ) : null}
        </Box>
      ) : null}
      <canvas id={canvasId} ref={reactCanvas} />
    </>
  );
};
