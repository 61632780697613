import { useMutation } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import {
  CaseCancellationType,
  CaseCancelledReasonType,
  CaseInvalidReasonType,
  CaseMissedReasonType,
  format,
  ICase,
  Permission,
  UserRoleType,
} from '@workflow-nx/common';
import { ProgressButton, SelectField, TextField } from '@workflow-nx/ui';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import CustomDialog from '../../../components/CustomDialog';
import { DatePickerField } from '../../../components/DatePickerField';
import { CANCEL_CASE, DELETE_CASE } from '../../../gql';
import useAuth from '../../../hooks/useAuth';

export function CaseCancellationDialog(props: {
  activeCase: ICase;
  open: boolean;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const [cancelCase] = useMutation(CANCEL_CASE);
  const [deleteCase] = useMutation(DELETE_CASE);
  const auth = useAuth();
  const hasDeletionPermission = auth?.hasRole?.([
    UserRoleType.Operations,
    UserRoleType.SiteAdministrator,
  ]);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  async function handleDeleteCase(reason: string) {
    try {
      await confirm({
        title: `Delete Case ${props.activeCase.number}?`,
        description: (
          <>
            This action will remove the case from the aprevo® Digital Production System. It can only
            be retrieved by a system administrator. Are you sure you wish to continue?
          </>
        ),
      });

      await deleteCase({
        variables: {
          caseId: props.activeCase.caseId,
          reason,
        },
      });

      enqueueSnackbar(`Case ${props.activeCase.number} was successfully deleted.`, {
        variant: 'success',
      });

      props.onClose(true);
    } catch (errors) {
      console.error(errors);
      enqueueSnackbar('Error deleting case', {
        variant: 'error',
      });
    }
  }

  async function handleCancelCase(
    type: CaseCancellationType,
    reason: CaseCancelledReasonType | CaseMissedReasonType | CaseInvalidReasonType,
    cancelledAt: Date,
    note: string,
  ) {
    try {
      const reasonDescription = format.formatCaseCancellationReasonType({
        cancellationType: type,
        reason,
      });
      const cancelledDate = format.formatDate(cancelledAt);

      await confirm({
        title: `Cancel Case ${props.activeCase.number}?`,
        description: (
          <>
            This will mark the case as {format.formatCaseCancellationType(type)} for the reason{' '}
            <strong>{reasonDescription}</strong>.
          </>
        ),
      });

      await cancelCase({
        variables: {
          caseId: props.activeCase.caseId,
          cancelledAt: cancelledDate,
          note,
          type,
          reason,
        },
      });

      enqueueSnackbar(`Case ${props.activeCase.number} was successfully removed.`, {
        variant: 'success',
      });

      props.onClose(true);
    } catch (errors) {
      console.error(errors);
      enqueueSnackbar('Error removing case', {
        variant: 'error',
      });
    }
  }

  const handleSubmitForm = async (values: FormikValues, { setSubmitting }: FormikHelpers<any>) => {
    const { note, cancelledAt, reason, type, shouldDelete } = values;
    try {
      if (shouldDelete) {
        await handleDeleteCase(reason);
      } else {
        await handleCancelCase(type, reason, cancelledAt, note);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const caseCancelledTypes = [
    {
      key: CaseCancelledReasonType.InsuranceCoPay,
      value: format.formatCaseCancelledReasonType(CaseCancelledReasonType.InsuranceCoPay),
    },
    {
      key: CaseCancelledReasonType.InsuranceDenial,
      value: format.formatCaseCancelledReasonType(CaseCancelledReasonType.InsuranceDenial),
    },
    {
      key: CaseCancelledReasonType.PatientHealth,
      value: format.formatCaseCancelledReasonType(CaseCancelledReasonType.PatientHealth),
    },
    {
      key: CaseCancelledReasonType.PatientOptOut,
      value: format.formatCaseCancelledReasonType(CaseCancelledReasonType.PatientOptOut),
    },
    {
      key: CaseCancelledReasonType.SurgeonPreference,
      value: format.formatCaseCancelledReasonType(CaseCancelledReasonType.SurgeonPreference),
    },
  ];
  const caseMissedTypes = [
    {
      key: CaseMissedReasonType.HospitalApproval,
      value: format.formatCaseMissedReasonType(CaseMissedReasonType.HospitalApproval),
    },
    {
      key: CaseMissedReasonType.Imaging,
      value: format.formatCaseMissedReasonType(CaseMissedReasonType.Imaging),
    },
    {
      key: CaseMissedReasonType.InsufficientLeadTime,
      value: format.formatCaseMissedReasonType(CaseMissedReasonType.InsufficientLeadTime),
    },
    {
      key: CaseMissedReasonType.LostDelayedShipment,
      value: format.formatCaseMissedReasonType(CaseMissedReasonType.LostDelayedShipment),
    },
    {
      key: CaseMissedReasonType.ProductionIssue,
      value: format.formatCaseMissedReasonType(CaseMissedReasonType.ProductionIssue),
    },
    {
      key: CaseMissedReasonType.SurgeryMovedUp,
      value: format.formatCaseMissedReasonType(CaseMissedReasonType.SurgeryMovedUp),
    },
    {
      key: CaseMissedReasonType.SurgeonPreferenceIntraOp,
      value: format.formatCaseMissedReasonType(CaseMissedReasonType.SurgeonPreferenceIntraOp),
    },
    {
      key: CaseMissedReasonType.SurgeonPreferencePreOp,
      value: format.formatCaseMissedReasonType(CaseMissedReasonType.SurgeonPreferencePreOp),
    },
  ];
  const caseInvalidTypes = [
    {
      key: CaseInvalidReasonType.Duplicate,
      value: format.formatCaseInvalidReasonType(CaseInvalidReasonType.Duplicate),
    },
    {
      key: CaseInvalidReasonType.DataEntryError,
      value: format.formatCaseInvalidReasonType(CaseInvalidReasonType.DataEntryError),
    },
  ];

  const getCaseRemovalTypes = (cancellationType: CaseCancellationType) => {
    switch (cancellationType) {
      case CaseCancellationType.Cancelled:
        return caseCancelledTypes;
      case CaseCancellationType.Missed:
        return caseMissedTypes;
      case CaseCancellationType.Invalid:
        return caseInvalidTypes;
      default:
        return [];
    }
  };

  const caseCancellationTypes = [
    {
      key: CaseCancellationType.Cancelled,
      value: 'Cancelled',
    },
    {
      key: CaseCancellationType.Missed,
      value: 'Missed',
    },
    {
      key: CaseCancellationType.Invalid,
      value: 'Invalid',
    },
  ];

  function isFieldRequired(shouldDelete: any, schema: any, value: any) {
    const field = value.path;

    if (field === 'shouldDelete') {
      return schema.notRequired();
    }

    if (field === 'cancelledAt' || field === 'type' || field === 'reason') {
      return !shouldDelete[0] ? schema.required() : schema.notRequired();
    }
    return shouldDelete[0] ? schema.required() : schema.notRequired();
  }

  return (
    <Formik
      initialValues={{
        cancelledAt: new Date(),
        type: '',
        reason: '',
        note: '',
        shouldDelete: false,
      }}
      validationSchema={Yup.object().shape({
        cancelledAt: Yup.date().when('shouldDelete', isFieldRequired),
        type: Yup.string().when('shouldDelete', isFieldRequired),
        reason: Yup.string().when('shouldDelete', isFieldRequired),
        note: Yup.string().when('shouldDelete', isFieldRequired),
      })}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ setFieldValue, validateForm, values, handleSubmit, isSubmitting, submitForm }) => (
        <CustomDialog
          maxWidth={'md'}
          open={props.open}
          title={`Cancel Case ${props.activeCase.number}`}
          onClose={() => {
            props.onClose(false);
          }}
          negativeActionButtons={
            hasDeletionPermission
              ? [
                  <ProgressButton
                    onClick={async () => {
                      setFieldValue('shouldDelete', true);
                      // NOTE: validateForm is required after changing shouldDelete so that the submitForm isn't validating
                      //       against old values.
                      await validateForm(values);
                      await submitForm();
                    }}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    label={'Delete Case'}
                  />,
                ]
              : []
          }
          positiveActionButtons={[
            <ProgressButton
              onClick={async () => {
                setFieldValue('shouldDelete', false);
                // NOTE: validateForm is required after changing shouldDelete so that the submitForm isn't validating
                //       against old values.
                await validateForm(values);
                await submitForm();
              }}
              loading={isSubmitting}
              disabled={isSubmitting}
              label={'Cancel Case'}
            />,
          ]}
        >
          {props.open ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <DatePickerField
                    disabled={isSubmitting}
                    name="cancelledAt"
                    label={'Lost Opportunity Date'}
                    required={true}
                  />
                </Grid>

                <Grid item xs={4}>
                  <SelectField
                    name={'type'}
                    label={'Type'}
                    required={true}
                    hideNone={true}
                    menuItems={caseCancellationTypes}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectField
                    name={'reason'}
                    label={'Reason'}
                    required={true}
                    hideNone={true}
                    menuItems={getCaseRemovalTypes(values.type as CaseCancellationType)}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs>
                  <TextField disabled={isSubmitting} name="note" label={'Note'} multiline={true} />
                </Grid>
              </Grid>
              <Box mt={1} />
            </form>
          ) : null}
        </CustomDialog>
      )}
    </Formik>
  );
}
