import { useMutation } from '@apollo/client';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { CaseSpineType, CaseStageType, ICase, Permission, UserRoleType } from '@workflow-nx/common';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { Dispatch, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CaseTypeChip } from '../../../components/CaseTypeChip';
import { REMOVE_HOLD, UNCANCEL_CASE, UNDELETE_CASE } from '../../../gql';
import useAuth from '../../../hooks/useAuth';
import { Globals } from '../../../layouts/DashboardLayout';
import { CaseCancellationDialog } from './CaseCancellationDialog';
import { CaseHoldDialog } from './CaseHoldDialog';
import { CaseViewActionType } from './CaseView';
import { CaseViewContext } from './CaseView.context';
import ExportCaseButton from './ExportCaseButton';
import { GenericDateDialog } from './GenericDateDialog';
import { GenericReasonDialog } from './GenericReasonDialog';
import { MeasurementsVersionToggle } from './MeasurementsVersionToggle';
import { UpdateCaseNumberDialog } from './UpdateCaseNumberDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type CaseHeaderViewProps = {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
};

export function CaseHeaderView({ activeCase, dispatch }: CaseHeaderViewProps) {
  const [openCancelCaseDialog, setOpenCancelCaseDialog] = useState(false);
  const [openRestoreCaseDialog, setOpenRestoreCaseDialog] = useState(false);
  const [openUpdateCaseNumberDialog, setOpenUpdateCaseNumberDialog] = useState(false);
  const [openHoldCaseDialog, setOpenHoldCaseDialog] = useState(false);
  const [openRemoveHoldDialog, setOpenRemoveHoldDialog] = useState(false);
  const [openUndeleteCaseDialog, setOpenUndeleteCaseDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [uncancelCase] = useMutation(UNCANCEL_CASE);
  const [undeleteCase] = useMutation(UNDELETE_CASE);
  const [removeHold] = useMutation(REMOVE_HOLD);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { hasPermission, isDrawerOpen, hasRole } = useAuth();
  const caseViewContext = useContext(CaseViewContext);
  const theme = useTheme();

  const handleRestoreCaseClick = async (reason: string) => {
    try {
      await uncancelCase({
        variables: {
          caseId: activeCase.caseId,
          reason,
        },
      });

      enqueueSnackbar(`Case ${activeCase.number} was successfully restored.`, {
        variant: 'success',
      });
      navigate(`/app/cases/${activeCase.caseId}`);
    } catch (errors) {
      console.error(errors);
      enqueueSnackbar('Error restoring case', {
        variant: 'error',
      });
    }
  };

  const handleUndeleteCaseClick = async (reason: string) => {
    try {
      await undeleteCase({
        variables: {
          caseId: activeCase.caseId,
          reason,
        },
      });

      enqueueSnackbar(`Case ${activeCase.number} was successfully restored.`, {
        variant: 'success',
      });
      navigate('/app/cases');
    } catch (errors) {
      console.error(errors);
      enqueueSnackbar('Error undeleting case', {
        variant: 'error',
      });
    }
  };

  const handleReleaseCaseClick = async (values: {
    date: Date | string;
    isSurgeryDateTentative: boolean;
    comment?: string;
  }) => {
    try {
      const surgeryDate = values.date
        ? DateTime.fromJSDate(values.date as Date).toISODate()
        : undefined;

      if (!surgeryDate) {
        enqueueSnackbar('Error unable to determine the surgery date', {
          variant: 'error',
        });
        return;
      }

      await removeHold({
        variables: {
          caseId: activeCase.caseId,
          surgeryDate,
          isSurgeryDateTentative: values.isSurgeryDateTentative,
          comment: values.comment ? values.comment : undefined,
        },
      });

      enqueueSnackbar(`Case ${activeCase.number} was successfully released.`, {
        variant: 'success',
      });
      navigate('/app/cases');
    } catch (errors) {
      console.error(errors);
      enqueueSnackbar('Error releasing hold on case', {
        variant: 'error',
      });
    }
  };

  const canShowCancelCaseButton =
    hasPermission?.([Permission.ManageCase]) &&
    activeCase.stage !== CaseStageType.Complete &&
    !activeCase.deletedAt;
  const canShowHoldCaseButton =
    hasPermission?.([Permission.ManageCase]) &&
    activeCase.stage !== CaseStageType.Complete &&
    !activeCase.caseCancellation;
  const canShowUndeleteCaseButton =
    hasPermission?.([Permission.ManageCase]) &&
    activeCase.deletedAt &&
    hasRole?.([UserRoleType.Operations, UserRoleType.SiteAdministrator]);

  const canShowExportCaseButton = hasRole?.([UserRoleType.SiteAdministrator]) ?? false;

  return (
    <>
      <Stack
        sx={{
          p: 2,
          position: 'fixed',
          top: 64,
          width: `calc(100% - ${
            isDrawerOpen ? Globals.DrawerWidthOpen : Globals.DrawerWidthClosed
          }px)`,
          backgroundColor: theme.palette.grey[200],
          boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
          zIndex: 1000,
        }}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Stack direction={'row'}>
            <Box display={'flex'} alignItems={'center'}>
              <Stack>
                <Stack direction={'row'} spacing={1}>
                  <Typography variant={'h3'}>{activeCase?.number}</Typography>
                  {hasPermission?.([Permission.ManageCase]) ? (
                    <IconButton
                      onClick={() => {
                        setOpenUpdateCaseNumberDialog(true);
                      }}
                      size="small"
                    >
                      <FontAwesomeIcon icon={faPencil} size={'sm'} />
                    </IconButton>
                  ) : null}
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <Typography variant={'h5'} color={'textSecondary'}>
                    {activeCase?.spineType}
                  </Typography>
                  {activeCase.spineType === CaseSpineType.Cervical ? (
                    <Typography variant={'h5'} color={'textSecondary'}>
                      &mdash; {activeCase?.shortNumber}
                    </Typography>
                  ) : null}
                </Stack>
              </Stack>
              <CaseTypeChip caseType={activeCase.caseType} />
            </Box>
          </Stack>
          <Box flexGrow={1} />
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            MenuListProps={{
              sx: { marginX: 1 },
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem>
              <MeasurementsVersionToggle
                originalCaseMeasurementsVersion={activeCase?.settings.measurementsVersion}
                selectedCaseMeasurementsVersion={caseViewContext.caseMeasurementsVersion}
                onChange={() => {
                  caseViewContext.toggleCaseMeasurementsVersion();
                }}
              />
            </MenuItem>
            {canShowCancelCaseButton ? (
              <MenuItem
                onClick={() => {
                  if (!activeCase.caseCancellation) {
                    setOpenCancelCaseDialog(true);
                  } else {
                    setOpenRestoreCaseDialog(true);
                  }
                }}
              >
                {!activeCase.caseCancellation ? 'Cancel Case' : 'Un-cancel Case'}
              </MenuItem>
            ) : null}
            {canShowHoldCaseButton ? (
              <MenuItem
                onClick={() => {
                  if (!activeCase.caseHold) {
                    setOpenHoldCaseDialog(true);
                  } else {
                    setOpenRemoveHoldDialog(true);
                  }
                }}
              >
                {!activeCase.caseHold ? 'Place Surgery Date On Hold' : 'Remove Surgery Date Hold'}
              </MenuItem>
            ) : null}
            {canShowExportCaseButton ? (
              <MenuItem>
                <ExportCaseButton activeCase={activeCase} />
              </MenuItem>
            ) : null}
            {activeCase.stage === CaseStageType.Complete ? (
              <MenuItem
                onClick={() => {
                  navigate(`/app/post-op-analysis/${activeCase.caseId}`);
                }}
              >
                View Post-op Analysis
              </MenuItem>
            ) : null}
            {canShowUndeleteCaseButton ? (
              <MenuItem
                onClick={() => {
                  setOpenUndeleteCaseDialog(true);
                }}
              >
                Undelete Case
              </MenuItem>
            ) : null}
          </Menu>
        </Stack>
      </Stack>
      <UpdateCaseNumberDialog
        open={openUpdateCaseNumberDialog}
        activeCase={activeCase}
        onUpdate={() => {
          dispatch({ type: 'refetch' });
          setOpenUpdateCaseNumberDialog(false);
        }}
        onClose={() => {
          setOpenUpdateCaseNumberDialog(false);
        }}
      />
      <CaseCancellationDialog
        activeCase={activeCase}
        open={openCancelCaseDialog}
        onClose={(shouldUpdate) => {
          if (shouldUpdate) {
            navigate('/app/cases');
          }
          setOpenCancelCaseDialog(false);
        }}
      />
      <CaseHoldDialog
        activeCase={activeCase}
        open={openHoldCaseDialog}
        onClose={(shouldUpdate) => {
          if (shouldUpdate) {
            navigate('/app/cases');
          }
          setOpenHoldCaseDialog(false);
        }}
      />
      <GenericReasonDialog
        open={openRestoreCaseDialog}
        title={`Restore Case  ${activeCase?.number}`}
        labelText={'Restore Case'}
        description={`Please include the reason for the restoration of case  ${activeCase.number}.`}
        onComplete={handleRestoreCaseClick}
        onClose={() => {
          setOpenRestoreCaseDialog(false);
        }}
      />
      <GenericDateDialog
        open={openRemoveHoldDialog}
        title={`Remove Surgery Date Hold On Case  ${activeCase?.number}`}
        labelText={'Remove Surgery Date Hold'}
        dateLabelText={'Surgery Date'}
        description={`Please include the new surgery date for case  ${activeCase.number}.`}
        onComplete={handleReleaseCaseClick}
        onClose={() => {
          setOpenRemoveHoldDialog(false);
        }}
      />
      <GenericReasonDialog
        open={openUndeleteCaseDialog}
        title={`Undelete Case ${activeCase?.number}`}
        labelText={'Undelete Case'}
        description={`Please include the reason for the undeletion of case  ${activeCase.number}.`}
        onComplete={handleUndeleteCaseClick}
        onClose={() => {
          setOpenUndeleteCaseDialog(false);
        }}
      />
    </>
  );
}
