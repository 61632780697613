import { useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button, Card, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import {
  CaseStageType,
  ICase,
  ICaseResult,
  IPlan,
  InterbodyScrewLengthTypes,
  LevelResult,
  LevelResultNotUsedReason,
  LevelSize,
  LevelType,
  PartType,
  Permission,
  PlanStatusType,
  caseUtils,
  format,
} from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useState } from 'react';
import ActionButton from '../../../components/ActionButton';
import { COMPLETE_CASE, FIND_CASE_RESULTS_BY_CASE_ID, FIND_PLAN_BY_STATUS } from '../../../gql';
import useAuth from '../../../hooks/useAuth';
import { ReadyRejectionDialog } from './CaseCompleteTab/ReadyRejectionDialog';
import { CaseExpiryDateAlert } from './CaseExpiryDateAlert';
import {
  CaseResultsFormFields,
  CaseResultsFormValuesType,
  getCaseResultsSchema,
} from './CaseResultsFormFields';
import { CaseViewActionType } from './CaseView';
import {
  Control,
  FieldValues,
  FormProvider,
  Resolver,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSurgeonExcludedImplantSizes } from '../../../hooks/useSurgeonExcludedImplantSizes';
import { PlusSizeExclusionAlert } from '../../../components/PlusSizeExclusionAlert';

type CaseResultType = {
  size: LevelSize | '';
  part: PartType | '';
  result: LevelResult | '';
  exceptionReason: string;
  notUsedReasons: string[];
  screwLengthRight?: InterbodyScrewLengthTypes | '';
  screwLengthMiddle?: InterbodyScrewLengthTypes | '';
  screwLengthLeft?: InterbodyScrewLengthTypes | '';
};

type CaseResultsType = Record<LevelType, CaseResultType>;

const allLevelTypes = Object.values(LevelType);

export function CaseReadyTabView(props: {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
}) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [completeCase, { loading: loadingCaseComplete }] = useMutation(COMPLETE_CASE);
  const confirm = useConfirm();
  const hasPermission = !!auth.hasPermission?.([Permission.ManageCase]);
  const [openReadyRejectionDialog, setOpenReadyRejectionDialog] = useState(false);
  const [areAllResultsUnused, setAreAllResultsUnused] = useState(false);
  const [approvedPlan, setApprovedPlan] = useState<IPlan>();

  const allowedPlusSizeExcludedImplantTypes = useSurgeonExcludedImplantSizes(
    props?.activeCase?.levels,
    props?.activeCase?.surgeonUser?.userId,
  );

  useQuery(FIND_PLAN_BY_STATUS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.activeCase.caseId,
      status: [PlanStatusType.Approved],
    },
    onCompleted: (data) => {
      setApprovedPlan(data.planByStatus);
    },
  });

  const getPlanScrewLength = (levelType: LevelType) => {
    if (approvedPlan) {
      const foundPlanImplant = approvedPlan?.implants?.find(
        (implant) => implant.level === levelType,
      );
      if (foundPlanImplant?.screwLength) {
        return caseUtils.getInterbodyScrewLength(foundPlanImplant.screwLength);
      }
    }
    return null;
  };

  const { loading: loadingCaseResultsByCaseId } = useQuery(FIND_CASE_RESULTS_BY_CASE_ID, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.activeCase.caseId,
    },
    onCompleted: (data) => {
      if (data.caseResultsByCaseId) {
        const levels = props.activeCase.levels;
        const initialValues: CaseResultsType = allLevelTypes.reduce(
          (prev, currLevel) => ({
            ...prev,
            [currLevel]: {
              size: '',
              part: (levels[currLevel] as PartType) || 'NONE',
              result: '',
              notUsedReasons: [],
              exceptionReason: '',
              screwLengthLeft: getPlanScrewLength(currLevel) ?? '',
              screwLengthMiddle: getPlanScrewLength(currLevel) ?? '',
              screwLengthRight: getPlanScrewLength(currLevel) ?? '',
            },
          }),
          {} as CaseResultsType,
        );

        data.caseResultsByCaseId.forEach((caseResult: ICaseResult) => {
          initialValues[caseResult.level] = {
            size: caseResult.size ?? '',
            part: caseResult.part,
            result: caseResult.result,
            notUsedReasons: caseResult.notUsedReasons ?? [],
            exceptionReason: caseResult.exceptionReason,
            screwLengthLeft: caseResult?.screwLengthLeft ?? '',
            screwLengthMiddle: caseResult?.screwLengthMiddle ?? '',
            screwLengthRight: caseResult?.screwLengthRight ?? '',
          };
        });

        setInitialValues(initialValues);
      }
    },
  });

  const [initialValues, setInitialValues] = useState<{
    [key: string]: {
      size: string;
      part: string;
      result: string;
      notUsedReasons: string[];
      exceptionReason: string;
      screwLengthLeft?: string;
      screwLengthMiddle?: string;
      screwLengthRight?: string;
    };
  }>(
    allLevelTypes.reduce(
      (prev, currLevel) => ({
        ...prev,
        [currLevel]: {
          size: '',
          part: '',
          result: '',
          notUsedReasons: [],
          exceptionReason: '',
          screwLengthLeft: '',
          screwLengthMiddle: '',
          screwLengthRight: '',
        },
      }),
      {} as CaseResultsType,
    ),
  );

  const formMethods = useForm<CaseResultsFormValuesType>({
    defaultValues: initialValues ?? undefined,
    resolver: yupResolver(
      getCaseResultsSchema(props.activeCase),
    ) as unknown as Resolver<CaseResultsFormValuesType>,
  });

  const { control, handleSubmit, reset } = formMethods;

  const handleSubmitForm: SubmitHandler<CaseResultsFormValuesType> = async (values) => {
    try {
      setAreAllResultsUnused(false);
      await confirm({
        title: 'Mark case as complete?',
        description: 'Are you sure you want to mark this case as complete?',
      });

      const results = Object.entries(values)
        .map(([key, value]) => {
          const notUsedReasons = value.notUsedReasons?.filter((reason: string) =>
            Boolean(
              Object.values(LevelResultNotUsedReason).includes(reason as LevelResultNotUsedReason),
            ),
          );
          return {
            level: key,
            size: value.size === '' ? undefined : value.size,
            part: value.part,
            result: value.result,
            notUsedReasons: notUsedReasons.length ? notUsedReasons : undefined,
            exceptionReason: value.exceptionReason,
            screwLengthLeft: value?.screwLengthLeft === '' ? undefined : value.screwLengthLeft,
            screwLengthMiddle:
              value?.screwLengthMiddle === '' ? undefined : value.screwLengthMiddle,
            screwLengthRight: value?.screwLengthRight === '' ? undefined : value.screwLengthRight,
          };
        })
        .filter((r) => caseUtils.isValidLevelPartType(r.part));

      if (
        results.every((result) =>
          [LevelResult.Exception, LevelResult.NotUsed].includes(result.result as LevelResult),
        )
      ) {
        enqueueSnackbar('Error - None of the parts are used - please mark as a Lost Opportunity', {
          variant: 'warning',
        });
        setAreAllResultsUnused(true);
        return;
      }

      await completeCase({
        variables: {
          caseId: props.activeCase.caseId,
          results: { results },
        },
      });

      enqueueSnackbar('Case has been marked as complete', {
        variant: 'success',
      });
      props.dispatch({ type: 'refetch' });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error marking the case as complete', {
        variant: 'error',
      });
    }
  };

  const isEditingAllowed = () => {
    const surgeryDate = date.parse(props.activeCase?.surgeryDate as string);
    surgeryDate.setUTCMinutes(new Date().getTimezoneOffset());
    if (date.isBeforeToday(surgeryDate.toISOString())) {
      return hasPermission;
    } else {
      return false;
    }
  };
  const isStageComplete = props.activeCase.stage === CaseStageType.Complete;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  return (
    <>
      <Card>
        <Stack direction={'row'} spacing={1} sx={{ mt: 2, mx: 2 }} alignItems={'center'}>
          <Typography variant={'h2'}>Case Results</Typography>
        </Stack>
        <CardContent>
          {!isStageComplete ? (
            <CaseExpiryDateAlert expiryDate={props.activeCase.studyDate?.expiryDate} />
          ) : null}
          <PlusSizeExclusionAlert
            levels={props.activeCase?.levels}
            plan={approvedPlan}
            isAllowedPlusSizeExcludedImplantTypes={allowedPlusSizeExcludedImplantTypes?.length > 0}
          />
          <>
            <Box my={2}>
              {areAllResultsUnused ? (
                <>
                  <Box my={1} />
                  <Alert severity={'warning'}>
                    If the results only contain:{' '}
                    <strong>{format.formatInterbodyLevelResult(LevelResult.Exception)}</strong>{' '}
                    and/or <strong>{format.formatInterbodyLevelResult(LevelResult.NotUsed)}</strong>{' '}
                    Results, then this case should be marked as: <strong>Lost Opportunity</strong>
                  </Alert>
                </>
              ) : null}
            </Box>
            <Stack spacing={2}>
              <Alert severity={'info'}>
                Once the surgery has been performed, fill out the surgery results and click the{' '}
                <strong>Mark As Complete</strong> button to set the case to "Completed" and remove
                it from the case list.
              </Alert>
              <Box px={5}>
                {loadingCaseResultsByCaseId ? (
                  <Stack spacing={1}>
                    {caseUtils.getValidCaseLevels(props.activeCase?.levels)?.map((level) => (
                      <Stack
                        direction={'row'}
                        spacing={3}
                        justifyContent={'center'}
                        alignItems={'center'}
                        key={`${level}-Skeleton`}
                      >
                        <Skeleton height={50} width={190} />
                        <Skeleton height={110} width={'100%'} />
                      </Stack>
                    ))}
                    <Stack
                      direction={'row'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      spacing={1}
                    >
                      <Skeleton height={60} width={150} />
                      <Skeleton height={60} width={150} />
                    </Stack>
                  </Stack>
                ) : (
                  <FormProvider {...formMethods}>
                    <form>
                      <CaseResultsFormFields
                        activeCase={props.activeCase}
                        isEditingAllowed={isEditingAllowed()}
                        control={control as unknown as Control<FieldValues>}
                      />
                      <Box mt={4}>
                        <Box display={'flex'} my={2} justifyContent={'center'}>
                          <Button
                            variant={'outlined'}
                            disabled={
                              isStageComplete ||
                              !hasPermission ||
                              !!props?.activeCase?.caseCancellation
                            }
                            onClick={() => setOpenReadyRejectionDialog(true)}
                          >
                            Reject Ready
                          </Button>
                          <Box mx={1} />
                          <ActionButton
                            loading={loadingCaseComplete || loadingCaseResultsByCaseId}
                            variant={'outlined'}
                            onClick={handleSubmit(handleSubmitForm)}
                            disabled={
                              isStageComplete ||
                              !isEditingAllowed() ||
                              !!props?.activeCase?.caseCancellation ||
                              caseUtils.isCaseExpiryDateApproachingOrAlreadyExpired(
                                props?.activeCase?.studyDate?.expiryDate,
                              )
                            }
                          >
                            Mark As Complete
                          </ActionButton>
                        </Box>
                      </Box>
                    </form>
                  </FormProvider>
                )}
              </Box>
            </Stack>
          </>
        </CardContent>
      </Card>
      <ReadyRejectionDialog
        open={openReadyRejectionDialog}
        activeCase={props.activeCase}
        onClose={(shouldUpdate) => {
          if (shouldUpdate) {
            props.dispatch({ type: 'refetch' });
          }
          setOpenReadyRejectionDialog(false);
        }}
      />
    </>
  );
}
