import config from '../../../extras/config';
import { faCloud } from '@fortawesome/pro-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

export const getLoginProviders = () => {
  const getAuthUrlForProvider = (identityProvider: string) =>
    `${config.auth.oauth.url}/oauth2/authorize?identity_provider=${identityProvider}&client_id=${config.auth.clientId}&response_type=${config.auth.responseType}&scope=${config.auth.scope}&redirect_uri=${config.auth.redirectUri}`;

  const loginProviders: { name: string; label: string; icon: IconDefinition; authUrl: string }[] =
    [];

  if (config.auth.oauth.idp.jumpCloud) {
    loginProviders.push({
      name: 'JUMPCLOUD',
      label: 'Log in with JumpCloud',
      icon: faCloud,
      authUrl: getAuthUrlForProvider(config.auth.oauth.idp.jumpCloud),
    });
  }

  if (config.auth.oauth.idp.google) {
    loginProviders.push({
      name: 'GOOGLE',
      label: 'Log in with Google',
      icon: faGoogle,
      authUrl: getAuthUrlForProvider(config.auth.oauth.idp.google),
    });
  }
  return loginProviders;
};
