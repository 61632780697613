import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLoginProviders } from './loginHelpers';

const LoginButton = () => {
  const loginProviders = getLoginProviders();

  return (
    <Box>
      {loginProviders.map((loginProvider, index) => {
        return (
          <>
            <Button
              color="primary"
              fullWidth
              size="large"
              onClick={() => (window.location.href = loginProvider.authUrl)}
              variant="text"
            >
              <Box display={'flex'} alignItems={'center'}>
                <FontAwesomeIcon icon={loginProvider.icon} size={'xl'} />
                <Box mx={1} />
                <Typography variant={'body1'}>{loginProvider.label}</Typography>
              </Box>
            </Button>
            {index + 1 < loginProviders.length ? (
              <Box my={1}>
                <Divider variant={'middle'} light={true} />
              </Box>
            ) : null}
          </>
        );
      })}
    </Box>
  );
};

export default LoginButton;
