import { Permission, UserRoleType } from '@workflow-nx/common';

export const getDefaultPermissionsForRole = (role: UserRoleType): Permission[] => {
  switch (role) {
    case UserRoleType.SiteAdministrator:
      return [
        Permission.EditCase,
        Permission.ManageCase,
        Permission.ManageOrganization,
        Permission.ManagePostOpAnalysis,
        Permission.ManagePriceLevel,
        Permission.ManageSiteSettings,
        Permission.ManageSurgeon,
        Permission.ManageSurgeonPreferences,
        Permission.ManageTask,
        Permission.ManageUser,
        Permission.ManageUserPermissions,
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListPostOpAnalyses,
        Permission.ListReports,
        Permission.ListPriceLevels,
        Permission.ListSurgeons,
        Permission.ListTasks,
        Permission.ListUsers,
        Permission.QaReviewCase,
        Permission.ReportExportCaseData,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewPostOpAnalysis,
        Permission.ViewPriceLevel,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
        Permission.ViewTask,
        Permission.ViewUser,
      ];
    case UserRoleType.Operations:
      return [
        Permission.ManageCase,
        Permission.ManageOrganization,
        Permission.ManageSiteSettings,
        Permission.ManageSurgeon,
        Permission.ManageSurgeonPreferences,
        Permission.ManageTask,
        Permission.ManageUser,
        Permission.ManageUserPermissions,
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListPostOpAnalyses,
        Permission.ListReports,
        Permission.ForecastReport,
        Permission.ListPriceLevels,
        Permission.ListSurgeons,
        Permission.ListTasks,
        Permission.ListUsers,
        Permission.QaReviewCase,
        Permission.ReportExportCaseData,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewPostOpAnalysis,
        Permission.ViewPriceLevel,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
        Permission.ViewTask,
        Permission.ViewUser,
      ];
    case UserRoleType.CaseEngineer:
      return [
        Permission.EditCase,
        Permission.ManageSurgeonPreferences,
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListSurgeons,
        Permission.ListUsers,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
      ];
    case UserRoleType.CaseAdmin:
      return [
        Permission.EditCase,
        Permission.ManageCase,
        Permission.ManagePostOpAnalysis,
        Permission.ManageSurgeon,
        Permission.ManageSurgeonPreferences,
        Permission.ManageTask,
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListPostOpAnalyses,
        Permission.ListSurgeons,
        Permission.ListTasks,
        Permission.ListUsers,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewPostOpAnalysis,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
      ];
    case UserRoleType.QualityEngineer:
      return [
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListSurgeons,
        Permission.ListUsers,
        Permission.QaReviewCase,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
      ];
    case UserRoleType.CustomerRep:
    case UserRoleType.FieldRep:
      return [
        Permission.ManageCase,
        Permission.ManageOrganization,
        Permission.ManagePostOpAnalysis,
        Permission.ManageSurgeon,
        Permission.ManageSurgeonPreferences,
        Permission.ManageTask,
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListPostOpAnalyses,
        Permission.ListSurgeons,
        Permission.ListUsers,
        Permission.ListTasks,
        Permission.ForecastReport,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewPostOpAnalysis,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
        Permission.ViewTask,
      ];
    case UserRoleType.CustomerRepManager:
    case UserRoleType.FieldRepManager:
      return [
        Permission.ManageCase,
        Permission.ManageOrganization,
        Permission.ManagePostOpAnalysis,
        Permission.ManageSurgeon,
        Permission.ManageSurgeonPreferences,
        Permission.ManageTask,
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListPostOpAnalyses,
        Permission.ListSurgeons,
        Permission.ListUsers,
        Permission.ListTasks,
        Permission.ForecastReport,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewPostOpAnalysis,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
        Permission.ViewTask,
      ];
    case UserRoleType.Finance:
      return [
        Permission.ManageOrganization,
        Permission.ManagePriceLevel,
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListReports,
        Permission.ForecastReport,
        Permission.ListPostOpAnalyses,
        Permission.ListPriceLevels,
        Permission.ListSurgeons,
        Permission.ListUsers,
        Permission.ReportExportCaseData,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewPriceLevel,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
      ];
    case UserRoleType.Viewer:
      return [
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListSurgeons,
        Permission.ListUsers,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
      ];
    case UserRoleType.External:
      return [
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListSurgeons,
        Permission.ListUsers,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
      ];
    case UserRoleType.SalesRep:
      return [
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListSurgeons,
        Permission.ManagePlanApproval,
        Permission.ViewCase,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
      ];
    case UserRoleType.SegmentationEngineer:
      return [Permission.EditCase, Permission.ListCases, Permission.ViewCase, Permission.ViewSelf];
    case UserRoleType.Surgeon:
      return [
        Permission.ListCases,
        Permission.ManagePlanApproval,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewSelf,
      ];
    case UserRoleType.System:
      return [];
    case UserRoleType.SupplyChain:
      return [
        Permission.ManageCase,
        Permission.ManageOrganization,
        Permission.ManageSiteSettings,
        Permission.ManageSurgeon,
        Permission.ManageSurgeonPreferences,
        Permission.ManageTask,
        Permission.ManageUser,
        Permission.ManageUserPermissions,
        Permission.ListCases,
        Permission.ListOrganizations,
        Permission.ListPostOpAnalyses,
        Permission.ListReports,
        Permission.ForecastReport,
        Permission.ListPriceLevels,
        Permission.ListSurgeons,
        Permission.ListTasks,
        Permission.ListUsers,
        Permission.QaReviewCase,
        Permission.ReportExportCaseData,
        Permission.ViewCase,
        Permission.ViewOrganization,
        Permission.ViewPostOpAnalysis,
        Permission.ViewPriceLevel,
        Permission.ViewSurgeon,
        Permission.ViewSelf,
        Permission.ViewTask,
        Permission.ViewUser,
      ];
    case UserRoleType.Vendor:
      return [Permission.ViewSelf, Permission.ViewCase];
  }

  return [];
};

export const isInternalUser = (role: UserRoleType): boolean => {
  switch (role) {
    case UserRoleType.SiteAdministrator:
    case UserRoleType.CaseEngineer:
    case UserRoleType.SegmentationEngineer:
    case UserRoleType.CaseAdmin:
    case UserRoleType.QualityEngineer:
    case UserRoleType.CustomerRep:
    case UserRoleType.CustomerRepManager:
    case UserRoleType.FieldRep:
    case UserRoleType.FieldRepManager:
    case UserRoleType.Finance:
    case UserRoleType.Operations:
    case UserRoleType.SupplyChain:
    case UserRoleType.Viewer:
      return true;
    case UserRoleType.System:
    case UserRoleType.Surgeon:
    case UserRoleType.SalesRep:
    case UserRoleType.Vendor:
    case UserRoleType.External:
    case UserRoleType.Demo:
      return false;
  }
};
