import { useSnackbar } from 'notistack';
import React, { ComponentProps, useCallback, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CREATE_USER, FIND_LOCATIONS, FIND_ORGANIZATIONS } from '../../../gql';
import { createUserDialogSchema } from '../../../extras/schemas';
import CustomDialog from '../../../components/CustomDialog';
import { format, ILocation, OrganizationType, UserRoleType } from '@workflow-nx/common';
import { Grid, SelectChangeEvent } from '@mui/material';
import { InputConfigRHF, InputRHF, InputTypeRHF, ProgressButton } from '@workflow-nx/ui';
import { sortBy } from 'lodash';
import { Control, FieldValues, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { parsePhoneNumber } from 'libphonenumber-js';

export interface UserFormType extends FieldValues {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  organizationId: number;
  locationId: number;
}

export function CreateUserDialog(props: {
  open: boolean;
  onCreate: (userId: string) => void;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [internalOrganizations, setInternalOrganizations] = useState<
    { key: string; value: string }[]
  >([]);
  const [externalOrganizations, setExternalOrganizations] = useState<
    { key: string; value: string }[]
  >([]);
  const [selectedRole, setSelectedRole] = useState<'internal' | 'external'>('internal');
  const [locations, setLocations] = useState<{ key: string; value: string }[]>([]);
  const [createUser, { loading: loadingCreateUser }] = useMutation(CREATE_USER);
  let userRoleTypes: { key: string; value: string }[] = [];
  for (const value of Object.values(UserRoleType)) {
    if (value === UserRoleType.System) {
      continue;
    }
    userRoleTypes.push({ key: value, value: format.formatUserRole(value) });
  }
  userRoleTypes = sortBy(userRoleTypes, 'value');

  const formRowTextData: InputConfigRHF[] = [
    {
      id: 'firstName',
      label: 'First Name',
      input: InputTypeRHF.Text,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      input: InputTypeRHF.Text,
    },
    {
      id: 'email',
      label: 'Email',
      input: InputTypeRHF.Text,
    },
    {
      id: 'phone',
      label: 'Phone Number',
      input: InputTypeRHF.Phone,
    },
    {
      id: 'role',
      label: 'Role',
      input: InputTypeRHF.Select,
      menuItems: userRoleTypes,
    },
    {
      id: 'organizationId',
      label: 'Institution',
      input: InputTypeRHF.Select,
      menuItems: selectedRole === 'internal' ? internalOrganizations : externalOrganizations,
    },
    {
      id: 'locationId',
      label: 'Default Location',
      input: InputTypeRHF.Select,
      menuItems: locations,
    },
  ];

  const {
    control,
    handleSubmit,
    reset,
    setError,
    resetField,
    formState: { isSubmitting },
  } = useForm<UserFormType>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: undefined,
      organizationId: undefined,
      locationId: undefined,
    },
    resolver: yupResolver(createUserDialogSchema),
  });

  const { loading: loadingFindOrganizations } = useQuery(FIND_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (orgData) => {
      const internalOrganizations: { key: string; value: string }[] = [];
      const extOrganizations: { key: string; value: string }[] = [];

      const organizationSets = orgData?.organizations?.organizations || [];
      for (const org of organizationSets) {
        if (org.organizationType === OrganizationType.Hospital) {
          continue;
        }
        if (org.organizationType === OrganizationType.Main) {
          internalOrganizations.push({
            key: org.organizationId as string,
            value: org.name,
          });
        } else {
          extOrganizations.push({
            key: org.organizationId as string,
            value: org.name,
          });
        }
      }
      setInternalOrganizations(sortBy(internalOrganizations, (o) => o.value.toLowerCase()));
      setExternalOrganizations(sortBy(extOrganizations, (o) => o.value.toLowerCase()));
    },
  });
  const [findLocations] = useLazyQuery(FIND_LOCATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const locations = data.locations || [];
      setLocations(
        sortBy(
          locations.map((location: ILocation) => {
            return { key: location.locationId, value: location.description };
          }),
          (o) => o.value.toLowerCase(),
        ),
      );
    },
  });

  const handleSubmitForm: SubmitHandler<UserFormType> = async (data) => {
    try {
      const formattedPhone =
        !data?.phone || data.phone.trim() === '' || /^\+\d*$/.test(data.phone.trim())
          ? undefined
          : parsePhoneNumber(data.phone).number;

      const { data: createUserData } = await createUser({
        variables: {
          firstName: data.firstName,
          lastName: data.lastName,
          phone: formattedPhone,
          email: data.email,
          role: data.role,
          organizationId: data.organizationId,
          locationId: data.locationId,
        },
      });

      const userId = createUserData.createUser.user.userId;

      enqueueSnackbar('User Created', {
        variant: 'success',
      });

      props.onCreate(userId);
      reset();
    } catch (err: unknown) {
      console.error(err);

      if (err instanceof ApolloError) {
        if (err?.graphQLErrors?.[0]?.extensions?.code === 'EMAIL_NOT_UNIQUE') {
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
          resetField('email');
          setError('email', { message: 'Email is not unique' });
          return;
        }
        if (err?.graphQLErrors?.[0]?.extensions?.code === 'CREATE_USER_PHONE_NOT_UNIQUE') {
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
          resetField('phone');
          setError('phone', { message: 'Phone number is not unique' });
          return;
        }
      }
      enqueueSnackbar('An error occurred creating the user', {
        variant: 'error',
      });
    }
  };

  const handleLocationChange = useCallback(
    (e: SelectChangeEvent<HTMLSelectElement>): void => {
      const organizationId = e.target.value;
      if (organizationId) {
        findLocations({
          variables: { organizationId },
        });
      } else {
        setLocations([]);
      }
    },
    [findLocations],
  );

  const handleRoleChange = useCallback((e: SelectChangeEvent<HTMLSelectElement>): void => {
    const roleType = e.target.value;
    if (
      [UserRoleType.SalesRep, UserRoleType.Vendor, UserRoleType.External].includes(
        roleType as UserRoleType,
      )
    ) {
      setSelectedRole('external');
    } else {
      setSelectedRole('internal');
    }
  }, []);

  const getInputProps = (config: InputConfigRHF): ComponentProps<typeof InputRHF> => ({
    config: config,
    control: control as unknown as Control<FieldValues>,
    disabled: isSubmitting,
    onChange:
      config.id === 'organizationId'
        ? handleLocationChange
        : config.id === 'role'
        ? handleRoleChange
        : undefined,
    selectFieldProps: {
      hideNone: true,
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      title={'Create User'}
      onClose={() => {
        reset();
        props.onClose();
      }}
      positiveActionButtons={[
        <ProgressButton
          variant={'contained'}
          disabled={isSubmitting}
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          label={'Create'}
          loading={isSubmitting || loadingFindOrganizations || loadingCreateUser}
        />,
      ]}
    >
      <form>
        <Grid container spacing={3}>
          {formRowTextData.map((config) => {
            return <InputRHF key={config.id} {...getInputProps(config)} />;
          })}
        </Grid>
      </form>
    </CustomDialog>
  );
}
