import React, { ComponentProps, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Chip, Divider, Drawer, List, ListSubheader, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavItem from './NavItem';
import useAuth from '../../../hooks/useAuth';
import {
  EnvironmentName,
  IUser,
  Permission,
  TaskStatusType,
  UserRoleType,
} from '@workflow-nx/common';
import {
  faBriefcaseMedical,
  faBuilding,
  faCalendarPlus,
  faCalendarRange,
  faCog,
  faFileChartLine,
  faHandHoldingMedical,
  faLaptopMedical,
  faList,
  faSearchPlus,
  faTachometer,
  faTasks,
  faUserMd,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { intersection } from 'lodash';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { Globals } from '../index';
import config from '../../../extras/config';

interface INavItem {
  title: string;
  icon: any;
  info?: string;
  href?: string;
  permissions: Permission[];
  badge?: number;
  items?: INavItem[];
}

interface ISection {
  items: INavItem[];
  subheader?: string;
}

function getNavSections(user: IUser | undefined, taskCount: number): ISection[] {
  const sections: ISection[] = [];
  const permissions = user?.permissions ?? [];
  const role = user?.role;

  if (role !== UserRoleType.SegmentationEngineer) {
    sections.push({
      items: [
        {
          title: 'Dashboard',
          icon: faTachometer,
          href: '/app/dashboard',
          permissions: [],
        },
      ],
    });
    if (
      role === UserRoleType.Operations ||
      role === UserRoleType.QualityEngineer ||
      role === UserRoleType.SiteAdministrator ||
      role === UserRoleType.SupplyChain
    ) {
      sections.push({
        items: [
          {
            title: 'Cases',
            icon: faBriefcaseMedical,
            href: '/app/cases',
            permissions: [Permission.ListCases],
          },
          {
            title: 'Case Events',
            icon: faLaptopMedical,
            href: '/app/case-events',
            permissions: [Permission.ListCases],
          },
          {
            title: 'Case Triage',
            icon: faHandHoldingMedical,
            href: '/app/case-triage',
            permissions: [Permission.ListCases],
          },
          {
            title: 'Case Calendar',
            icon: faCalendarPlus,
            href: '/app/case-calendar',
            permissions: [Permission.ListCases],
          },
        ].filter((item) => intersection(item.permissions, permissions).length > 0),
      });
    } else {
      sections.push({
        items: [
          {
            title: 'Cases',
            icon: faBriefcaseMedical,
            href: '/app/cases',
            permissions: [Permission.ListCases],
          },
          {
            title: 'Case Triage',
            icon: faHandHoldingMedical,
            href: '/app/case-triage',
            permissions: [Permission.ListCases],
          },
          {
            title: 'Case Calendar',
            icon: faCalendarPlus,
            href: '/app/case-calendar',
            permissions: [Permission.ListCases],
          },
        ].filter((item) => intersection(item.permissions, permissions).length > 0),
      });
    }
  } else {
    sections.push({
      items: [
        {
          title: 'Case Triage',
          icon: faHandHoldingMedical,
          href: '/app/case-triage',
          permissions: [Permission.ListCases],
        },
      ].filter((item) => intersection(item.permissions, permissions).length > 0),
    });
  }

  sections.push({
    items: [
      {
        title: 'Post-Op Analysis',
        icon: faSearchPlus,
        href: '/app/post-op-analysis',
        permissions: [Permission.ListPostOpAnalyses],
      },
      {
        title: 'Tasks',
        icon: faTasks,
        href: '/app/tasks',
        badge: taskCount,
        permissions: [Permission.ListTasks],
      },
    ].filter((item) => intersection(item.permissions, permissions).length > 0),
  });

  sections.push({
    items: [
      {
        title: 'Institutions',
        icon: faBuilding,
        href: '/app/institutions',
        permissions: [Permission.ListOrganizations],
      },
      {
        title: 'Users',
        icon: faUsers,
        href: '/app/users',
        permissions: [Permission.ListUsers],
      },
      {
        title: 'Surgeons',
        icon: faUserMd,
        href: '/app/surgeons',
        permissions: [Permission.ListSurgeons],
      },
      {
        title: 'Reports',
        icon: faFileChartLine,
        href: '/app/reports',
        permissions: [Permission.ListReports],
      },
    ].filter((item) => intersection(item.permissions, permissions).length > 0),
  });

  sections.push({
    items: [
      {
        title: 'Settings',
        icon: faCog,
        href: '/app/settings',
        permissions: [Permission.ManageSiteSettings],
      },
    ].filter((item) => intersection(item.permissions, permissions).length > 0),
  });

  return sections;
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: INavItem[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc: any[], item: INavItem) => reduceChildRoutes({ acc, item, pathname, depth }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: INavItem;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} title={item.title} badge={item.badge}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        title={item.title}
        badge={item.badge}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  drawer: {
    top: 64,
    height: 'calc(100% - 64px)',
  },
  drawerOpen: {
    transition: 'width 0.5s',
    width: Globals.DrawerWidthOpen,
    overflow: 'hidden',
  },
  drawerClosed: {
    transition: 'width 0.5s',
    width: Globals.DrawerWidthClosed,
    overflow: 'hidden',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const ENVIRONMENT_COLOR_MAP: Record<EnvironmentName, ComponentProps<typeof Chip>['color']> = {
  local: 'default',
  dev: 'default',
  vnv: 'secondary',
  production: 'primary',
};

const NavBar = (props: { open: boolean }) => {
  const styles = useStyles();
  const location = useLocation();
  const auth = useAuth();
  const [taskCount, setTaskCount] = useState(0);

  useQuery(
    gql`
      query FindTasks($assignedId: Int, $status: TaskStatusType) {
        tasks(assignedId: $assignedId, status: $status) {
          count
        }
      }
    `,
    {
      variables: {
        assignedId: auth?.hasRole?.([UserRoleType.FieldRep]) ? auth.user?.userId : undefined,
        status: TaskStatusType.Open,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setTaskCount(data?.tasks?.count ?? 0);
      },
    },
  );

  const sections = getNavSections(auth?.user, taskCount);

  const content = (
    <Box height="100%" display="flex" flexDirection="column" pt={'1px'}>
      <PerfectScrollbar options={{ suppressScrollX: true, suppressScrollY: true }}>
        {(sections || {}).map((section, index) =>
          section.items.length > 0 ? (
            <Box key={index}>
              <Box width={Globals.DrawerWidthOpen}>
                <Divider />
              </Box>
              <Box p={2}>
                <List
                  disablePadding={true}
                  key={index}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              </Box>
            </Box>
          ) : null,
        )}
        <Box width={Globals.DrawerWidthOpen}>
          <Divider />
        </Box>
        <Box
          style={{ position: 'absolute', left: 70 }}
          pt={2}
          display={'flex'}
          flexDirection={'column'}
        >
          <Stack justifyContent="center" alignItems="center">
            <Box textAlign={'center'} p={1}>
              <img alt="Logo" src="/assets/carlsmed_logo.png" height={25} />
            </Box>
            <Stack direction={'row'} justifyContent="center" alignItems="center">
              <Typography variant={'button'} color={'textSecondary'}>
                v.{config.version}
              </Typography>
              <Box mx={0.5} />
              {config.environmentName !== 'production' ? (
                <Chip
                  label={config.environmentName}
                  size="small"
                  variant="outlined"
                  color={ENVIRONMENT_COLOR_MAP[config.environmentName as EnvironmentName]}
                />
              ) : null}
            </Stack>
            {config?.branch?.length && config?.branch !== 'master' ? (
              <Chip
                label={config.branch}
                size="small"
                variant="outlined"
                color={ENVIRONMENT_COLOR_MAP[config.environmentName as EnvironmentName]}
              />
            ) : null}
          </Stack>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const drawerWidthStyle = props.open ? styles.drawerOpen : styles.drawerClosed;

  return (
    <Drawer
      anchor="left"
      classes={{
        paper: `${styles.drawer} ${drawerWidthStyle}`,
      }}
      open
      variant="persistent"
    >
      {content}
    </Drawer>
  );
};

export default NavBar;
